<app-admin-navbar></app-admin-navbar>
<div class=" mx-lg-5"> 
    <mat-card> 
        <form class="form" [formGroup]="faculty_member_form">
            <div class="first-portion">
                <h1>Add Faculty Member</h1>
                <div class="div-right">
                    <div class="uploadPhoto-div">Upload Photo</div>
                    <div class="image-upload">
                        <!-- Image Preview -->
                        <label class="hoverable" for="fileInput">
                            <img [src]="url ? url:'../../../assets/img_avatar.png'">
                            <div class="hover-text">Choose Image
                                <p>Size: 300 x 300</p>
                            </div>
                            <div class="background"></div>
                        </label>
                        <br>
                        <input id="fileInput" type='file' (change)="onSelectFile($event)" required>
                    </div>
                </div>
            </div>

            <div class="second-portion">

                <h1> Faculty Member's Information</h1>

                <mat-form-field appearance="outline">
                    <mat-label>Full Name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>
 
                <mat-form-field appearance="outline">
                    <mat-label>Designation</mat-label>
                    <input matInput formControlName="designation" required>
                </mat-form-field>

                <!-- <mat-form-field appearance="outline">
                    <mat-label>Subject</mat-label>
                    <input matInput formControlName="subject" required>
                </mat-form-field> -->

                <mat-form-field appearance="outline">
                    <mat-label>Years of experience</mat-label>
                    <input matInput formControlName="experience"      required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Valid E-mail</mat-label>
                    <input matInput placeholder="pat@example.com" formControlName="email" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Phone Number (Residence)</mat-label>
                    <input matInput formControlName="phoneNumber_residence"   required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Phone Number (Personal)</mat-label>
                    <input matInput formControlName="phoneNumber_personal"      required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Phone Number (Office)</mat-label>
                    <input matInput formControlName="phoneNumber_office"      required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Ex. Number (Office)</mat-label>
                    <input matInput formControlName="ex_number_office"      required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Fax Number (Office)</mat-label>
                    <input matInput formControlName="fax_number_office"      required>
                </mat-form-field>

                <!-- <mat-form-field appearance="outline">
                    <mat-label>Days with BOU</mat-label>
                    <input matInput formControlName="days_bou"   required>
                </mat-form-field> -->
                <mat-form-field appearance="outline">
                    <mat-label>Joining Date (MM/DD/YYYY)</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="days_bou" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Number of Publications</mat-label>
                    <input matInput formControlName="num_publiction"   required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Twitter Id</mat-label>
                    <input matInput formControlName="twitter" placeholder="Add Your Twitter Link" required>
                    
                </mat-form-field>
 
                <mat-form-field appearance="outline">
                    <mat-label>Facebook Id</mat-label>
                    <input matInput formControlName="facebook" placeholder="Add Your Twitter Link" required>
                    
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Linkedin Id</mat-label>
                    <input matInput formControlName="linkedin" placeholder="Add Your Twitter Link" required>
                    
                </mat-form-field>
 
                <!-- <mat-form-field appearance="outline">
                    <mat-label>Email Id</mat-label>
                    <input matInput formControlName="mail" required>
                </mat-form-field> -->
                
                <mat-form-field appearance="outline">
                    <mat-label>Bio</mat-label>
                    <textarea matInput formControlName="bio" required></textarea>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Employment Histroy</mat-label>
                    <textarea matInput formControlName="employment_history" required></textarea>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Research Interest</mat-label>
                    <textarea matInput formControlName="research_interest" required></textarea>
                </mat-form-field>
 
                <mat-form-field appearance="outline"> 
                    <mat-label>Upload CV</mat-label>
                    <ngx-mat-file-input type='file' (change)="onSignatureSelectFile($event)" placeholder="Include a CV">
                    </ngx-mat-file-input>
                    <mat-icon matSuffix>folder</mat-icon>
                </mat-form-field>
            </div> 

            <div class="fifth-portion">
                <div class="d-flex justify-content-center mt-5">
                    <div class="form-group">
                        <button class="submit btn btn-primary" mat-raised-button
                            (click)="createFacultyMember()">Submit</button>
                    </div>
                </div>
            </div>
        </form>
    </mat-card>
</div>
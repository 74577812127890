<app-admin-navbar></app-admin-navbar>
<main class=" right-side-content">
  <mat-card>
    <div class="table-3 table-responsive">
      <h1>Admin List <button routerLink="/admin/create" style="position: absolute; right: 34px;"
          matTooltip="Create new admin"><img src="../../../assets/icon/faculty_add.png" /></button></h1>
      <table class="table table-bordered table-hover table-stripped">
        <tbody>

          <tr>
            <th>No</th>
            <th>Create Date</th>
            <th>Admin Email</th>
            <th colspan="1">Action</th>
          </tr>

          <ng-container *ngFor="let admin of adminList; index as i">
            <tr>
              <td class="text-center">{{i+1}}</td>
              <td class="text-center">{{admin?.adminCreateDate | date}}</td>
              <td class="text-center">{{admin?.email}}</td>
              <td class="text-center"> <button type="button" class="btn btn-primary" matTooltip="Access Edit"
                  (click)="giveAdminAccess(admin._id)"><img src="../../../../assets/icon/edit.png" alt=""></button>
              </td>

            </tr>
          </ng-container>

        </tbody>
      </table>
    </div>
  </mat-card>
</main>

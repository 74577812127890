<div class="sidebar-container">
  <app-sidebar></app-sidebar>
</div>

<video src="../../../assets/video/bousst.mp4" autoplay loop playsinline [muted]="'muted'"></video>

<header class="viewport-header">
  <div>
    <img src="../../../assets/home/sstName.png" alt="BOU Logo">
  </div>
</header>

<main>
  <div class="message-div">
    <img src="../../../assets/home/mujibCorner/spotlite3.webp" alt="Father of Nation" class="mujibPhoto">
    <div class="father-speech">
      <p class="mujibSpeech">“বাঙ্গালি বীরের জাতি পৃথিবীর কোনো শক্তি আমাদের পদানত করতে পারবে না।”

        -বঙ্গবন্ধু শেখ মুজিবুর রহমান</p>
    </div>
  </div>
  <div class="slogan-div">
    <div class="sloganImgDiv">
      <img src="../../../assets/home/bou.webp" alt="Bangladesh Open University">
    </div>
    <div class="slogan">
      “Intelligence without ambition is a bird without wings.” <span class="writer">—Salvador Dalí</span></div>
  </div>
  <div class="explore-div">
    <div class="centered-vertically">
      <a class="explore-btn">EXPLORE</a>
      <ul class="first-ul">
        <li><a routerLink="/about-sst">About SST</a></li>
        <li><a routerLink="/e-book">E-Book</a></li>
        <li style="border-right-width: 0;"><a routerLink="/research-and-journal">Journal</a></li>
      </ul>
      <ul class="second-ul">
        <li><a routerLink="/research-and-journal">Research</a></li>
        <li><a href="https://www.bou.ac.bd/" target="blank">BOU Main Website</a></li>
        <li style="border-right-width: 0;"><a routerLink="/faqs">FAQs</a></li>
      </ul>
    </div>
  </div>
  <div class="students-div">
    <div class="teacher-div">
      <div class="vc">
        <div class="teacher-image-div vc-image">
        </div>
        <div class="teacher-text">
          <h2>Message form VC</h2>
          <div class="underline-div"></div>
          <p>It gives me a great pleasure to welcome you all at the website of the School of Science and... &nbsp;<a
              (click)="goToLink('messages','vc-message')">See more</a></p>
        </div>
      </div>
      <div class="pro-vc">
        <div class="teacher-image-div pro-vc-image">
        </div>
        <div class="teacher-text">
          <h2>About SST</h2>
          <div class="underline-div"></div>
          <p>School of Science and Technology is one of the six schools of Bangladesh Open University... &nbsp;<a
              routerLink="/about-sst">See more</a></p>
        </div>
      </div>
      <div class="dean">
        <div class="teacher-image-div dean-image">
        </div>
        <div class="teacher-text">
          <h2>Message form Dean</h2>
          <div class="underline-div"></div>
          <p>Welcome to the Open and Distance learners of the programs of School of Science and Technology (SST)....
            &nbsp;<a (click)="goToLink('messages','dean-message')">See more</a></p>
        </div>
      </div>
    </div>

    <div class="flex-container">
      <div class="photo-frame-div">
        <div class="grid-container">
          <div class="item1"><img src="../../../assets/home/grid-photo/gridphoto1.jpg" alt="" class="grid-img"></div>
          <div class="item2"><img src="../../../assets/home/grid-photo/gridphoto2.jpg" alt="" class="grid-img"></div>
          <div class="item3"><img src="../../../assets/home/grid-photo/gridphoto3.jpg" alt="" class="grid-img"></div>
          <div class="item4"><img src="../../../assets/home/grid-photo/gridphoto4.jpg" alt="" class="grid-img"></div>
          <div class="item5"><img src="../../../assets/home/grid-photo/grid-photo5.jpg" alt="" class="grid-img"></div>
          <div class="item6"><img src="../../../assets/home/grid-photo/gridphoto6.jpg" alt="" class="grid-img"></div>
          <div class="item7"><img src="../../../assets/home/grid-photo/gridphoto7.jpg" alt="" class="grid-img"></div>
          <div class="item8"><img src="../../../assets/aboutSST/officialsGroupPhoto.jpg" alt="" class="grid-img"></div>
          <div class="item9"><img src="../../../assets/home/grid-photo/gridphoto9.jpg" alt="" class="grid-img"></div>
          <div class="item10"><img src="../../../assets/home/grid-photo/gridphoto10.jpg" alt="" class="grid-img"></div>
          <div class="item11"><img src="../../../assets/home/grid-photo/gridphoto11.jpg" alt="" class="grid-img"></div>
          <div class="item12"><img src="../../../assets/home/grid-photo/gridphoto12.png" alt="" class="grid-img"></div>
          <div class="item13"><img src="../../../assets/home/grid-photo/gridphoto13.jpg" alt="" class="grid-img"></div>
        </div>
      </div>
      <div class="frame-text-div">
        <h2>Faculty Members</h2>
        <p>“The aim of education should be to teach us rather how to think, than what to think — rather to improve our
          minds, so as to enable us to think for ourselves, than to load the memory with thoughts of other
          men.”<span><strong>Bill Beattie</strong></span></p>
      </div>
    </div>

    <div class="conteiner-flex">
      <div class="flex-child-1">
        <img class="" src="../../../assets/home/lastPortion/classroom.webp" alt="">
        <div class="overlay">
          <div class="text">Class Room</div>
        </div>
      </div>
      <div class="flex-child-2">
        <img class="" src="../../../assets/home/lastPortion/student.webp" alt="">
        <div class="overlay">
          <div class="text">Students</div>
        </div>
      </div>
      <div class="flex-child-3">
        <img class="" src="../../../assets/home/lastPortion/intconference.webp" alt="">
        <div class="overlay">
          <div class="text">International Conference</div>
        </div>
      </div>
      <div class="flex-child-4">
        <img class="" src="../../../assets/home/lastPortion/workshop.webp" alt="">
        <div class="overlay">
          <div class="text">Workshop</div>
        </div>
      </div>
    </div>
  </div>
  <div id="footer">
    <app-footer></app-footer>
  </div>
</main>

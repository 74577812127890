<app-navbar></app-navbar>
<div class="mainContainer">
    <section>
        <div class="firstPart">
            <div class="imgDiv">
                <img [src]="profileData?.applicantPicture" alt="">
            </div>
            <div class="nameDiv">
                <h1>{{profileData?.name}}</h1>
                <h3>{{profileData?.designation}}</h3>
            </div>
        </div>
        <div class="secondPart">
            <div class="sec-1">
                <h2>Social</h2>
                <div class="social">
                    <span *ngIf="profileData?.facebook!=' '"><a href="" href="{{profileData?.facebook}}" target="blank"><i class="fa fa-facebook"></i></a></span>
                    <span *ngIf="profileData?.twitter!=' '"><a href="" href="{{profileData?.twitter}}" target="blank"><i class="fa fa-twitter"></i></a></span>
                    <span *ngIf="profileData?.linkedin!=' '"><a href="" href="{{profileData?.linkedin}}" target="blank"><i class="fab fa-linkedin-in"></i></a></span>
                    <span *ngIf="profileData?.bio!=' '"><a href="" href="mailto:{{profileData?.bio}}" target="blank"><i class="fa fa-instagram"></i></a></span>
                </div>
                <h2 class="mt-3">Contacts</h2>
                <p *ngIf="profileData?.phoneNumber_office!=' '"><i class="fa fa-phone"
                        aria-hidden="true"></i>&nbsp;&nbsp;Off: {{profileData?.phoneNumber_office}}
                    &nbsp;&nbsp; Ex. {{profileData?.ex_number_office}}</p>
                <p *ngIf="profileData?.phoneNumber_personal!=' '"><i class="fa fa-phone"
                        aria-hidden="true"></i>&nbsp;&nbsp;Per: {{profileData?.phoneNumber_personal}}</p>
                <p *ngIf="profileData?.phoneNumber_residence!=' '"><i class="fa fa-phone"
                        aria-hidden="true"></i>&nbsp;&nbsp;Res: {{profileData?.phoneNumber_residence}}</p>
                <p *ngIf="profileData?.fax_number_office!=' '"><i class="fa fa-fax"
                        aria-hidden="true"></i>&nbsp;&nbsp;Fax: {{profileData?.fax_number_office}}</p>
                <p *ngIf="profileData?.email==' '"><i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;&nbsp;<a
                        href="mailto:{{profileData?.email}}" class="mailLink">{{profileData?.email}}</a></p>
                <div class="profileBtnContainer" *ngIf="profileData?.cvUrl!=' '">
                    <a href="{{profileData?.cvUrl}}" target="blank"><i class="fa fa-user" aria-hidden="true"></i> View
                        Profile</a>
                </div>
            </div>
            <div class="sec-2">
                 <h2 *ngIf="profileData?.research_interest!=' '" class="underlined">Research Interest</h2>
                <p *ngIf="profileData?.research_interest!=' '" class="sec2text">
                    {{profileData?.research_interest}}
                </p>
                <div class="workInfoDiv">
                    <div>
                        <div class="iconDiv">
                            <img src="../../../assets/facultyMembers/icon/expirencer.png">
                        </div>
                        <h2 class="workDetails">{{profileData?.experience}}</h2>
                        <p>of Experience</p>
                    </div>
                    <div>
                        <div class="iconDiv">
                            <img src="../../../assets/facultyMembers/icon/withbour.png">
                        </div>
                        <h2 class="workDetails">{{diffDays}} days</h2>
                        <p>with BOU</p>
                    </div>
                    <div>
                        <div class="iconDiv">
                            <img src="../../../assets/facultyMembers/icon/publicationr.png">
                        </div>
                        <h2 class="workDetails">{{profileData?.num_publiction}}</h2>
                        <p>Publications</p>
                    </div>
                </div>
                <h2 class="headingMarginChange" class="underlined mt-3">Employment History</h2>
                <div innerHtml="{{profileData?.employment_history}}" class="sec2text"></div>
            </div>
        </div>
    </section>
</div>
<div class="footer">
    <app-footer></app-footer>
</div>

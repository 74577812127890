<app-admin-navbar></app-admin-navbar> 
<main class="right-side-content">
    <mat-card>
    <div class="container-fluid">
        <div class="table-3 table-responsive">
            <h1>Student's Feedback List</h1>
            <table class="table table-bordered table-hover table-stripped">
                <tr>
                    <th>Date</th>
                    <th>Student ID</th>
                    <th>Student Name</th>
                    <th>Email</th>
                    <th>Complain</th>
                    <th>View</th>
                    <th>Status</th>
                </tr>
                <tr *ngFor="let complain of complaintList; index as i">
                    <td>{{complain.complaintSubmissionDate | date}}</td>
                    <td>{{complain.studentID}}</td>
                    <td>{{complain.studentName}}</td>
                    <td>{{complain.studentEmail}}</td>
                    <td>{{complain.topicName}}</td>
                  
                    <td class="text-center"> <button (click)="viewComplaint(complain._id)" matTooltip="View"><img src="../../../../assets/icon/binocular.png" alt=""></button> </td>
                    <td style="text-align: center; font-weight: bold;"
                        [ngClass]="{'btn-warning': complain.complaintStatus == 'Seen', 'btn-danger':complain.complaintStatus == 'Unseen', 'btn-success':complain.complaintStatus == 'Done' }">
                        {{complain.complaintStatus}} </td>
                </tr>
            </table>
        </div>
    </div>
</mat-card>
</main>
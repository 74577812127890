<!-- <app-admin-navbar></app-admin-navbar>
<main class="pt-5 mx-lg-5">
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-sm">
        <mat-card routerLink="/dashboard">
          <h1>Dashboard</h1>
        </mat-card>
      </div>

      <div class="col-sm">
        <mat-card style="background-color: #5cb85c;"
          routerLink="/complains">
          <h1>Complains</h1>
        </mat-card>
      </div>

      <div class="col-sm">
        <mat-card style="background-color:#d9534f;"
          routerLink="/admin/notice/view">
          <h1>Notices</h1>
        </mat-card>
      </div>

    </div>

  </div>
</main> -->

<app-complains></app-complains>
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs'; 
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApplicantService {
  result: any
  baseURL: string = environment.API;
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  createNewApplicant(applicant: any) {
    return this.http.post<any>(`${this.baseURL}/api/application/new`, applicant)
  }
  createNewPayment(payment: any) {
    return this.http.post<any>(`${this.baseURL}/api/payment/start`, payment)
  }

  getApplicants() {
    return this.http.get(`${this.baseURL}/allApplicant`);
  }

  // Get applicant
  getApplicant(id): Observable<any> {
    let url = `${this.baseURL}/applicant_read/${id}`;
    return this.http.get(url, { headers: this.headers }).pipe(
      map((res: Response) => {
        return res || {}
      }),
      catchError(this.errorMgmt)
    )
  }


  // Error handling 
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
<div class="container-fluid">
    <div class="row footer-div">
        <div class="col-md-6">
            <div class="first-col" id="contact-us">
                <!-- <h2>Contact to the School</h2> -->
                <h2>Dean Office</h2>
          
                School of Science and Technology (SST) <br>
                Bangladesh Open University, Gazipur-1705. <br>
                Phone : 9291111 (Direct), 09666730730 Ex.685 <br>
                Fax : 880-2-9291111 <br>
                E-mail :deansst2017&#64;gmail.com <br>
            </div>
            <div class="social-band">
                <ul id="social-ul">
                  <li><a href="https://www.fb.com/sstbou" target="blank"><i class="fa fa-facebook"></i></a></li>
                  <li><a href="https://twitter.com/bouedu?lang=en" target="blank"><i class="fa fa-twitter"></i></a></li>
                  <li><a href="https://www.youtube.com/c/BangladeshOpenUniversityOpenBangla/featured" target="blank"><i class="fa fa-youtube-play"></i></a></li>
                  <li><a href="https://bd.linkedin.com/school/bangladesh-open-university/" target="blank"><i class="fa fa-linkedin"></i></a></li>
                  <li><a href="https://www.instagram.com" target="blank"><i class="fa fa-instagram"></i></a></li>
                  </ul>
              </div>
        </div>
        <div class="col-md-6 logo-map-container">
            <div class="map-container">
                <img src="../../../assets/footer/map-img.png" alt="Map" class="map-image">
                <div id="map" class="map"></div>
            </div>
        </div>
    </div>
</div>
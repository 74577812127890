<app-navbar></app-navbar>
<div class="container">
    <div class="row">
        <div class="col-sm-2">

        </div>
        <div class="col-sm-8">
            <mat-card>
                <img class="image" mat-card-image src="../../../assets/question-icon.png">
                <mat-card-title class="text-center">Make Admission Fees Now ?</mat-card-title>
                <mat-card-content class="text-center">

                </mat-card-content>
                <mat-card-actions class="text-center">
                    <button class="btn-text-center" (click)="makePayment()" mat-raised-button>Make Payment!</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="col-sm-2">

        </div>
    </div>
</div>
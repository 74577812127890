import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bsc-nursing',
  templateUrl: './bsc-nursing.component.html',
  styleUrls: ['./bsc-nursing.component.scss']
})
export class BscNursingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="button-container">
        <div class="btn-div">
            <div *ngIf="isLoaded">
                <button (click)="prevPage()" [disabled]="page === 1">Prev</button>
                <span>{{ page }} / {{ totalPages }}</span>
                <button (click)="nextPage()" [disabled]="page === totalPages">Next</button>
            </div>
        </div>
        <div class="btn-div" style="text-align: right">
            <button (click)="download()" [disabled]="!isLoaded">Download</button>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-sm-12">
            <ngx-extended-pdf-viewer [src]="pdfSource"></ngx-extended-pdf-viewer>
        </div>
    </div> -->
    <div class="button-container">
        <div class="btn-div">
            <div *ngIf="isLoaded">
                <button (click)="prevPage()" [disabled]="page === 1">Prev</button>
                <span>{{ page }} / {{ totalPages }}</span>
                <button (click)="nextPage()" [disabled]="page === totalPages">Next</button>
            </div>
        </div>
        <div class="btn-div" style="text-align: right">
            <button (click)="download()" [disabled]="!isLoaded">Download</button>
        </div>
    </div>
</div>

<br>
<br>
<br>
<br>

<app-footer></app-footer>
import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
// import { SnotifyService } from 'ng-snotify';

@Injectable()
export class AccessGuardService implements CanActivate {
  haveAccess = false
  constructor(public auth: AuthService, public router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    this.auth.getAccess(JSON.parse(localStorage.getItem('_id'))).subscribe((accessData) => {
      if (accessData.isFound) {
        accessData?.result?.access.forEach(element => {
          if (element.routeName === route.data.routeName) {
            if (element.hasAccess === true) {
              this.haveAccess = true;

            } else {
             // this.snotifyService.error("Need access permission from admin.")
              this.router.navigateByUrl('dashboard')
              return this.haveAccess = false;
            }

          }

        });

      }
    })
    return this.haveAccess;

  }


}

<app-admin-navbar></app-admin-navbar>
<main class=" right-side-content">
    <mat-card>
        <div class="table-3 table-responsive">
            <h1>Routine List <button routerLink="/admin/routine/create" style="position: absolute; right: 34px;"  matTooltip="Upload Routine"><img src="../../../assets/icon/faculty_add.png"/></button></h1>
            <table class="table table-bordered table-hover table-stripped">
                <tbody>

                    <tr>
                        <th>No</th>
                        <th>Date</th>
                        <th>Program Name</th>
                        <th>Study Center</th>
                        <th>Routine Year</th>
                        <th>Routine Semester</th>
                        <th colspan="4">Action</th>
                    </tr>

                    <ng-container *ngFor="let doc of routineList; index as i">
                        <tr *ngIf="!doc?.isDeleted">
                            <td class="text-center">{{i+1}}</td>
                            <td class="text-center">{{doc?.routineSubmissionDate | date}}</td> 
                            <td class="text-center">{{doc?.programName}}</td>
                            <td class="text-center">{{doc?.studyCenter}}</td>
                            <td class="text-center">{{doc?.routineYear}}</td>
                            <td class="text-center">{{doc?.routineSemister}}</td>
                            <td class="text-center"><button type="button" class="btn btn-success"
                                (click)="viewNotice(doc?.routineFile)"><img src="../../../../assets/icon/binocular.png" alt=""></button>
                            </td>
                            <td class="text-center"> <button type="button" class="btn btn-primary" (click)="doEdit(doc)"><img src="../../../../assets/icon/edit.png" alt=""></button>
                            </td>
                            <td class="text-center"><button *ngIf="!doc?.isDisabled" type="button" class="btn btn-secondary"
                                    (click)="makeDisable(doc?._id)"><img src="../../../../assets/icon/disable.png" alt=""></button>
                                <button *ngIf="doc?.isDisabled" type="button" class="btn btn-success"
                                    (click)="makeEnable(doc?._id)"><img src="../../../../assets/icon/enable.png" alt=""></button>
                            </td>
                            <td class="text-center"><button type="button" class="btn btn-danger"
                                    (click)="makeDelete(doc?._id)"><img src="../../../../assets/icon/bin.png" alt=""></button></td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
        </div>
    </mat-card>
</main>
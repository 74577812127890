import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-applyform',
  templateUrl: './applyform.component.html',
  styleUrls: ['./applyform.component.scss']
})
export class ApplyformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

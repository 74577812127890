import { RoutineService } from './../../../services/routine.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { Router } from '@angular/router';

@Component({
  selector: 'app-routine-edit',
  templateUrl: './routine-edit.component.html',
  styleUrls: ['./routine-edit.component.scss']
})
export class RoutineEditComponent implements OnInit {

  constructor(private router: Router, private routineService: RoutineService, public fb: FormBuilder) { }
  editRoutineForm: any;
  editRoutineFormData: any;
  RoutineSession = ['2010-2011', '2011-2012', '2012-2013', '2013-2014', '2014-2015', '2015-2016', '2016-2017', '2017-2018', '2018-2019', '2019-2020', '2020-2021', '2021-2022', '2022-2023', '2023-2024', '2024-2025', '2025-2026', '2026-2027', '2027-2028', '2028-2029', '2029-2030', '2030-2031', '2031-2032', '2032-2033', '2033-2034', '2034-2035', '2035-2036', '2036-2037', '2037-2038', '2038-2039', '2039-2040', '2040-2041', '2041-2042', '2042-2043', '2043-2044', '2044-2045'];
  RoutineSemister = ['1st', '2nd'];
  RoutineYear = ['1st Year', '2nd Year', '3rd Year', '4th Year'];
  StudyCenter = ['RRC', 'DUET', 'Gazipur'];
  ProgramName = ['Diploma in Computer Science and Application Program (DCSA)', 'B.Sc In Computer Science and Engineering (CSE)', 'B.Sc in Nursing', 'B.Sc (Hons) in Food Science and Nutrition', 'Master of Disability Management and Rehabilitation (MDMR) Program', 'Appication Form for Master of Public Health', 'Post Graduate Diploma in Medical Ultrasound (PGDMU)'];
  ngOnInit(): void {
    this.editRoutineFormData = this.routineService.editRoutineData
    if (this.editRoutineFormData == undefined || this.editRoutineFormData == null) {
      this.router.navigate(['/admin/routine/view'])
    }
    console.log(this.editRoutineFormData)
    this.editRoutineForm = this.fb.group({
      routineYear: [this.editRoutineFormData?.routineYear],
      routineSemister: [this.editRoutineFormData?.routineSemister],
      documentFile: [''],
      routineSession: [this.editRoutineFormData?.routineSession],
      programName: [this.editRoutineFormData?.programName]
    });
  }

  onSelectFile(event) {
    const file = event.target.files[0];
    this.editRoutineForm.patchValue({
      documentFile: file
    });
  }
  editRoutine() {
    console.log(this.editRoutineForm.value)
    let form_data = new FormData();

    for (let key in this.editRoutineForm.value) {
      form_data.append(key, this.editRoutineForm.value[key]);
    }
    this.routineService.editRoutine(form_data, this.editRoutineFormData._id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("success")
          // window.location.reload()

          this.router.navigateByUrl("/admin/routine/view")
        }
      })
  }

}

<header>
    <div><img src="../../../assets/index1.png" alt="Bou logo" class="img-fluid"></div>
    <div class="name-container">
        <h3>School of Science and Technology</h3>
        <h1>Bangladesh Open University</h1>
    </div>
</header>
<div class="container">
<div class="serial-no-div">
    <div>*From SL No. - </div>
    <div>
        <table>
            <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
            </tr>
        </table>
    </div>
</div>
<div class="heading">
    <h1><u><b>ADMIT CARD</b></u></h1>
    <h2>Admission Test for B.Sc (Hons) in Food Science and Nutrition Program</h2>
</div>
<div class="roll-no-div">
    <div><b>Admission Test Roll No. <span><i>(For office use only)</i></span> :</b></div>
    <div>
        <table>
            <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
            </tr>
        </table>
    </div>
</div>
<div class="admit-form">
    <form>
        <div class="image-upload">
            <!-- Image Preview -->
            <label class="hoverable" for="fileInput">
                <img [src]="url">
                <div class="hover-text">Attach here one attested passport size phohograph </div>
            </label>
            <br>
        </div>
        <div class="form-group row">
          <label for="session" class="col-sm-3 col-form-label"><b>Session</b> <i>(For offiece use only)</i><b> :</b></label>
          <div class="col-sm-5">
            <input type="text" class="form-control" placeholder="">
          </div>
        </div>
        <div class="form-group row">
          <label for="date&time" class="col-sm-3 col-form-label">
              <b>Date and Time of Admission Test :</b><br>
              <i>(For offiece use only)</i>
          </label>
          <div class="col-sm-5">
            <input type="text" class="form-control" placeholder="">
          </div>
        </div>
        <div class="form-group row">
            <label for="examCenter" class="col-sm-3 col-form-label">
                <b>Center of the Admission Test :</b><br>
                <i>(For offiece use only)</i>
            </label>
            <div class="col-sm-5">
              <input type="text" class="form-control" placeholder="">
            </div>
          </div>
          <div class="form-group row">
            <label for="applicantName" class="col-sm-3 col-form-label">
                <b>Name of the Applicant :</b>
            </label>
            <div class="col-sm-8">
              <input type="text" class="form-control" placeholder="">
            </div>
          </div>
          <div class="form-group row">
            <label for="fathersName" class="col-sm-3 col-form-label">
                <b>Father's Name :</b>
            </label>
            <div class="col-sm-8">
              <input type="text" class="form-control" placeholder="">
            </div>
          </div>
          <div class="form-group row">
            <label for="mothersName" class="col-sm-3 col-form-label">
                <b>Mother's Name :</b>
            </label>
            <div class="col-sm-8">
              <input type="text" class="form-control" placeholder="">
            </div>
          </div>
      </form>
</div>

<div class="row seal-div">
    <div class="col-sm-1"></div>
    <div class="col-sm-2 seal" ><span><b>Seal</b></span></div>
    <div class="col-sm-5"></div>
    <div class="col-sm-4">
        <h3><b>(Signature of the Admission Committee Chairman/ RC Official)</b></h3>
    </div>   
</div>

<div class="note-div">
    <h4><u><b>Note</b></u></h4>
    <section>
        <p>
            You may get the following information through BOU Notice Board, National Dailies and Webisites
            (www.bousst.edu.bd or www.bou.edu.bd).
        </p>
        <ol>
            <li>
                List of eligible candidates for admission test;
            </li>
            <li>
                Date, Time and Venue of admission test;
            </li>
            <li>
                List of selected candidates according to merit;
            </li>
            <li>
                Date and Time of admission, orientation and class.
            </li>
        </ol>
    </section>
</div>
</div>

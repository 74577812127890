
    <mat-card>
        <h1>Reply Student Feedback</h1> 
        <div class="form-1">
            <form [formGroup]="replyForm">

                <mat-form-field appearance="outline">
                    <mat-label>Message body</mat-label>
                    <textarea matInput formControlName="replyBody" placeholder="Message boddy" required></textarea>
                </mat-form-field>

                <mat-form-field appearance="fill"> 
                    <mat-label>File (Optional) </mat-label>
                    <ngx-mat-file-input type='file' (change)="onSelectFile($event)"
                        placeholder="Include necessary file (Optional)">
                    </ngx-mat-file-input>
                    <mat-icon matSuffix>folder</mat-icon>
                </mat-form-field>
            </form>
            <button class="btn btn-primary" (click)="sendReply()">Send</button>
        </div>
    </mat-card>

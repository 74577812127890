import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NoticeService } from 'src/app/services/notice.service';
import { DocumentsService } from '../../../services/documents.service'
@Component({
  selector: 'app-study-guides',
  templateUrl: './study-guides.component.html',
  styleUrls: ['./study-guides.component.scss']
})
export class StudyGuidesComponent implements OnInit {
  public studyGuides;
  constructor(private documentsService: DocumentsService, public noticeService: NoticeService, private router: Router) { }

  ngOnInit(): void {
    this.documentsService.getDocument('Program Handbook')
      .subscribe((res) => {
        if (res.isFound) {
          let guidesList = [];
          res.result.forEach(guide => {
            if (guide.programName.toLowerCase().includes("diploma") && !guide.programName.toLowerCase().includes("graduate")) {
              guidesList.push(guide)
            }
          });

          res.result.forEach(guide => {
            if (guide.programName.toLowerCase().includes("bachelor") || guide.programName.toLowerCase().includes("bsc") || guide.programName.toLowerCase().includes("b.sc")) {
              guidesList.push(guide)
            }
          });

          res.result.forEach(guide => {
            if (guide.programName.toLowerCase().includes("master") || guide.programName.toLowerCase().includes("msc") || guide.programName.toLowerCase().includes("m.sc")) {
              guidesList.push(guide)
            }
          });

          res.result.forEach(guide => {
            if (guide.programName.toLowerCase().includes("graduate")) {
              guidesList.push(guide)
            }
          });

          this.studyGuides = guidesList

          console.log(this.studyGuides)
        }
        else {
          alert(res.error)
        }
      })

  }
  viewPDF(url) {
    this.fileLink(url[0])
  }
  downloadPDF(url) {
    window.open(
      url[0],
      '_blank'
    );
  }
  fileLink(file: string) {
    console.log(file)
    if (file.substr(-4) == '.pdf') {
      this.noticeService.pdfURL = file;
      this.router.navigate(['/pdfviewer-notice'])
    }
    else {
      window.open(
        file,
        '_blank'
      );

    }

  }

}

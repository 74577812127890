import { NoticeService } from './../../../services/notice.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-notice',
  templateUrl: './edit-notice.component.html',
  styleUrls: ['./edit-notice.component.scss']
})
export class EditNoticeComponent implements OnInit {

  noticeList
  creatNoticeForm
  constructor(public noticeService: NoticeService, private router: Router) { }

  ngOnInit(): void {

    this.noticeService.getAllNotice()
      .subscribe(data => {
        if (data.isFound) {
          this.noticeList = data.result;
          console.log(this.noticeList)
        }
      })
  }
  doEdit(data) {

    this.noticeService.editNoticeData = data
    this.router.navigate(['/admin/notice/edit'])

  }
  makeDelete(id: string) {
    let data = {
      isDeleted: true
    }
    this.noticeService.deleteNotice(id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("success")
          window.location.reload()
        }
      })

  }
  makeDisable(id: string) {
    let data = {
      isDisabled: true
    }
    this.noticeService.disableNotice(data, id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("success")
          window.location.reload()
        }
      })
  }
  makeEnable(id) {
    let data = {
      isDisabled: false
    }
    this.noticeService.disableNotice(data, id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("success")
          window.location.reload()
        }
      })
  }
  viewNotice(url) { 
    this.fileLink(url[0])
  }
  fileLink(file: string) {
    console.log(file)
    if (file.substr(-4) == '.pdf') {
      this.noticeService.pdfURL = file;
      this.router.navigate(['/pdfviewer-notice'])
    }
    else {
      window.open(
        file,
        '_blank'
      );

    }
  }


}

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComplaintService {
  baseURL: string = environment.API;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  complaintId: string
  constructor(private http: HttpClient) { }
 
  createNewComplaint(complaint: any) {
    return this.http.post<any>(`${this.baseURL}/api/complaint/new`, complaint)
  }
  getAllComplaint() {
    return this.http.get<any>(`${this.baseURL}/api/complaint/list`, { headers: this.headers })
  }
  getComplaintById(id: string) {
    return this.http.get<any>(`${this.baseURL}/api/complaint/view/${id}`, { headers: this.headers })
  }
  getStatusById(id: string) {
    return this.http.get<any>(`${this.baseURL}/api/complaint/status/${id}`, { headers: this.headers })
  }
  updateComplaintById(id: string) {
    return this.http.put<any>(`${this.baseURL}/api/complaint/seen/${id}`, { headers: this.headers })
  }
  deleteComplain(id: string) {
    return this.http.delete<any>(`${this.baseURL}/api/complaint/delete/${id}`)
  }
  sendReply(reply: any) {
    return this.http.put<any>(`${this.baseURL}/api/complaint/reply/${this.complaintId}`, reply)
  }


}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacultyMemberService } from 'src/app/services/faculty-member.service';

@Component({
  selector: 'app-faculty-members-details',
  templateUrl: './faculty-members-details.component.html', 
  styleUrls: ['./faculty-members-details.component.scss']
})
export class FacultyMembersDetailsComponent implements OnInit {

  today = new Date() as any;
  oldDate = new Date() as any;
  diffDays = new Date() as any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private facultyMemberService: FacultyMemberService) { }

profileData:any;

  ngOnInit(): void {


    this.activatedRoute
        .queryParams
        .subscribe(params => {
            let id = params['id'];
            this.facultyMemberService.getMember(id)
            .subscribe((result:any)=>{
              if(result.isFound){
this.profileData = result.result
console.log(this.profileData)
this.oldDate = new Date(result.result.days_bou) as any;
this.diffDays =  Math.floor(Math.abs(this.oldDate - this.today)/(1000 * 3600 * 24));
              }else{
                alert("Not found !")
              }
            })
        });
  }
  
  ngAfterViewChecked() {

  }

}

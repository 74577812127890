<app-navbar></app-navbar>
<mat-card>
  <div class="wrapper">
    <h1>Program Handbooks</h1> 
  </div>
<hr>
<div class="flex-container">
<div *ngFor='let guide of studyGuides; index as i' class="book-cntainer-card">
    <h3>SL. No. {{i+1}}</h3>
    <div class="book-cntainer">
        <div class="book">
          <div class="cover"></div>
          <h2>{{guide.documentFirstName}}<span> {{guide.documentLastName}}</span></h2>
          <span class="writer"><i>Study Guides</i></span>
        </div>
      </div>
      <p><b>Program Name:</b> {{guide.programName}}</p>
      <div class="btn-container">
          <!-- <button class="viewBtn" (click)="viewPDF(guide.documentFile)" matTooltip="View PDF"><img src="../../../../assets/icon/pdf.png"/></button> -->
          <button class="downloadBtn" (click)="downloadPDF(guide.documentFile)"matTooltip="View PDF"><img src="../../../../assets/icon/pdf.png"/></button>
      </div>
</div>
</div>
</mat-card>
<br>
<br>
<br>
<br>
<app-footer></app-footer>

<app-navbar></app-navbar>
<div class="container">
    <div class="wrapper">
        <h1>Video Contents</h1>
      </div><hr>
<div class="flex-container">
    <mat-card>
        <a routerLink="/dcsa-video-content">
        <h3>Video content for Diploma in Computer Science and Application (DCSA)</h3>
        <mat-icon>developer_board</mat-icon>
        </a>
        <div class="youtubeBtn">
            <a href="https://www.youtube.com/playlist?list=PL0JIXvZaEYIdMkkasDveP5p7Z8hE8_eLQ">Youtube</a>
        </div>
    </mat-card>
    <mat-card>
        <a routerLink="/cse-video-content">
        <h3>Video content for B.Sc in Computer Science and Engineering (CSE)</h3>
        <mat-icon>cast_for_education</mat-icon>
        </a>
        <div class="youtubeBtn">
            <a href="https://www.youtube.com/playlist?list=PL0JIXvZaEYIdMkkasDveP5p7Z8hE8_eLQ">Youtube</a>
        </div>
    </mat-card>

    <mat-card>
        <a routerLink="/bsn-video-content">
        <h3>Video content for B.Sc. in Nursing (BSN)</h3>
        <mat-icon>healing</mat-icon>
        </a>
    </mat-card>
    
    <mat-card>
        <a routerLink="/fsn-video-content">
        <h3>Video content for B.Sc (Hons) in Food Science and Nutrition</h3>
        <mat-icon>food_bank</mat-icon>
        </a>
    </mat-card>    
    <mat-card>
        <a routerLink="/mdmr-video-content">
        <h3>Video content for Master of Disability Management and Rehabilitation (MDMR)</h3>
        <mat-icon>local_hotel</mat-icon>
        </a>
        <div class="youtubeBtn">
            <a href="https://www.youtube.com/playlist?list=PL0JIXvZaEYIc6zBMz0S7-Ij88yyqUgC9q">Youtube</a>
        </div>
    </mat-card>
    <mat-card>
        <a routerLink="/mph-video-content">
        <h3>Video content for Master of Public Health (MPH)</h3>
        <mat-icon>local_hospital</mat-icon>
        </a>
        <div class="youtubeBtn">
            <a href="https://www.youtube.com/playlist?list=PL0JIXvZaEYIdYPmHxk0idJFeqJ4amKE1a">Youtube</a>
        </div>
    </mat-card>
    <mat-card>
        <a routerLink="/pgdmu-video-content">
        <h3>Video content for Post Graduate Diploma in Medical Ultrasound (PGDMU)</h3>
        <mat-icon>biotech</mat-icon>
        </a>
        <div class="youtubeBtn">
            <!-- <a href="">Youtube</a> -->
        </div>
    </mat-card>
</div>
</div>

<app-footer></app-footer>
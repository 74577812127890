<app-admin-navbar></app-admin-navbar>
<main class="pt-5 mx-lg-5">
    <div class="container-fluid mt-5">
        <mat-card>
            <h3>Edit Document</h3> 
            <div class="form-1">
                <form [formGroup]="editDocumentForm">

                    <mat-form-field appearance="fill">
                        <mat-label>Document First Name</mat-label>
                        <input matInput formControlName="documentFirstName" placeholder="Document First Name" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Document Last Name</mat-label>
                        <input matInput formControlName="documentLastName" placeholder="Document Last Name" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Document Category</mat-label>
                        <mat-select formControlName="documentCategory">
                            <mat-option *ngFor="let documentCategory of category" [value]="documentCategory" required>
                                {{documentCategory}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Program Name</mat-label>
                        <input matInput formControlName="programName" placeholder="Program Name" required>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>File </mat-label>
                        <ngx-mat-file-input type='file' (change)="onSelectFile($event)">
                        </ngx-mat-file-input>
                        <mat-icon matSuffix>folder</mat-icon>
                    </mat-form-field>
                </form>
                <button class="btn btn-primary" (click)="editDocument()">Edit</button>
            </div>
        </mat-card>
    </div>
</main>
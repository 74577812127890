import { ApplicantService } from './../../services/applicant.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from "@angular/forms";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({ 
  selector: 'app-fsn-reg-form',
  templateUrl: './fsn-reg-form.component.html',
  styleUrls: ['./fsn-reg-form.component.scss']
})
export class FsnRegFormComponent implements OnInit {

  // public studyCenterList = ['Uttara Study Center, BOU (USC)', 'International Institute of Health Sciences (IIHS)', 'Daffodil International University (DIU)'];
  // public regeionalCenterList = ['Dhaka'];
  public regionalCenter: any;
  public sscResult: any;
  public hscResult: any;
  public bachelorResult: any;
  public otherResult: any;
  public genderList = ['Male', 'Female'];
  public marital_Status = ['Single', 'Married'];
  public preferrMoodStudy = ['Regular Education (Class Sunday to Thursday)', 'Distance Education (Class Friday to Saturday)'];
  public sscDegreeList = ['SSC', 'Dakhil', 'SSC (Vocational)', 'O Level/Cambridge', 'S.S.C Equivalent'];
  public hscDegreeList = ['HSC', 'Alim', 'Business Management', 'A Level/Sr. Cambridge', 'H.S.C Equivalent', 'Diploma in Computer Science Engineering', 'Diploma in Civil Engineering', 'Diploma in Mechanical Engineering', 'Diploma in Electrical Engineering', 'Diploma in Automobile Engineering', 'Diploma in Chemical Engineering', 'Diploma in Nursing'];
  public bachelorDegreeList = ['Honours (3 Years)', 'Honours (4 Years)', 'Honours (5 Years)', 'M.B.B.S'];
  public bachelorSubjectList = ['Computer Science and Engineering (CSE)', 'B.Sc in Computer Science', 'Physics', 'Mathematics', 'Chemistry', 'Electrical and Electronics Engineering', 'Electronics and Communication Engineering', 'Statistics', 'Botany', 'Zoology', 'Arabic and Islamic Studies', 'Bengali', 'English', 'History', 'Philosophy', ' Islamic history and Sanskrit', 'Bachelor of Business Administration (BBA)', 'Accounting', 'Finance', 'Management', 'Marketing', 'Bachelor of Social Science (Honours)', 'Law', 'Economics', 'Political Science', 'Sociology', 'Social Work', 'Geography', 'Psychology', 'Bachelor of Agricultural Education (B. Ag. Ed)', 'Bachelor of Education (BEd)', 'Bachelor of Arts (BA)', 'Bachelor of Social Science (BSS)', 'Bachelor of English Language Teaching (BELT)'];
  public sscBoardUniversityList = ['Dhaka', 'Barishal', 'Chittagong', 'Comilla', 'Rajshahi', 'Dinajpur', 'Jessore', 'Sylhet', 'Madrasah', 'Technical', 'DIBS(Dhaka)', 'Cambridge International - ICGE', 'Others'];
  public hscBoardUniversityList = ['Dhaka', 'Barishal', 'Chittagong', 'Comilla', 'Rajshahi', 'Dinajpur', 'Jessore', 'Sylhet', 'Madrasah', 'Technical', 'DIBS(Dhaka)', 'Cambridge International - ICGE', 'Others']; 
  public sscSessionList = ['1969-70', '1970-71', '1971-72', '1972-73', '1973-74', '1974-75', '1975-76', '1976-77', '1977-78', '1978-79', '1979-80', '1980-81', '1981-82', '1982-83', '1983-84', '1984-85', '1985-86', '1986-87', '1987-88', '1988-89', '1989-90', '1990-91', '1991-92', '1992-93', '1993-94', '1994-95', '1995-96', '1996-97', '1997-98', '1998-99', '1999-2000', '2000-2001', '2001-2002', '2002-2003', '2003-2004', '2004-2005', '2005-2006', '2006-2007', '2007-2008', '2008-2009', '2009-2010', '2010-2011', '2011-2012', '2012-2013', '2013-2014', '2014-2015', '2015-2016', '2016-2017', '2017-2018', '2018-2019', '2019-2020', '2020-2021', '2021-2022', '2022-2023', '2023-2024', '2024-2025', '2025-2026', '2026-2027', '2027-2028', '2028-2029', '2029-2030'];
  public hscSessionList = ['1969-70', '1970-71', '1971-72', '1972-73', '1973-74', '1974-75', '1975-76', '1976-77', '1977-78', '1978-79', '1979-80', '1980-81', '1981-82', '1982-83', '1983-84', '1984-85', '1985-86', '1986-87', '1987-88', '1988-89', '1989-90', '1990-91', '1991-92', '1992-93', '1993-94', '1994-95', '1995-96', '1996-97', '1997-98', '1998-99', '1999-2000', '2000-2001', '2001-2002', '2002-2003', '2003-2004', '2004-2005', '2005-2006', '2006-2007', '2007-2008', '2008-2009', '2009-2010', '2010-2011', '2011-2012', '2012-2013', '2013-2014', '2014-2015', '2015-2016', '2016-2017', '2017-2018', '2018-2019', '2019-2020', '2020-2021', '2021-2022', '2022-2023', '2023-2024', '2024-2025', '2025-2026', '2026-2027', '2027-2028', '2028-2029', '2029-2030'];
  public bachelorSessionList = ['1969-70', '1970-71', '1971-72', '1972-73', '1973-74', '1974-75', '1975-76', '1976-77', '1977-78', '1978-79', '1979-80', '1980-81', '1981-82', '1982-83', '1983-84', '1984-85', '1985-86', '1986-87', '1987-88', '1988-89', '1989-90', '1990-91', '1991-92', '1992-93', '1993-94', '1994-95', '1995-96', '1996-97', '1997-98', '1998-99', '1999-2000', '2000-2001', '2001-2002', '2002-2003', '2003-2004', '2004-2005', '2005-2006', '2006-2007', '2007-2008', '2008-2009', '2009-2010', '2010-2011', '2011-2012', '2012-2013', '2013-2014', '2014-2015', '2015-2016', '2016-2017', '2017-2018', '2018-2019', '2019-2020', '2020-2021', '2021-2022', '2022-2023', '2023-2024', '2024-2025', '2025-2026', '2026-2027', '2027-2028', '2028-2029', '2029-2030'];
  public othersSessionList = ['1969-70', '1970-71', '1971-72', '1972-73', '1973-74', '1974-75', '1975-76', '1976-77', '1977-78', '1978-79', '1979-80', '1980-81', '1981-82', '1982-83', '1983-84', '1984-85', '1985-86', '1986-87', '1987-88', '1988-89', '1989-90', '1990-91', '1991-92', '1992-93', '1993-94', '1994-95', '1995-96', '1996-97', '1997-98', '1998-99', '1999-2000', '2000-2001', '2001-2002', '2002-2003', '2003-2004', '2004-2005', '2005-2006', '2006-2007', '2007-2008', '2008-2009', '2009-2010', '2010-2011', '2011-2012', '2012-2013', '2013-2014', '2014-2015', '2015-2016', '2016-2017', '2017-2018', '2018-2019', '2019-2020', '2020-2021', '2021-2022', '2022-2023', '2023-2024', '2024-2025', '2025-2026', '2026-2027', '2027-2028', '2028-2029', '2029-2030'];
  public bachelorUniversityList = ['Bangabandhu Sheikh Mujib Medical University', 'Bangabandhu Sheikh Mujibur Rahman Agricultural University', 'Bangabandhu Sheikh Mujibur Rahman Maritime University', 'Bangabandhu Sheikh Mujibur Rahman Science & Technology University', 'Bangladesh Agricultural University', 'Bangladesh Open University', 'Bangladesh University of Engineering & Technology', 'Bangladesh University of Professionals', 'Bangladesh University of Textiles', 'Barisal University', 'Begum Rokeya University', 'Chittagong Medical University', 'Chittagong University of Engineering & Technology', 'Chittagong Veterinary and Animal Sciences University', 'Comilla University', 'Dhaka University of Engineering & Technology', 'Hajee Mohammad Danesh Science & Technology University', 'Islamic Arabic University', 'Islamic University', 'Jagannath University', 'Jahangirnagar University', 'Jatiya Kabi Kazi Nazrul Islam University', 'Jessore University of Science & Technology', 'Khulna University', 'Khulna University of Engineering and Technology', 'Mawlana Bhashani Science & Technology University', 'National University', 'Noakhali Science & Technology University', 'Pabna University of Science and Technology', 'Patuakhali Science And Technology University', 'Rajshahi Medical University', 'Rajshahi University of Engineering & Technology', 'Rangamati Science and Technology University', 'Shahjalal University of Science & Technology', 'Sher-e-Bangla Agricultural University', 'Sylhet Agricultural University', 'University of Chittagong', 'University of Dhaka', 'University of Rajshahi'];
  public sscPassingYearList = ['1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
  public hscPassingYearList = ['1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
  public bachelorPassingYearList = ['1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
  public othersPassingYearList = ['1970', '1971', '1972', '1973', '1974', '1975', '1976', '1977', '1978', '1979', '1980', '1981', '1982', '1983', '1984', '1985', '1986', '1987', '1988', '1989', '1990', '1991', '1992', '1993', '1994', '1995', '1996', '1997', '1998', '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
  public sscGroupDisciplineList = ['Business Studies', 'Science', 'Humanities']; 
  public hscGroupDisciplineList = ['Business Studies', 'Science', 'Humanities'];
  public sscDivisionList = ['1st Division', '2nd Division', '3rd Division', 'GPA/CGPA in scale 4', 'GPA/CGPA in scale 5'];
  public hscDivisionList = ['1st Division', '2nd Division', '3rd Division', 'GPA/CGPA in scale 4', 'GPA/CGPA in scale 5'];
  public bachelorDivisionList = ['1st Division', '2nd Division', '3rd Division', 'GPA/CGPA in scale 4', 'GPA/CGPA in scale 5'];
  public othersDivisionList = ['1st Division', '2nd Division', '3rd Division', 'GPA/CGPA in scale 4', 'GPA/CGPA in scale 5'];

  public RCList = [
    { key: "Dhaka", value: ['Uttara Study Center, BOU (USC)', 'International Institute of Health Sciences (IIHS)', 'Daffodil International University (DIU)'] },
  ];

  FSN_application_form: any;
  myControl = new FormControl();
  hide = true;
  filteredOptions: Observable<string[]>;
  url: any;
  preview: string;
  freedomFighter = 'no';
  smallEthnic = 'no';
  disablePerson = 'no';
  dismissed = 'no';
  isDisabled = true;
  isDisabled2 = true;
  freedomFighterFileSubmitted = false
  ethnicGroupFileSubmitted = false
  disablePersonFileSubmitted = false
  dismissedFileSubmitted = false
  constructor(
    public fb: FormBuilder,
    public router: Router,
    private applicantService: ApplicantService
  ) { }

  ngOnInit(): void {
    this. FSN_application_form = this.fb.group({
      // applicationProcessingFee: ['', Validators.required],
      // bankReceptNo: ['', Validators.required],
      // date: ['', Validators.required],
      degreeName: ['FSN'],
      applicationSubmissionDate: ['', Validators.required],
      session: ['', Validators.required],
      studyCenterName: ['', Validators.required],
      nameRC: ['', Validators.required],

      applicantName: ['', [Validators.required, Validators.minLength(2)]],
      motherName: ['', Validators.required],
      fatherName: ['', Validators.required],
      phoneNumber_residence: ['', Validators.required],
      phoneNumber_personal: ['', Validators.required],
      phoneNumber_guardian: ['', Validators.required],
      email: ['', Validators.required],
      nid: ['', Validators.required],
      gender: ['', Validators.required],
      dob: ['', Validators.required],
      citizenship: ['', Validators.required],
      maritalStatus: ['', Validators.required],
      present_add: ['', Validators.required],
      presentZip: ['', Validators.required],
      presentDistrict: ['', Validators.required],
      permanent_add: ['', Validators.required],
      permanentDistrict: ['', Validators.required],
      permanentZip: ['', Validators.required],
      applicantPicture: [],
      preferrMood: ['', Validators.required],

      sscDegree: ['', Validators.required],
      sscSession: ['', Validators.required],
      sscRoll: ['', Validators.required],
      sscPassingYear: ['', Validators.required],
      sscBoardUniversity: ['', Validators.required],
      sscInstitution: ['', Validators.required],
      sscGroupDiscipline: ['', Validators.required],
      sscDivision: ['', Validators.required],
      sscCgpa: ['', Validators.required],
      sscGpa: ['', Validators.required],

      hscDegree: ['', Validators.required],
      hscSession: ['', Validators.required],
      hscRoll: ['', Validators.required],
      hscPassingYear: ['', Validators.required],
      hscBoardUniversity: ['', Validators.required],
      hscInstitution: ['', Validators.required],
      hscGroupDiscipline: ['', Validators.required],
      hscDivision: ['', Validators.required],
      hscCgpa: ['', Validators.required],
      hscGpa: ['', Validators.required],

      bachelorDegree: [{value:'', disabled: true}],
      bachelorSession: [{value:'', disabled: true}],
      bachelorRoll: [{value:'', disabled: true}],
      bachelorPassingYear: [{value:'', disabled: true}],
      bachelorBoardUniversity: [{value:'', disabled: true}],
      bachelorInstitution: [{value:'', disabled: true}],
      bachelorGroupDiscipline: [{value:'', disabled: true}],
      bachelorDivision: [{value:'', disabled: true}],
      bachelorCgpa: ['', Validators.required],
      bachelorGpa: ['', Validators.required],

      othersDegree: [{value:'', disabled: true}],
      othersSession: [{value:'', disabled: true}],
      othersRoll: [{value:'', disabled: true},],
      othersPassingYear: [{value:'', disabled: true}],
      othersBoardUniversity: [{value:'', disabled: true}],
      othersInstitution: [{value:'', disabled: true}],
      othersGroupDiscipline: [{value:'', disabled: true}],
      othersDivision: [{value:'', disabled: true}],
      otherCgpa: [''],
      otherGpa: [''],


      freedomFighter: [''],
      freedomfighterData: [''],
      smallEthnic: [''],
      smallEthnicGroup: [''],
      disablePerson: [''],
      disablePersonData: [''],
      dismissed: [''],
      dismissedData: [''],

      signPhoto: ['', Validators.required],
      signDate: ['', Validators.required]
    })
  }
  checkRC(){
    if(this.regionalCenter==null && this.regionalCenter== undefined){
        alert("Please Select the Regional Center first!")
    }
  }

  ifApplicable() {
    if(this.isDisabled==false){
      this.isDisabled  = true;
      this.FSN_application_form.get('bachelorDegree').disable();
      this.FSN_application_form.get('bachelorSession').disable();
      this.FSN_application_form.get('bachelorRoll').disable();
      this.FSN_application_form.get('bachelorPassingYear').disable();
      this.FSN_application_form.get('bachelorBoardUniversity').disable();
      this.FSN_application_form.get('bachelorInstitution').disable();
      this.FSN_application_form.get('bachelorGroupDiscipline').disable();
      this.FSN_application_form.get('bachelorDivision').disable();

    }else{
      this.isDisabled  = false;
      this.FSN_application_form.get('bachelorDegree').enable();
      this.FSN_application_form.get('bachelorSession').enable();
      this.FSN_application_form.get('bachelorRoll').enable();
      this.FSN_application_form.get('bachelorPassingYear').enable();
      this.FSN_application_form.get('bachelorBoardUniversity').enable();
      this.FSN_application_form.get('bachelorInstitution').enable();
      this.FSN_application_form.get('bachelorGroupDiscipline').enable();
      this.FSN_application_form.get('bachelorDivision').enable();
    }
    console.log(this.isDisabled)
  }
  ifApplicable2() {
    if(this.isDisabled2==false) {
      this.isDisabled2 = true;
      this.FSN_application_form.get('othersDegree').disable();
      this.FSN_application_form.get('othersSession').disable();
      this.FSN_application_form.get('othersRoll').disable();
      this.FSN_application_form.get('othersPassingYear').disable();
      this.FSN_application_form.get('othersBoardUniversity').disable();
      this.FSN_application_form.get('othersInstitution').disable();
      this.FSN_application_form.get('othersGroupDiscipline').disable();
      this.FSN_application_form.get('othersDivision').disable();
    }   
    else {
      this.isDisabled2  = false;
      this.FSN_application_form.get('othersDegree').enable();
      this.FSN_application_form.get('othersSession').enable();
      this.FSN_application_form.get('othersRoll').enable();
      this.FSN_application_form.get('othersPassingYear').enable();
      this.FSN_application_form.get('othersBoardUniversity').enable();
      this.FSN_application_form.get('othersInstitution').enable();
      this.FSN_application_form.get('othersGroupDiscipline').enable();
      this.FSN_application_form.get('othersDivision').enable();
    }
  }

  onSelectFile(event) {
    const file = event.target.files[0];
    const preview = event.target.files;
    if (preview && file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
      }
    }
    this. FSN_application_form.patchValue({
      applicantPicture: file
    });

  }
  onSelectFreedomFighterFile(event) {
    const file = event.target.files[0];
    const preview = event.target.files;
    if (preview && file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      this.freedomFighterFileSubmitted = true
    }
    this. FSN_application_form.patchValue({
      freedomfighterData: file
    });
  }
  onSelectSmallEthnicFile(event) {
    const file = event.target.files[0];
    const preview = event.target.files;
    if (preview && file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      this.ethnicGroupFileSubmitted = true
    }
    this. FSN_application_form.patchValue({
      smallEthnicGroup: file
    });


  }
  onSelectDisablePersonFile(event) {
    const file = event.target.files[0];
    const preview = event.target.files;
    if (preview && file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      this.disablePersonFileSubmitted = true
    }
    this. FSN_application_form.patchValue({
      disablePersonData: file
    });


  }
  onSelectDismissedFile(event) {
    const file = event.target.files[0];
    const preview = event.target.files;
    if (preview && file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      this.dismissedFileSubmitted = true
    }
    this. FSN_application_form.patchValue({
      dismissedData: file
    });


  }
  onSignatureSelectFile(event) {
    console.log('sign')
    const file = event.target.files[0];
    const preview = event.target.files;
    if (preview && file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url

    }
    this. FSN_application_form.patchValue({
      signPhoto: file
    });

  }

  // Image Preview
  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this. FSN_application_form.patchValue({
      signPhoto: file
    });
    this. FSN_application_form.get('signPhoto').updateValueAndValidity()
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.preview = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  createApplicant() {

    if (this.freedomFighter != 'no') {
      if (this.freedomFighterFileSubmitted == true) {

        if (this.smallEthnic != 'no') {
          if (this.ethnicGroupFileSubmitted == true) {
            console.log('Small Ethnic Submit file');
          } else {
            alert('Please Small Ethnic Submit file');
          }
        }
        if (this.disablePerson != 'no') {
          if (this.disablePersonFileSubmitted == true) {
            console.log('Disable Person Submit file');
          } else {
            alert('Please Disable Person Submit file');
          }
        }
        if (this.dismissed != 'no') {
          if (this.dismissedFileSubmitted == true) {
            console.log('Dismissed Submit file');
          } else {
            alert('Please Dismissed Submit file');
          }
        }

        this.createApplicationSubmit()
        //submit
      } else {
        alert('Please Freedom Fighter Submit file');
      }
    }
    if (this.smallEthnic != 'no') {
      if (this.ethnicGroupFileSubmitted == true) {
        if (this.disablePerson != 'no') {
          if (this.disablePersonFileSubmitted == true) {
            console.log('Disable Person Submit file');
          } else {
            alert('Please Disable Person Submit file');
          }
        }
        if (this.dismissed != 'no') {
          if (this.dismissedFileSubmitted == true) {
            console.log('Dismissed Submit file');
          } else {
            alert('Please Dismissed Submit file');
          }
        }
        this.createApplicationSubmit()
        // submit
        console.log(this. FSN_application_form.value)
      } else {
        alert('Please Small Ethnic Submit file');
      }
    }
    if (this.disablePerson != 'no') {
      if (this.disablePersonFileSubmitted == true) {
        if (this.dismissed != 'no') {
          if (this.dismissedFileSubmitted == true) {
            console.log('Dismissed Submit file');
          } else {
            alert('Please Dismissed Submit file');
          }
        }
        this.createApplicationSubmit()
        // submit
        console.log(this. FSN_application_form.value)
      } else {
        alert('Please Disable Person Submit file');
      }
    }
    if (this.dismissed != 'no') {
      if (this.dismissedFileSubmitted == true) {
        this.createApplicationSubmit()
        // submit
        console.log(this. FSN_application_form.value)
      } else {
        alert('Please Dismissed Submit file');
      }
    }

    // submit
    this.createApplicationSubmit()

  }
  createApplicationSubmit() {
    var formData: any = new FormData();
    for (var key in this. FSN_application_form.value) {
      formData.append(key, this. FSN_application_form.value[key]);
    }
    this.applicantService.createNewApplicant(formData).subscribe((response) => {
      if (response.isCreated == true) {
        this.applicantService.result = response.result
      }
      if (this.applicantService.result) {

        this.router.navigate(['payment/new'])
      }
    },
      (error) => {
        alert(error.error)
      })
  }

}
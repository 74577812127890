import { RoutineService } from './../../../services/routine.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NoticeService } from 'src/app/services/notice.service';

@Component({
  selector: 'app-routine-list',
  templateUrl: './routine-list.component.html',
  styleUrls: ['./routine-list.component.scss']
})
export class RoutineListComponent implements OnInit {
  routineList
  constructor(private router: Router, private routineService: RoutineService,public noticeService: NoticeService) { }

  ngOnInit(): void {

    this.routineService.getAllRoutine()
      .subscribe(data => {
        if (data.isFound) {
          this.routineList = data.result;
          console.log(this.routineList)
        }
      })
  }
  doEdit(data) {

    this.routineService.editRoutineData = data
    this.router.navigate(['/admin/routine/edit'])

  }
  makeDelete(id: string) {
    let data = {
      isDeleted: true
    }
    this.routineService.disableRoutine(data, id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("success")
          window.location.reload()
        }
      })

  }
  makeDisable(id: string) {
    let data = {
      isDisabled: true
    }
    this.routineService.disableRoutine(data, id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("success")
          window.location.reload()
        }
      })
  }
  makeEnable(id) {
    let data = {
      isDisabled: false
    }
    this.routineService.disableRoutine(data, id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("success")
          window.location.reload()
        }
      })
  }
  viewNotice(url) { 
    this.fileLink(url[0])
  }
  fileLink(file: string) {
    console.log(file)
    if (file.substr(-4) == '.pdf') {
      this.noticeService.pdfURL = file;
      this.router.navigate(['/pdfviewer-notice'])
    }
    else {
      window.open(
        file,
        '_blank'
      );

    }
  }

}

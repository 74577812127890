import { Component, OnInit } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { Router } from '@angular/router';
import { NoticeService } from 'src/app/services/notice.service';

@Component({
  selector: 'app-notice-creat',
  templateUrl: './notice-creat.component.html',
  styleUrls: ['./notice-creat.component.scss']
})
export class NoticeCreatComponent implements OnInit {

  creatNoticeForm: any = [];
  url: any;
  public category = ['Admission', 'Registration', 'Examination', 'Result', 'General', 'Employment'];
  public programList = ['Diploma in Computer Science and Application', 'B.Sc in Computer Science and Engineering', 'B.Sc (Hons) in Food Science and Nutrition', 'Master of Disability Management and Rehabilitation (MDMR)', 'Master of Public Health', 'Post Graduate Diploma in Medical Ultrasound (PGDMU)'];
  constructor(
    public fb: FormBuilder,
    public router: Router,
    public noticeService: NoticeService,
  ) { }

  ngOnInit(): void {
    this.creatNoticeForm = this.fb.group({
      noticeTitle: [''],
      noticeBody: [''],
      noticeFile: [''],
      noticeCategory: [''],
      noticeProgram: [''],
    });
  }

  onSelectFile(event) {
    const file = event.target.files[0];
    this.creatNoticeForm.patchValue({
      noticeFile: file
    });
  }


  createNotice() {
    console.log(this.creatNoticeForm.value)
    let form_data = new FormData();

    for (let key in this.creatNoticeForm.value) {
      form_data.append(key, this.creatNoticeForm.value[key]);
    }
    this.noticeService.createNewNotice(form_data)
      .subscribe(data => {
        if (data.isCreated) {
          alert("success")

          this.router.navigateByUrl("/admin/notice/view")
          // window.location.reload();

        }
      })
  }

}

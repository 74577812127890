import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {

  constructor(private http: HttpClient) { }
  baseURL: string = environment.API;
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  public isAuthenticated(): boolean {

    const token = localStorage.getItem('token');

    return !!token;
  }

  public makeAuthenticated(access_token: String, _id: String): boolean {

    let token = JSON.stringify(access_token);
    localStorage.removeItem('token');
    localStorage.setItem('token', token);

    let id = JSON.stringify(_id);
    localStorage.removeItem('_id');
    localStorage.setItem('_id', id);

    if (!this.isAuthenticated()) {
      return false;
    }
    return true;

  }

  public logout(): boolean {

    localStorage.clear();
    if (!this.isAuthenticated()) {
      return true;
    }
    return false;

  }
  public newLogin(login: any) {
    return this.http.post<any>(`${this.baseURL}/api/admin/login`, login)
  }
  public createAdmin(adminData: any) {
    return this.http.post<any>(`${this.baseURL}/api/admin/create`, adminData)
  }
  public getAccess(adminId) {
    return this.http.get<any>(`${this.baseURL}/api/admin/access/${adminId}`);
  }
  public createAccess(access) {
    return this.http.post<any>(`${this.baseURL}/api/admin/access/`, access);
  }
  public updateAccess(id: string, access) {
    return this.http.put<any>(`${this.baseURL}/api/admin/access/${id}`, access)
  }
  public getAllAdmin() {
    return this.http.get<any>(`${this.baseURL}/api/admin/list`);
  }


}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {
  baseURL: string = environment.API;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  contactId: string
  constructor(private http: HttpClient) { }

  createNewContact(contact: any) {
    return this.http.post<any>(`${this.baseURL}/api/contact/creat-contactus`, contact)
  }
  getAllContact() {
    return this.http.get<any>(`${this.baseURL}/api/contact/contactus`)
  }
  getContactById(id: string) {
    return this.http.get<any>(`${this.baseURL}/api/contact/contactusById/${id}`)
  }
  getStatusById(id: string) {
    return this.http.get<any>(`${this.baseURL}/api/contact/status/${id}`)
  }
  updateContactById(id: string) {
    return this.http.put<any>(`${this.baseURL}/api/admin/seen/${id}`, { headers: this.headers })
  }
  deleteContactById(id: string) {
    return this.http.delete<any>(`${this.baseURL}/api/contact/delete-message/${id}`, { headers: this.headers })
  }
} 

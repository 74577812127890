import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import *as $ from 'jquery';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void { 
  }

  goToLink(link,id){
    this.router.navigate( [ '/'+link ], { fragment: id } );
  }

}

<div class="side-container">
  <div id="mySidenav" class="sidenav">
    <ul id="ul-1">
      <li class="black-button dropdown"><a href="#"><i class="fa fa-bars"></i> Menu</a>
        <ul class="dropdown-content">
          <!-- <li><a>Results</a></li> -->
          <li><a routerLink="/about-sst">About SST</a></li>
          <li><a routerLink="/contact-us">Contact Us</a></li>
          <li><a routerLink="/faculty-members">Faculty Members</a></li>
          <li><a routerLink="/faqs">FAQ</a></li>
          <li><a routerLink="/gallery">Gallery</a></li>
          <li><a routerLink="/research-and-journal">Research & Journal</a></li>
        </ul>
      </li>
      <li class="black-button dropdown">
        <a><i class="fa fa-bell"></i> Notice</a>
        <ul class="dropdown-content">
          <li><a routerLink="/notice/admission-notice">Admission Notice</a></li>
          <li><a routerLink="/notice/registration-class-notice">Registration/Class Notice</a></li>
          <li><a routerLink="/notice/examination-notice">Examination Notice</a></li>
          <li><a routerLink="/notice/result-notice">Result Notice</a></li>
          <li><a routerLink="/notice/general-notice">General Notice</a></li>
          <li><a routerLink="/notice/employment-notice">Employment Notice</a></li>
        </ul>
      </li>
      <li class="black-button dropdown"><a href="#"><i class="fa fa-briefcase"></i> Resource</a>
        <ul class="dropdown-content">
          <li><a routerLink="/study-guides">Program Handbook</a></li>
          <li><a routerLink="/registration-form">Registration Form</a></li>
          <li><a routerLink="/study-centers">Study Centers</a></li>
          <!-- <li><a routerLink="/tutor-list">List of Tutors</a></li> -->
          <li><a routerLink="/class-routine">Class Routine</a></li>
          <li><a routerLink="/assignment">Assignment</a></li>
          <li><a routerLink="/academic-calender">Academic Calender</a></li>
          <li><a routerLink="/e-book">E-book</a></li>
          <li><a routerLink="/video-audio-content">Video/Audio Content</a></li>
        </ul>
      </li>
    </ul>
    <ul id="ul-2">
      <li class="btn-white dropdown"><a> Apply Online</a>
        <ul class="dropdown-content applyOnline-dropdownContent">
          <li><a routerLink="/dcsa-application-form">Diploma in Computer Science and Application</a></li>
          <li><a routerLink="/cse-application-form">B.Sc in Computer Science and Engineering</a></li>
          <!-- <li><a routerLink="/nursing-application-form">B.Sc in Nursing</a></li> -->
          <li><a routerLink="/FSN-application-form">B.Sc (Hons) in Food Science and Nutrition</a></li>
          <li><a routerLink="/mdmr-application-form">Master of Disability Management and Rehabilitation</a>
          </li>
          <li><a routerLink="/mph-application-form">Master of Public Health</a></li>
          <li><a routerLink="/pgdmu-application-form">Post Graduate Diploma in Medical Ultrasound</a></li>
        </ul>
      </li>
      <li class="btn-white dropdown"><a>Program</a>
        <ul class="dropdown-content program-dropdownContent">
          <li><a routerLink="/dcsa">Diploma in Computer Science and Application</a></li>
          <li><a routerLink="/BSc-CSE">B.Sc in Computer Science and Engineering</a></li>
          <!-- <li><a routerLink="/BSc-nursing">B.Sc in Nursing</a></li> -->
          <li><a routerLink="/BSc-foodScience">B.Sc (Hons) in Food Science and Nutrition</a></li>
          <li><a routerLink="/MDMR">Master of Disability Management and Rehabilitation</a></li>
          <li><a routerLink="/MPH">Master of Public Health</a></li>
          <li><a routerLink="/PGDMU">Post Graduate Diploma in Medical Ultrasound</a></li>
        </ul>
      </li>
      <li class="btn-white dropdown"><a>Feedback</a>
        <ul class="dropdown-content1 complain-dropdown-content">
          <li><a routerLink="/complaint">New Feedback</a></li>
          <li><a routerLink="/complains/status">Feedback Status</a></li>
        </ul>
      </li>
      <li class="btn-white">
        <a routerLink="/alumni-dept-list">Alumni</a>
      </li>
    </ul>

  </div>
</div>

<app-navbar></app-navbar>
<mat-card>
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">school</mat-icon>
                &nbsp;&nbsp;PGDMU
            </ng-template>
            <img style="margin: 20px;" src="../../../../../assets/program_details/image/ultrasound.svg" alt="">

            <p class="firstTabPara">
                Post Graduate Diploma in Medical Ultrasound (PGDMU)
                is designed to be accessible to rural and regional Queenslanders
                and interstate students seeking a nationally accredited sonography qualification.
            </p>
            <h2>Aims and objectives of the PGDMU Program</h2>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To create opportunity for acquiring knowledge and
                    skill in
                    Medical Ultrasound.
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To produce sufficient sonologists available for
                    taking care of
                    community health.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To train up medical doctors for effective and
                    scientific diagnosis
                    of patients and writing reports on case basis using ultrasound
                    machine.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To produce trained and skilled sonologists to
                    meet the growing
                    demand in clinical ultrasound and imaging at home and abroad.</li>
            </ul>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">import_contacts</mat-icon>
                &nbsp;&nbsp;Course Details
            </ng-template>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th>Level</th>
                        <th>Graduation</th>
                    </tr>
                    <tr>
                        <td>Semester</td>
                        <td>02</td>
                    </tr>
                    <tr>
                        <td>Semester Duration</td>
                        <td>6 months</td>
                    </tr>
                    <tr>
                        <td>Class Duration</td>
                        <td>5 months</td>
                    </tr>
                    <tr>
                        <td>Total Credit Hours</td>
                        <td>72 hours</td>
                    </tr>
                    <tr>
                        <td>Minimum Duration</td>
                        <td>1 years</td>
                    </tr>
                    <tr>
                        <td>Maximum Duration</td>
                        <td>2 years</td>
                    </tr>
                    <tr>
                        <td>Enrollment</td>
                        <td>Once in a Year(January)</td>
                    </tr>
                    <tr>
                        <td>Selection Criteria</td>
                        <td>
                            Admission Test
                        </td>
                    </tr>
                    <tr>
                        <td>Learner’s Guide and Admission Form Fee</td>
                        <td>TK. 1000</td>
                    </tr>
                    <tr>
                        <td>Each Course Fee (5 Course/Learner’s)</td>
                        <td>Tk. 6000</td>
                    </tr>
                    <tr>
                        <td>ID card</td>
                        <td>Tk. 200</td>
                    </tr>
                    <tr>
                        <td>Registration Fee</td>
                        <td>Tk. 1000</td>
                    </tr>
                    <tr>
                        <td>Examination Fee (Each course)</td>
                        <td>TK. 1000</td>
                    </tr>
                    <tr>
                        <td>Library Fee</td>
                        <td>TK. 100</td>
                    </tr>
                    <tr>
                        <td>Academic Calendar Fee</td>
                        <td>TK. 100</td>
                    </tr>
                    <tr>
                        <td>Semester Mark Sheet</td>
                        <td>TK. 100</td>
                    </tr>
                    <tr>
                        <td>Provisional Certificate Fee</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Original Certificate Fee</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Transcript</td>
                        <td>0</td>
                    </tr>
                    <tr>
                        <td>Semester Marks sheet</td>
                        <td>TK. 100</td>
                    </tr>
                    <tr>
                        <td>Final Mark Sheet</td>
                        <td>0</td>
                    </tr>

                    <tr>
                        <td>Seminar Fee</td>
                        <td>3000</td>
                    </tr>
                    <tr>
                        <td>Others</td>
                        <td>500</td>
                    </tr>

                </table>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">verified</mat-icon>
                &nbsp;&nbsp;Eligibility
            </ng-template>
            <h2>Eligible criteria for Admission into the PGDMU Program of BOU</h2>
            <p class="fourTabPara">
                The selection of learners for admission into the program will be done on a competitive basis by
                assessing result of academic performance and Viva-voce. To be eligible for admission into the
                PGDMU program, applicant’s minimum requirements to take part in the admission test are as
                follow:
            </p>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Candidates must have MBBS or equivalent degree
                    having registered from Bangladesh
                    Medical and Dental Council (BMDC).</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Basic skill in computer literacy.</li>
            </ul>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">military_tech</mat-icon>
                &nbsp;&nbsp;Evaluation
            </ng-template>
            <h2>Method of Evaluation </h2>
            <p class="fourTabPara">1. Theory Courses including Practical Demonstrations:
            </p>
            <div class="table-responsive">
                <table id="dcsa-table2">
                    <tr>
                        <td>a) Theory: MCQ short and Analytical Questions</td>
                        <td class="text-center">70 marks</td>
                    </tr>
                    <tr>
                        <td>b) Quizze/Class Test/Slide Presentation/Case Study</td>
                        <td class="text-center">15 marks</td>
                    </tr>
                    <tr>
                        <td>c) Practical Demonstrations/Report Writing</td>
                        <td class="text-center">15 marks</td>
                    </tr>
                    <tr>
                        <th>Total</th>
                        <th>100 marks</th>
                    </tr>
                </table>
            </div>
            <p class="fourTabPara" style="margin-top: 15px;">2. Comprehensive Training – I and II</p>
            <div class="table-responsive">
                <table id="dcsa-table2">
                    <tr>
                        <td>a) Practical Session</td>
                        <td class="text-center">60 marks</td>
                    </tr>
                    <tr>
                        <td>b) Viva-voce</td>
                        <td class="text-center">40 marks</td>
                    </tr>
                    <tr>
                        <th>Total</th>
                        <th>100 marks</th>
                    </tr>
                </table>
            </div>
            <h3 style="margin-top: 20px;">
                Requirement for obtaining
                degree of Post Graduate
                Diploma in Medical
                Ultrasound (PGDMU)
            </h3>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Theoretical and oral examination will be
                    evaluated separately and
                    the pass marks will be 60% for each component.</li>
            </ul>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">account_circle</mat-icon>
                &nbsp;&nbsp;Academic Co-ordinator
            </ng-template>
            <div style="margin: 20px;">
                <h1>For more information:</h1>
                <ul class="fifthTabul" style="padding-inline-start: 20px;">
                    <li>Dr. Farida Easmin Shelley</li>
                    <li>Professor (Medical Science)</li>
                    <li>School of Science and Technology</li>
                    <li>Bangladesh Open University</li>
                    <li>Board Bazar, Gazipur-1705.</li>
                    <li>E-mail: farida&#64;bou.edu.bd</li>
                    <li>Phone: 09666730730 Ex. 688, 01733452222 (cell).</li>
                </ul>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add_alert</mat-icon>
                &nbsp;&nbsp;Notice
            </ng-template>
            <div class="table-container">
                <table class="notice-table">
                    <ng-container *ngFor="let notice of noticeList; index as i">
                        <ng-container *ngIf="!notice?.isDeleted">
                            <ng-container *ngIf="!notice?.isDisabled">
                                
    
                                    <tr>
                                       
                                        <td class="small-cell">
                                            <div class="dateDiv">
                                                <div class="month">{{notice?.noticeSubmissionDate | date :'MMMM'}}</div>
                                                <div class="date">{{notice?.noticeSubmissionDate | date :'d'}}, {{notice?.noticeSubmissionDate | date :'y'}}</div>
                                                <!-- <div class="year"></div> -->
                                            </div>
                                            <div class="half-circle">
                                            </div>                                    
                                        </td> 
                                       
                                         <td class="notice-cell">
                                             <a href="{{notice?.noticeFile}}" target="blank" matTooltip="Click for download">{{notice?.noticeBody}} </a> <app-new-icon [date]="notice?.noticeSubmissionDate"></app-new-icon>
                                            
                                        </td>
                                    </tr>
                                
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card>
<app-footer></app-footer>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.scss']
})
export class AdminNavbarComponent implements OnInit {

  
  constructor(private authService:AuthService,private router:Router) { }

  ngOnInit(): void { }
  logOut(){
    if(this.authService.logout()){
this.router.navigate(['/login']);
    }else{
      alert('Logout Not Success!')
    }
  }
}


import { Component, OnInit } from '@angular/core';
import { NoticeService } from '../../../../services/notice.service';

@Component({
  selector: 'app-dcsa',
  templateUrl: './dcsa.component.html',
  styleUrls: ['./dcsa.component.scss']
})
export class DcsaComponent implements OnInit {
  noticeList
  constructor(public noticeService: NoticeService) { }

  ngOnInit(): void {
    this.noticeService.getNoticeByProgram('Diploma in Computer Science and Application')
      .subscribe(data => {
        if (data.isFound) {
          this.noticeList = data.result;
         console.log(this.noticeList)
        }
      })
  }

}

import { ComplaintService } from './../../../services/complaint.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { Router } from '@angular/router';

@Component({
  selector: 'app-reply-complain',
  templateUrl: './reply-complain.component.html',
  styleUrls: ['./reply-complain.component.scss']
})
export class ReplyComplainComponent implements OnInit {

  replyForm: any = [];
  url: any;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private complaintService: ComplaintService,
  ) { }

  ngOnInit(): void {
    this.replyForm = this.fb.group({
      replyBody: [''],
      replyFile: ['']
    });
  }

  onSelectFile(event) {
    const file = event.target.files[0];
    this.replyForm.patchValue({
      replyFile: file
    });
  }


  sendReply() {
    console.log(this.replyForm.value)
    let form_data = new FormData();

    for (let key in this.replyForm.value) {
      form_data.append(key, this.replyForm.value[key]);
    }

    this.complaintService.sendReply(form_data)
      .subscribe(reply => {
        if (reply.isSend) {
          alert("success")
          // window.location.reload()
          this.router.navigateByUrl("/complains")

        }
      })



  }

}

<app-navbar></app-navbar>
<div class="container-fluid">
  <div class="modal fade" id="showfriend" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" id="exampleModalLongTitle">{{name}}</h2>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <table cellpadding="5" class="table-striped" style="width: 100%; ">
              <tbody>
                <tr style="text-align: center;">
                  <td colspan=2>
                    <img [src]="profile_img" alt="" data-dismiss="modal" class="imghover">
                  </td>
                </tr>
                <tr>
                  <td>Name:</td>
                  <td>{{name}}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td><a href="mailto:{{email}}">{{email}}</a></td>
                </tr>
                <tr>
                  <td>Mobile No:</td>
                  <td><a href="tel:{{phoneNumber}}">{{phoneNumber}}</a></td>
                </tr>
                <tr>
                  <td>Institute:</td>
                  <td>{{institute}}</td>
                </tr>
                <tr>
                  <td>Registration No:</td>
                  <td>{{regno}}</td>
                </tr>
                <tr>
                  <td>Department:</td>
                  <td>{{dept_group}}</td>
                </tr>
                <tr>
                  <td>School:</td>
                  <td>{{faculty}}</td>
                </tr>
                <tr>
                  <td>Session:</td>
                  <td>{{session}}</td>
                </tr>
                <tr>
                  <td>Batch:</td>
                  <td>{{batch}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container" style="margin-top: 20px;">
  <div class="row">
    <div class="col-md text-center">

      <h2>Alumni member of {{alumniDept}} , {{alumniBatch}} batch.</h2>
      <div class="friendlist-container">
        <div class="media" *ngFor="let user of cseUsers; let i = index">
          <div class="kard" data-toggle="modal" data-target="#showfriend" (click)="getById(user._id)">
            <img [src]="user.profile_img" class="proimg" [alt]="user.name">
            <h2>{{user.name}}</h2>
            <div class="cont">
              <p>
                Batch: {{user.batch}}
              </p>
              <p>
                Session: {{user.session}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

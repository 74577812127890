import { Component, OnInit } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { Router } from '@angular/router';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
  selector: 'app-create-documents',
  templateUrl: './create-documents.component.html',
  styleUrls: ['./create-documents.component.scss']
})
export class CreateDocumentsComponent implements OnInit {
  createDocumentsForm: any = [];
  public category = ['AcademicBook', 'AcademicCalendar','Assignment', 'ClassRoutine','Program Handbook', 'ResearchPaper', 'RegistrationForm'];

  constructor(
    public fb: FormBuilder,
    public router: Router,
    public documentsService: DocumentsService
  ) { }

  ngOnInit(): void {
    this.createDocumentsForm = this.fb.group({
      documentFirstName: [''],
      documentLastName: [''],
      programName: [''],
      documentFile: [''],
      documentCategory: [''],
    });
  }
  onSelectFile(event) {
    const file = event.target.files[0];
    this.createDocumentsForm.patchValue({
      documentFile: file
    });
  }


  createNotice() {
    console.log(this.createDocumentsForm.value)
    let form_data = new FormData();

    for (let key in this.createDocumentsForm.value) {
      form_data.append(key, this.createDocumentsForm.value[key]);
    }
    this.documentsService.createNewDocument(form_data)
      .subscribe(data => {
        if (data.isCreated) {
          alert("success")
          this.router.navigateByUrl("admin/doc/view")
          // window.location.reload()
        }
      })
  }

}


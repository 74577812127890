import { ActivatedRoute, Router } from '@angular/router';
import { ContactUsService } from './../../../../app/services/contact-us.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-message',
  templateUrl: './view-message.component.html',
  styleUrls: ['./view-message.component.scss']
})
export class ViewMessageComponent implements OnInit {

  contactData: any=[];
  contactId: string
  constructor(private ContactUsService: ContactUsService, private router: Router, private route: ActivatedRoute) {

    this.contactId = this.route.snapshot.paramMap.get("id")

  }

  ngOnInit(): void {
    this.ContactUsService.getContactById(
      this.contactId
    )
      .subscribe(data => {
        if (data.isFound) {
          this.contactData = data.result;
          console.log(this.contactData);
          if (this.contactData.contactStatus != 'Done') {
            this.ContactUsService.updateContactById(this.contactId)
              .subscribe(data => {
                if (data.isUpdate) {
                  console.log('Update to seen')
                }
              })
          }
        }
      })

  }
}

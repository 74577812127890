import { Component, OnInit } from '@angular/core';
import { NoticeService } from './../../services/notice.service';

@Component({
  selector: 'app-examination-notice',
  templateUrl: './examination-notice.component.html',
  styleUrls: ['./examination-notice.component.scss']
})
export class ExaminationNoticeComponent implements OnInit {

  noticeList
  constructor(public noticeService: NoticeService) { }

  ngOnInit(): void {
    this.noticeService.getNotice('Examination')
      .subscribe(data => {
        if (data.isFound) {
          this.noticeList = data.result;
          console.log(this.noticeList)
        }
      })
  }

}

import { NoticeService } from './../../../services/notice.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent implements OnInit {
  noticeList
  constructor(public noticeService: NoticeService) { }

  ngOnInit(): void {
    this.noticeService.getAllNotice()
      .subscribe(data => {
        if (data.isFound) {
          this.noticeList = data.result;
          console.log(this.noticeList)
        }
      })
  }
  

}

import { ComplaintService } from './../../../services/complaint.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-complains',
  templateUrl: './complains.component.html',
  styleUrls: ['./complains.component.scss']
})
export class ComplainsComponent implements OnInit {

  constructor(private complaintService: ComplaintService, private router: Router) { }
  complaintList
  ngOnInit(): void {
    this.complaintService.getAllComplaint()
      .subscribe((res) => {
        if (res.isFound) {
          this.complaintList = res.result
        }
      })
  }
  viewComplaint(id: string) {

    this.router.navigate([`/complains/view/${id}`])

  }

}

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoutineService {
  baseURL: string = environment.API;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  editRoutineData: any;
  constructor(private http: HttpClient) { }

  createNewRoutine(routine: any) {
    return this.http.post<any>(`${this.baseURL}/api/routine/new`, routine)
  }
  editRoutine(routine: any, id: string) {
    return this.http.put<any>(`${this.baseURL}/api/routine/update/${id}`, routine)
  }
  disableRoutine(routine: any, id: string) {
    return this.http.put<any>(`${this.baseURL}/api/routine/disable/${id}`, routine)
  }
  getAllRoutine() {
    return this.http.get<any>(`${this.baseURL}/api/routine/list`, { headers: this.headers })
  }
  getRoutine(routineCategory) {
    return this.http.get<any>(`${this.baseURL}/api/routine/list/${routineCategory}`, { headers: this.headers })
  }
}

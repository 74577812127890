<app-navbar></app-navbar>
<div id="about-sst">
    <mat-card>
        <h1>Frequently Asked Questions</h1>
        <ol>
            <li>
                <a href="#counsellingHelp">How counselling can help me?</a>
            </li>
            <li>
                <a href="#chooseProgram">How can I choose a program?</a>
            </li>
            <li>
                <a href="#stycenterHelp">How can I select my study center?</a>
            </li>
            <li>
                <a href="#stycenterHelp">How can I perticipate in Admission Exam?</a>
            </li>
            <li>
                <a href="#resultHelp">How can I see my Exam Result?</a>
            </li>
        </ol>
        <div id="counsellingHelp">
        <h3>How counselling can help me?</h3>
        <ul>
            <li>
                A Counsellor can help you clarify your educational and career goals and overcome barriers to your
                learning;
            </li>
            <li>
                A Counsellor will support you in your studies, help you learn to cope with change, recognize problems
                early and identify solutions.
            </li>
            <li>
                A Counsellor may recommend other resources, provide individual assistance for specific concerns, or
                suggest appropriate referrals.
            </li>
        </ul>
        </div>
        <div id="chooseProgram">
        <h3>How can I choose a program?</h3>
        <p>A Counsellor can help you to decide a program of study that is right for you based on your interests and
            goals. Complete the career self-assessment, and then make an appointment with a counsellor</p>
        </div>
        <div id="stycenterHelp">
        <h3>How can I select my study center?</h3>
        <p>According to suggestion of the program coordinator you would be able to find a suitable study center.</p>
        </div>
        <div id="admissionHelp">
            <h3>How can I perticipate in Admission Exam?</h3>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Esse rerum exercitationem libero nulla nesciunt explicabo ex aliquam quibusdam nostrum blanditiis!</p>
        </div>
        <div id="resultHelp">
            <h3>How can I see my Exam Result?</h3>
            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Esse rerum exercitationem libero nulla nesciunt explicabo ex aliquam quibusdam nostrum blanditiis!</p>
        </div>

        <h3>If you have any question, submit here:</h3>
        <app-complaint-form></app-complaint-form>
    </mat-card>
</div>
<app-footer></app-footer>
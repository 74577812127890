<app-admin-navbar></app-admin-navbar>
<main class="pt-5 right-side-content">
    <mat-card>
        <div class="container-fluid">
            <div class="table-3 table-responsive">
                
                <h1>Faculty Member List <button (click)="addMember()" style="position: absolute; right: 34px;"  matTooltip="Add Faculty Member"><img src="../../../assets/icon/faculty_add.png"/></button></h1>
                <table class="table table-bordered table-hover table-stripped">
                    <tr>
                        <th style="width: 90px;">Image</th>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Phone No</th>
                        <th colspan="3">Action</th>
                        <!-- <th>Status</th> -->
                    </tr>
                    <tr *ngFor="let facultyMember of facultyMemberList; index as i">
                        <td class="text-center"><img [src]="facultyMember?.applicantPicture" alt="" class="tableImage"></td>
                        <td>{{facultyMember.name}}</td>
                        <td>{{facultyMember.designation}}</td>
                        <td>{{facultyMember.phoneNumber_personal}}</td>
                        <!-- <td>{{facultyMember.topicName}}</td> -->
                        <td class="text-center"><button type="button" (click)="viewMember(facultyMember._id)" matTooltip="View"><img
                                    src="../../../../assets/icon/binocular.png" alt=""></button>
                        </td>
                        <td class="text-center"> <button type="button" (click)="doEdit(facultyMember)" matTooltip="Edit"><img
                                    src="../../../../assets/icon/edit.png" alt=""></button>
                        </td> 
                        <!-- <td>
                            <button *ngIf="!facultyMember?.isDisabled" type="button"
                                (click)="makeDisable(facultyMember?._id)"><img src="../../../../assets/icon/disable.png"
                                    alt=""></button>
                            <button *ngIf="facultyMember?.isDisabled" type="button" class="btn btn-success"
                                (click)="makeEnable(facultyMember?._id)">Enable</button>
                        </td> -->
                        <td class="text-center"><button type="button" (click)="makeDelete(facultyMember?._id)" matTooltip="Delete"><img
                                    src="../../../../assets/icon/bin.png" alt=""></button></td>
                        <!--                   
                    <td> <button (click)="viewMember(complain._id)"><img src="../../../../assets/icon/binocular.png" alt=""></button> </td>
                    <td style="text-align: center; font-weight: bold;"
                        [ngClass]="{'btn-warning': complain.complaintStatus == 'Seen', 'btn-danger':complain.complaintStatus == 'Unseen', 'btn-success':complain.complaintStatus == 'Done' }">
                        {{facultyMember.complaintStatus}} </td> -->
                    </tr>
                </table>
            </div>
        </div>
    </mat-card>
</main>
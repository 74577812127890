import { Component, OnInit } from '@angular/core';
import { NoticeService } from './../../services/notice.service';

@Component({
  selector: 'app-admission-notice',
  templateUrl: './admission-notice.component.html',
  styleUrls: ['./admission-notice.component.scss']
})
export class AdmissionNoticeComponent implements OnInit {

  noticeList

  constructor(public noticeService: NoticeService) { }

  ngOnInit(): void {

    this.noticeService.getNotice('Admission')
      .subscribe(data => {
        if (data.isFound) {
          this.noticeList = data.result;
         console.log(this.noticeList)
        }
      })
      
  }


}

<app-navbar></app-navbar>

<div class="container">
    <div class="row">
        <div class="col-sm"></div>

        <div class="col-sm">
            <mat-card>
                <img class="image" mat-card-image src="../../../assets/sad.png">
                <mat-card-title class="text-center">Oh No!</mat-card-title>
                <mat-card-content class="text-center">You have canceled the payment.If you do not complete the payment
                    within the next 72 hours, your application will be rejected.</mat-card-content>
                <mat-card-actions class="text-center"><button class="btn-text-center" mat-raised-button>Pay!</button>
                </mat-card-actions>
            </mat-card>
        </div>

        <div class="col-sm"></div>
    </div>

</div>
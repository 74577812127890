<app-navbar></app-navbar>
<main class="mx-lg-5">
    <div class="container-fluid mt-5">
        <div class="table-3">
            <h1>Notice List</h1>
            <table  class="table table-bordered table-hover table-stripped">
                <tbody>
                    <tr>
                        <th>SL.</th>
                        <th>Date</th>
                        <th>Category</th>
                        <th>Title</th>
                        <th>Body</th>
                        <th>Attachment</th>
                    </tr>
                    <ng-container *ngFor="let notice of noticeList; index as i">
                        <ng-container *ngIf="!notice?.isDeleted">
                            <ng-container *ngIf="!notice?.isDisabled">
                                <tr>
                                    <td>{{notice?.noticeSubmissionDate | date}}</td>
                                    <td>{{notice?.noticeCategory}}</td>
                                    <td>{{notice?.noticeTitle}}</td>
                                    <td>{{notice?.noticeBody}}</td>
                                    <td>
                                            <a type="button" class="btn btn-primary" href="{{notice?.noticeFile}}">Attachment</a>
                                        
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</main>


<br>
<br>
<br>
<br>
<app-footer></app-footer>
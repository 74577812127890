import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NoticeService } from 'src/app/services/notice.service';
import { DocumentsService } from '../../../services/documents.service'
@Component({
  selector: 'app-e-book',
  templateUrl: './e-book.component.html',
  styleUrls: ['./e-book.component.scss']
})

export class EBookComponent implements OnInit {
  public AcademicBook;
  constructor(private documentsService: DocumentsService, public noticeService: NoticeService, private router: Router) { }

  ngOnInit(): void {
    this.documentsService.getDocument('AcademicBook')
      .subscribe((res) => {
        if (res.isFound) {
          this.AcademicBook = res.result
          console.log(this.AcademicBook)
        }
        else { 
          alert(res.error)
        }
      })

  }
  viewPDF(url) {
    this.fileLink(url[0])
  }
  downloadPDF(url) {
    window.open(
      url[0],
      '_blank'
    );
  }
  fileLink(file: string) {
    console.log(file)
    if (file.substr(-4) == '.pdf') {
      this.noticeService.pdfURL = file;
      this.router.navigate(['/pdfviewer-notice'])
    }
    else {
      window.open(
        file,
        '_blank'
      );

    }

  }

}


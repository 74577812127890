import { Component, OnInit } from '@angular/core';
import { DocumentsService } from '../../../services/documents.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-assignments',
  templateUrl: './assignments.component.html',
  styleUrls: ['./assignments.component.scss']
})
export class AssignmentsComponent implements OnInit {
  public assignments;
  constructor(private documentsService: DocumentsService, private router: Router) { }

  ngOnInit(): void {
    this.documentsService.getDocument('Assignment')
      .subscribe((res) => {
        
        if (res.isFound) {
          let assignmentList=[];
          assignmentList.push(res.result),
          console.log(assignmentList)
          this.assignments=assignmentList
        }
        else {
          alert(res.error)
        }
        
      })

  }

  downloadPDF(url) {
    window.open(
      url[0],
      '_blank'
    );
  }
  
}

import { AlumniService } from './../../services/alumni.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alumni-member-new',
  templateUrl: './alumni-member-new.component.html',
  styleUrls: ['./alumni-member-new.component.scss']
})
export class AlumniMemberNewComponent implements OnInit {

  alumnimember
  isSubmit = false;
  preview
  dept_groups = ['Diploma in Computer Science and Application Program (DCSA)', 'B.Sc in Computer Science and Engineering', 'B.Sc.in Nursing', 'B.Sc (Hons) in Food Science and Nutrition', 'Master of Disability Management and Rehabilitation (MDMR)', 'Master of Public Health (MPH)', 'Post Graduate Diploma in Medical Ultrasound (PGDMU)'];
  batch_list = ['1st'];
  constructor(public fb: FormBuilder, public router: Router, private readonly _alumniService: AlumniService) { }

  ngOnInit(): void {
    this.alumnimember = this.fb.group({
      name: [''],
      institute: ['Bangladesh Open University'],
      studyCenter: [''],
      regno: [''],
      degree: [''],
      dept_group: [''],
      session: [''],
      faculty: ['School of Science & Technology'],
      batch: [''],
      phoneNumber: [''],
      email: [''],
      profile_img: [null]
    })
  }
  createAlMember() {
    console.log(this.alumnimember.value);
    var formData: any = new FormData();
    for (let key in this.alumnimember.value) {
      formData.append(key, this.alumnimember.value[key]);
    }
    if (this.alumnimember.valid) {
      if (this.alumnimember.value.profile_img != null || this.alumnimember.value.profile_img != undefined) {
        this.isSubmit = true;
        this._alumniService.createNewMember(formData)
          .subscribe((res) => {
            if (res.isCreated) {
              console.log(res.result)
              alert("Application successfully created !")
              this.router.navigateByUrl('/alumni-dept-batch');

            }
          })
      } else {

        alert("Please upload your profile Image !")

      }
    } else {
      alert("Please fill form correctly")
    }

  }


  url: any;
  croppedImage: any;
  isImageLoaded = false;
  isNeedCropped: any;
  imageChangedEvent: any;


  onSelectFile(event) {
    this.isImageLoaded = true;
    this.imageChangedEvent = event;
    const file = event.target.files[0];
    const preview = event.target.files;
    if (preview && file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
      }
    }
    this.alumnimember.patchValue({
      profile_img: file
    });


  }

  // Image Preview
  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.alumnimember.patchValue({
      profile_img: file
    });
    this.alumnimember.get('profile_img').updateValueAndValidity()
    // File Preview
    const reader = new FileReader();
    reader.onload = () => {
      this.preview = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  cropImageNeed() {
    this.isNeedCropped = true;
  }
  base64ToFile(data, filename) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  // imageCropped(event: ImageCroppedEvent) {
  //   // let image = new Image();
  //   this.croppedImage = event.base64;


  //   let file = this.base64ToFile(
  //     event.base64,
  //     this.imageChangedEvent.target.files[0].name,
  //   )


  //   this.alumnimember.patchValue({
  //     profile_img: file
  //   });
  // }
  imageLoaded(image: HTMLImageElement) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  cropImageDone() {
    this.isNeedCropped = false;
    this.url = this.croppedImage;
  }


}

import { Router } from '@angular/router';
import { NoticeService } from 'src/app/services/notice.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent implements OnInit {
  pdfSource = "";
  page: number = 1;
  totalPages: number;
  isLoaded: boolean = false;

  constructor(public noticeService: NoticeService, private router: Router) {
    
    if (this.noticeService.pdfURL != '') {
      this.pdfSource = this.noticeService.pdfURL
    } else {
      console.log('no pdf found')
      this.router.navigate(['/home'])
    }
  
  }

  ngOnInit(): void {
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  nextPage() {
    this.page++;
  }

  prevPage() {
    this.page--;
  }
  download(): void {
    window.open(
      this.pdfSource,
      '_blank'
    );
    // window.location.href = this.pdfSource;
  }

}

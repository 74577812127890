import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NoticeService } from './../../services/notice.service';

@Component({
  selector: 'app-registration-class-notice',
  templateUrl: './registration-class-notice.component.html',
  styleUrls: ['./registration-class-notice.component.scss']
})
export class RegistrationClassNoticeComponent implements OnInit {

  noticeList
  constructor(public noticeService: NoticeService, private router: Router) { }

  ngOnInit(): void {
    this.noticeService.getNotice('Registration')
      .subscribe(data => {
        if (data.isFound) {
          this.noticeList = data.result;
          console.log(this.noticeList)
        }
      })
  }
  viewNotice(url) { 
    this.fileLink(url)
  }
  fileLink(file: string) {
    console.log(file)
    if (file.substr(-4) == '.pdf') {
      this.noticeService.pdfURL = file;
      this.router.navigate(['/pdfviewer-notice'])
    }
    else {
      window.open(
        file,
        '_blank'
      );

    }

  }

}


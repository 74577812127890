<app-admin-navbar></app-admin-navbar>
<main class="right-side-content pb-3">
    <mat-card>
        <div class="table-3 table-responsive">
            <h1>Documents List <button routerLink="/admin/doc/create" style="position: absolute; right: 34px;"  matTooltip="Upload Document"><img src="../../../assets/icon/faculty_add.png"/></button></h1>
            <table class="table table-bordered table-hover table-stripped">

                    <tr>
                        <th>No</th>
                        <th>Date</th>
                        <th>Category</th> 
                        <th>Title</th>
                        <th>Program</th>
                        <th colspan="4">Action</th>
                    </tr>

                    <ng-container *ngFor="let doc of documentList; index as i">
                        <tr *ngIf="!doc?.isDeleted">
                            <td class="text-center">{{i+1}}</td>
                            <td class="text-center">{{doc?.documentSubmissionDate | date}}</td>
                            <td class="text-center">{{doc?.documentCategory}}</td>
                            <td class="text-center">{{doc?.documentFirstName}}</td>
                            <!-- <td class="text-center">{{doc?.documentLastName}}</td> -->
                            <td class="text-center">{{doc?.programName}}</td>
                            <td class="text-center"><button type="button" class="btn btn-success"
                                (click)="viewNotice(doc?.documentFile)" matTooltip="View"><img src="../../../../assets/icon/binocular.png" alt=""></button>
                            </td>
                            <td class="text-center"> <button type="button" class="btn btn-primary" (click)="doEdit(doc)" matTooltip="Edit"><img src="../../../../assets/icon/edit.png" alt=""></button>
                            </td>
                            <td class="text-center">
        
                                <button *ngIf="!doc?.isDisabled" type="button" class="btn btn-secondary"
                                    (click)="makeDisable(doc?._id)" matTooltip="Disable"><img src="../../../../assets/icon/disable.png" alt=""></button>
                                <button *ngIf="doc?.isDisabled" type="button" class="btn btn-success"
                                    (click)="makeEnable(doc?._id)" matTooltip="Enable"><img src="../../../../assets/icon/enable.png" alt=""></button>
                            </td>
                            <td class="text-center"><button type="button" class="btn btn-danger"
                                    (click)="makeDelete(doc?._id)" matTooltip="Delete"><img src="../../../../assets/icon/bin.png" alt=""></button></td>
                        </tr>
                    </ng-container>
            </table>
        </div>
    </mat-card>
</main>
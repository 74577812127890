import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { RoutineService } from 'src/app/services/routine.service';

@Component({
  selector: 'app-routine-view',
  templateUrl: './routine-view.component.html',
  styleUrls: ['./routine-view.component.scss']
})
export class RoutineViewComponent implements OnInit {

  constructor(private router: Router, private routineService: RoutineService) { }

  ngOnInit(): void {
  }

}

import { Router } from '@angular/router';
import { ApplicantService } from './../../services/applicant.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit {

  constructor(
    private applicantService: ApplicantService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.applicantService.result) {
      console.log(this.applicantService.result)
    }
    else {
      this.router.navigate(['application-form'])
    }
  }
  makePayment() {
    /*
    let user = {
      name: this.applicantService.result.applicantName,
      email: this.applicantService.result.email,
      address: this.applicantService.result.present_add,
      city: this.applicantService.result.presentDistrict,
      country: this.applicantService.result.citizenship,
      phone: this.applicantService.result.phoneNumber_personal,
      amount: 600
    }
    */
    let user = {
      "amount": 600,
      "name": "Sultan UL Arefin",
      "email": "programmer.arefin@gmail.com",
      "address": "Dhaka",
      "city": "Dhaka",
      "country": "Bangladesh",
      "phone": "+8801780324264"

    }
    this.applicantService.createNewPayment(user).subscribe((res: any) => {
      console.log(res)
      if (res.status == "SUCCESS") {
        window.location.replace(res.gatewayPageURL);
      }
    })
  }

}

<app-admin-navbar></app-admin-navbar>
<main class="right-side-content pb-3">
  <mat-card>
    <div class="table-3 table-responsive">
      <h1>Images List <button routerLink="/admin/gallery-image" style="position: absolute; right: 34px;"
          matTooltip="Upload Document"><img src="../../../assets/icon/image_add.png" /></button></h1>
      <table class="table table-bordered table-hover table-stripped">

        <tr>
          <th>No</th>
          <th>Date</th>
          <th>Image</th>
        </tr>

        <ng-container *ngFor="let img of imageList; index as i">
          <tr>
            <td class="text-center">{{i+1}}</td>
            <td class="text-center">{{img?.submissionDate | date}}</td>
            <td class="text-center"><img [src]="img?.image" alt="" class="tableImage"></td>


        </ng-container>
      </table>
    </div>
  </mat-card>
</main>

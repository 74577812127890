import { AlumniDeptListComponent } from './components/alumni-dept-list/alumni-dept-list.component';
import { AlumniDeptBatchListComponent } from './components/alumni-dept-batch-list/alumni-dept-batch-list.component';
import { AlumniMemberNewComponent } from './components/alumni-member-new/alumni-member-new.component';
import { AlumniMemberListComponent } from './components/alumni-member-list/alumni-member-list.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { GalleryImageComponent } from './bousst-admin/gallery-image/gallery-image.component';
import { AdminAccessComponent } from './bousst-admin/admin-access/admin-access.component';
import { CreateAdminComponent } from './bousst-admin/create-admin/create-admin.component';
import { AdminListComponent } from './bousst-admin/admin-list/admin-list.component';
import { AccessGuardService as AccessGuard } from './services/access-guard.service';
import { AdminLoginComponent } from './bousst-admin/admin-login/admin-login.component';
import { RoutineListComponent } from './bousst-admin/routine/routine-list/routine-list.component';
import { MessagesFromTeachersComponent } from './components/messages-from-teachers/messages-from-teachers.component';
import { GetStatusComponent } from './components/get-status/get-status.component';
import { ComplaintComponent } from './components/complaint/complaint.component';
import { MakePaymentComponent } from './components/make-payment/make-payment.component';
import { PaymentFailedComponent } from './components/payment-failed/payment-failed.component';
import { PaymentCanceledComponent } from './components/payment-canceled/payment-canceled.component';
import { SuccessComponent } from './components/success/success.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HomeComponent } from './components/home/home.component';
import { ApplyformComponent } from './components/applyform/applyform.component';
import { MdmrRegFormComponent } from './components/mdmr-reg-form/mdmr-reg-form.component';
import { FsnRegFormComponent } from './components/fsn-reg-form/fsn-reg-form.component';

import { NoticeComponent } from './bousst-admin/notice/notice/notice.component';
import { NoticeCreatComponent } from './bousst-admin/notice/notice-creat/notice-creat.component';
import { EditNoticeComponent } from './bousst-admin/notice/edit-notice/edit-notice.component';
import { NoticeEditComponent } from './bousst-admin/notice/notice-edit/notice-edit.component';

import { ComplainsComponent } from './bousst-admin/complain/complains/complains.component';
import { ViewComplainComponent } from './bousst-admin/complain/view-complain/view-complain.component';
import { ReplyComplainComponent } from './bousst-admin/complain/reply-complain/reply-complain.component';
import { ComplainStatusComponent } from './bousst-admin/complain/complain-status/complain-status.component';

import { DashbordComponent } from './bousst-admin/dashbord/dashbord.component';

import { ViewNoticeComponent } from './components/view-notice/view-notice.component';
import { AdmissionNoticeComponent } from './components/admission-notice/admission-notice.component';
import { RegistrationClassNoticeComponent } from './components/registration-class-notice/registration-class-notice.component';
import { ExaminationNoticeComponent } from './components/examination-notice/examination-notice.component';
import { ResultNoticeComponent } from './components/result-notice/result-notice.component';
import { GeneralNoticeComponent } from './components/general-notice/general-notice.component';
import { EmploymentNoticeComponent } from './components/employment-notice/employment-notice.component';

import { AdmitCardComponent } from './components/admit-card/admit-card.component';
import { AboutSstComponent } from './components/about-sst/about-sst.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { FacultyMembersComponent } from './components/faculty-members/faculty-members.component';

import { DcsaComponent } from './components/program/diploma/dcsa/dcsa.component';
import { BscCseComponent } from './components/program/under-graduate/bsc-cse/bsc-cse.component';
import { BscNursingComponent } from './components/program/under-graduate/bsc-nursing/bsc-nursing.component';
import { BscFoodScienceNutritionComponent } from './components/program/under-graduate/bsc-food-science-nutrition/bsc-food-science-nutrition.component';
import { MdmrComponent } from './components/program/graduate/mdmr/mdmr.component';
import { MphComponent } from './components/program/graduate/mph/mph.component';
import { PgdmuComponent } from './components/program/post-graduate/pgdmu/pgdmu.component';

import { DcsaRegFormComponent } from './components/admisstion-forms/dcsa-reg-form/dcsa-reg-form.component';
import { BscCseRegFormComponent } from './components/admisstion-forms/bsc-cse-reg-form/bsc-cse-reg-form.component';
import { BscNursingRegFormComponent } from './components/admisstion-forms/bsc-nursing-reg-form/bsc-nursing-reg-form.component';
import { MphRegFormComponent } from './components/admisstion-forms/mph-reg-form/mph-reg-form.component';
import { PgdmuRegFormComponent } from './components/admisstion-forms/pgdmu-reg-form/pgdmu-reg-form.component';

import { StudyGuidesComponent } from './components/resources/study-guides/study-guides.component';
import { RegistrationFormComponent } from './components/resources/registration-form/registration-form.component';
import { StudyCentersComponent } from './components/resources/study-centers/study-centers.component';
import { ListOfTutorsComponent } from './components/resources/list-of-tutors/list-of-tutors.component';
import { ClassRoutineComponent } from './components/resources/class-routine/class-routine.component';
import { AcademicCalenderComponent } from './components/resources/academic-calender/academic-calender.component';
import { AssignmentsComponent } from './components/resources/assignments/assignments.component';
import { EBookComponent } from './components/resources/e-book/e-book.component';
import { VideoAudioContentComponent } from './components/resources/video-audio-content/video-audio-content.component';
import { ViewPdfComponent } from './components/view-pdf/view-pdf.component';

import { CreateDocumentsComponent } from './bousst-admin/documents/create-documents/create-documents.component';
import { DocumentsListComponent } from './bousst-admin/documents/documents-list/documents-list.component';
import { DocumentsEditComponent } from './bousst-admin/documents/documents-edit/documents-edit.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';


import { ResearchAndJournalComponent } from './components/research-and-journal/research-and-journal.component';
import { ViewPdfNoticeComponent } from './components/view-pdf-notice/view-pdf-notice.component';
import { RoutineCreateComponent } from './bousst-admin/routine/routine-create/routine-create.component';
import { RoutineEditComponent } from './bousst-admin/routine/routine-edit/routine-edit.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';

import { CseVideoContentComponent } from './components/resources/video-audio-content/cse-video-content/cse-video-content.component';
import { DcsaVideoContentComponent } from './components/resources/video-audio-content/dcsa-video-content/dcsa-video-content.component';
import { MdmrVideoContentComponent } from './components/resources/video-audio-content/mdmr-video-content/mdmr-video-content.component';
import { BsnVideoContentComponent } from './components/resources/video-audio-content/bsn-video-content/bsn-video-content.component';
import { MphVideoContentComponent } from './components/resources/video-audio-content/mph-video-content/mph-video-content.component';
import { FsnVideoContentComponent } from './components/resources/video-audio-content/fsn-video-content/fsn-video-content.component';
import { PgdmuVideoContentComponent } from './components/resources/video-audio-content/pgdmu-video-content/pgdmu-video-content.component';

import { FacultyMembersDetailsComponent } from './components/faculty-members-details/faculty-members-details.component';
import { NewsComponent } from './components/news/news.component';
import { ContactUsMessageComponent } from './bousst-admin/contact-us-message/contact-us-message.component';
import { ViewMessageComponent } from './bousst-admin/contact-us-message/view-message/view-message.component';

import { AllfacultyMembersComponent } from './bousst-admin/allfaculty-members/allfaculty-members.component';
import { AddMemberComponent } from './bousst-admin/allfaculty-members/add-member/add-member.component';
import { ViewFacutyMemberComponent } from './bousst-admin/allfaculty-members/view-facuty-member/view-facuty-member.component';
import { UpdateMemberInfoComponent } from './bousst-admin/allfaculty-members/update-member-info/update-member-info.component';
import { OfficialsComponent } from './components/officials/officials.component';
import { UploadGalleryImageComponent } from './bousst-admin/upload-gallery-image/upload-gallery-image.component';
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'complaint', component: ComplaintComponent },
  { path: 'pdfviewer', component: ViewPdfComponent },
  { path: 'pdfviewer-notice', component: ViewPdfNoticeComponent },
  { path: 'mdmr-application-form', component: MdmrRegFormComponent },
  { path: 'FSN-application-form', component: FsnRegFormComponent },
  { path: 'dcsa-application-form', component: DcsaRegFormComponent },
  { path: 'cse-application-form', component: BscCseRegFormComponent },
  { path: 'nursing-application-form', component: BscNursingRegFormComponent },
  { path: 'mph-application-form', component: MphRegFormComponent },
  { path: 'pgdmu-application-form', component: PgdmuRegFormComponent },

  { path: 'payment/success', component: SuccessComponent },
  { path: 'payment/cancel', component: PaymentCanceledComponent },
  { path: 'payment/fail', component: PaymentFailedComponent },
  { path: 'payment/new', component: MakePaymentComponent },

  { path: 'notice/view', component: ViewNoticeComponent },
  { path: 'notice/admission-notice', component: AdmissionNoticeComponent },
  { path: 'notice/registration-class-notice', component: RegistrationClassNoticeComponent },
  { path: 'notice/examination-notice', component: ExaminationNoticeComponent },
  { path: 'notice/result-notice', component: ResultNoticeComponent },
  { path: 'notice/general-notice', component: GeneralNoticeComponent },
  { path: 'notice/employment-notice', component: EmploymentNoticeComponent },


  { path: 'dashboard', component: DashbordComponent, canActivate: [AuthGuard] },
  { path: 'login', component: AdminLoginComponent },

  { path: 'notice', component: NoticeComponent },
  { path: 'admin/notice/create', data: { routeName: 'admin/notice/create' }, component: NoticeCreatComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/notice/view', data: { routeName: 'admin/notice/view' }, component: EditNoticeComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/notice/edit', data: { routeName: 'admin/notice/edit' }, component: NoticeEditComponent, canActivate: [AuthGuard, AccessGuard] },

  { path: 'admin/doc/create', data: { routeName: 'admin/doc/create' }, component: CreateDocumentsComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/doc/view', data: { routeName: 'admin/doc/view' }, component: DocumentsListComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/doc/edit', data: { routeName: 'admin/doc/edit' }, component: DocumentsEditComponent, canActivate: [AuthGuard, AccessGuard] },

  { path: 'admin/routine/create', data: { routeName: 'admin/routine/create' }, component: RoutineCreateComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/routine/view', data: { routeName: 'admin/routine/view' }, component: RoutineListComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/routine/edit', data: { routeName: 'admin/routine/edit' }, component: RoutineEditComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/list', data: { routeName: 'admin/list' }, component: AdminListComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/create', data: { routeName: 'admin/create' }, component: CreateAdminComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/access/:adminId', data: { routeName: 'admin/access/:adminId' }, component: AdminAccessComponent, canActivate: [AuthGuard, AccessGuard] },

  { path: 'admin/contact-us-message', data: { routeName: 'admin/contact-us-message' }, component: ContactUsMessageComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/view-message/:id', data: { routeName: 'admin/view-message/:id' }, component: ViewMessageComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/faculty-members', data: { routeName: 'admin/faculty-members' }, component: AllfacultyMembersComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/add-member', data: { routeName: 'admin/add-member' }, component: AddMemberComponent, canActivate: [AuthGuard, AccessGuard] },

  { path: 'admin/view-message/:id', data: { routeName: 'admin/view-message/:id' }, component: ViewMessageComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/view-facuty-member/:id', data: { routeName: 'admin/view-facuty-member/:id' }, component: ViewFacutyMemberComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'admin/update-member-info', data: { routeName: 'admin/update-member-info' }, component: UpdateMemberInfoComponent, canActivate: [AuthGuard, AccessGuard] },


  { path: 'alumni-dept-list', component: AlumniDeptListComponent },
  { path: 'alumni-dept-batch-list/:dept', component: AlumniDeptBatchListComponent },
  { path: 'alumni-member-list/:dept/:batch', component: AlumniMemberListComponent },
  { path: 'alumni-member-application', component: AlumniMemberNewComponent },

  { path: 'complains', component: ComplainsComponent },
  { path: 'complains/view/:id', component: ViewComplainComponent },
  { path: 'complains/reply', data: { routeName: 'complains/reply' }, component: ReplyComplainComponent, canActivate: [AuthGuard, AccessGuard] },
  { path: 'complains/status', component: GetStatusComponent },
  { path: 'messages', component: MessagesFromTeachersComponent },
  { path: 'admit-card', component: AdmitCardComponent },

  { path: 'admin/gallery-image', component: UploadGalleryImageComponent },
  { path: 'admin/gallery-image/list', component: GalleryImageComponent },

  { path: 'about-sst', component: AboutSstComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'faculty-members', component: FacultyMembersComponent },
  { path: 'officials', component: OfficialsComponent },
  { path: 'gallery', component: GalleryComponent },

  { path: 'dcsa', component: DcsaComponent },
  { path: 'BSc-CSE', component: BscCseComponent },
  { path: 'BSc-nursing', component: BscNursingComponent },
  { path: 'BSc-foodScience', component: BscFoodScienceNutritionComponent },
  { path: 'MDMR', component: MdmrComponent },
  { path: 'MPH', component: MphComponent },
  { path: 'PGDMU', component: PgdmuComponent },

  { path: 'study-guides', component: StudyGuidesComponent },
  { path: 'registration-form', component: RegistrationFormComponent },
  { path: 'study-centers', component: StudyCentersComponent },
  // { path: 'tutor-list', component: ListOfTutorsComponent },
  { path: 'class-routine', component: ClassRoutineComponent },
  { path: 'academic-calender', component: AcademicCalenderComponent },
  { path: 'assignment', component: AssignmentsComponent },
  { path: 'e-book', component: EBookComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'video-audio-content', component: VideoAudioContentComponent },
  { path: 'research-and-journal', component: ResearchAndJournalComponent },

  { path: 'cse-video-content', component: CseVideoContentComponent },
  { path: 'dcsa-video-content', component: DcsaVideoContentComponent },
  { path: 'bsn-video-content', component: BsnVideoContentComponent },
  { path: 'fsn-video-content', component: FsnVideoContentComponent },
  { path: 'mdmr-video-content', component: MdmrVideoContentComponent },
  { path: 'mph-video-content', component: MphVideoContentComponent },
  { path: 'pgdmu-video-content', component: PgdmuVideoContentComponent },

  { path: 'news', component: NewsComponent },

  { path: 'facultyMembers/details', component: FacultyMembersDetailsComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

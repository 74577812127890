<app-admin-navbar></app-admin-navbar>
<main class="pt-5 mx-lg-5">
  <div class="container-fluid mt-5">
    <mat-card>
      <h3>Upload New Image</h3>
      <div class="form-1">
        <form [formGroup]="uploadImageForm">

          <mat-form-field appearance="fill">
            <mat-label>Caption</mat-label>
            <input matInput formControlName="caption" placeholder="Caption">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>File</mat-label>
            <ngx-mat-file-input type='file' (change)="onSelectFile($event)" placeholder="Include necessary file">
            </ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
          </mat-form-field>
        </form>
        <button [disabled]="!this.uploadImageForm.valid" mat-raised-button (click)="uploadImage()">Upload</button>
      </div>
    </mat-card>
  </div>
</main>

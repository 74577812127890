import { Component, OnInit } from '@angular/core';
import { NoticeService } from '../../../../services/notice.service';

@Component({
  selector: 'app-pgdmu',
  templateUrl: './pgdmu.component.html',
  styleUrls: ['./pgdmu.component.scss']
})
export class PgdmuComponent implements OnInit {

  noticeList
  constructor(public noticeService: NoticeService) { }

  ngOnInit(): void {
    this.noticeService.getNoticeByProgram('Post Graduate Diploma in Medical Ultrasound (PGDMU)')
      .subscribe(data => {
        if (data.isFound) {
          this.noticeList = data.result;
         console.log(this.noticeList)
        }
      })
  }

}

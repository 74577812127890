<app-navbar></app-navbar>
<div class="wrapper">
    <h1>Study Center List</h1>
  </div>
<mat-card>
    <p class="programName">Program Name: Diploma in Computer Science and Application (DCSA)</p>
    <div class="table-responsive">
        <table class="registration-form-table">
            <tr>
                <th width="70">SL.</th>
                <th>Study Center</th>
                <th>SC Code</th>
                <th>Regional Center</th>
            </tr>
            <tr>
                <td class="text-center">1</td>
                <td>Institute of Science and Technology (IST)</td>
                <td class="text-center">812</td>
                <td class="text-center">Dhaka</td>
            </tr>
            <tr>
                <td class="text-center">2</td>
                <td>Institute of Science and InformationTechnology (ISIT)</td>
                <td class="text-center">818</td>
                <td class="text-center">Dhaka</td>
            </tr>
            <tr>
                <td class="text-center">3</td>
                <td>Internation Islamic University, Chittagong (IIUC)</td>
                <td class="text-center">890</td>
                <td class="text-center">Chittagong</td>
            </tr>
            <tr>
                <td class="text-center">4</td>
                <td>Chittagong University of Engineering and Technology, Chittagong (CUET)</td>
                <td class="text-center">891</td>
                <td class="text-center">Chittagong</td>
            </tr>
            <tr>
                <td class="text-center">5</td>
                <td>Daffodil Institute of InformationTechnology, Chattogram</td>
                <td class="text-center">892</td>
                <td class="text-center">Chittagong</td>
            </tr>
            <tr>
                <td class="text-center">6</td>
                <td>Muslim Aid Institute of Technology (MAIT), Jessore</td>
                <td class="text-center">871</td>
                <td class="text-center">Jessore</td>
            </tr>
            <tr>
                <td class="text-center">7</td>
                <td>Infra Polytechnic Institute, Kashipur, Barisal</td>
                <td class="text-center">501</td>
                <td class="text-center">Barisal</td>
            </tr>
            <tr>
                <td class="text-center">8</td>
                <td>Bogura Polytechnic Institute, Bogura</td>
                <td class="text-center">861</td>
                <td class="text-center">Bogura</td>
            </tr>
            <tr>
                <td class="text-center">9</td>
                <td>College of Business Science and Technology (CBST), Mymensingh </td>
                <td class="text-center">160</td>
                <td class="text-center">Mymensingh</td>
            </tr>
            <tr>
                <td class="text-center">10</td>
                <td>Sythet Engineering College, Sylhet</td>
                <td class="text-center">591</td>
                <td class="text-center">Sylhet</td>
            </tr>
        </table>
    </div>
</mat-card>
<mat-card>
    <p class="programName">Program Name: B.Sc in Computer Science and Engineering (CSE)</p>
    <div class="table-responsive">
        <table class="registration-form-table">
            <tr>
                <th>SL. No.</th>
                <th>Study Center</th>
                <th>SC Code</th>
                <th>Regional Center</th>
            </tr>
            <tr>
                <td class="text-center">1</td>
                <td>Dhaka Regional Center (DRC)</td>
                <td class="text-center">801</td>
                <td class="text-center">Dhaka</td>
            </tr>
            <tr>
                <td class="text-center">2</td>
                <td>Dhaka University of Engineering and Technology (DUET)</td>
                <td class="text-center">020</td>
                <td class="text-center">Dhaka</td>
            </tr>
        </table>
    </div>
</mat-card>

<mat-card>
    <p class="programName">Program Name: Master of Public Health (MPH)</p>
    <div class="table-responsive">
        <table class="registration-form-table">
            <tr>
                <th>SL. No.</th>
                <th>Study Center</th>
                <th>SC Code</th>
                <th>Regional Center</th>
            </tr>
            <tr>
                <td class="text-center">1</td>
                <td>Uttara Study Center, BOU (USC)</td>
                <td class="text-center">111</td>
                <td class="text-center">Dhaka</td>
            </tr>
            <tr>
                <td class="text-center">2</td>
                <td>Institution of Child and Mother Health, Matuail (iCMH)</td>
                <td class="text-center">810</td>
                <td class="text-center">Dhaka</td>
            </tr>
            <tr>
                <td class="text-center">3</td>
                <td>National Heart Foundation and Research Institution, Mirpur, Dhaka (NHF&RI)</td>
                <td class="text-center">811</td>
                <td class="text-center">Dhaka</td>
            </tr>
            <tr>
                <td class="text-center">4</td>
                <td>Center for Medical Ultrasound, Green Road, Dhaka (CMUD)</td>
                <td class="text-center">812</td>
                <td class="text-center">Dhaka</td>
            </tr>
            <tr>
                <td class="text-center">5</td>
                <td>International Institute of Health Sciences (IIHS)</td>
                <td class="text-center">813</td>
                <td class="text-center">Chittagong</td>
            </tr>
            <tr>
                <td class="text-center">6</td>
                <td>Chattogram Medical College and Hospital, Chattogram (CMCH)</td>
                <td class="text-center">890</td>
                <td class="text-center">Chittagong</td>
            </tr>
            <tr>
                <td class="text-center">7</td>
                <td>M. A. G Osmani Medical College, Sylhet (SMC)</td>
                <td class="text-center">590</td>
                <td class="text-center">Sylhet</td>
            </tr>
            <tr>
                <td class="text-center">8</td>
                <td>Mymensing Medical College, Mymensing (MMC)</td>
                <td class="text-center">160</td>
                <td class="text-center">Mymensing</td>
            </tr>
            <tr>
                <td class="text-center">9</td>
                <td>Rajshahi Medical College, Rajshahi (RMC)</td>
                <td class="text-center">New</td>
                <td class="text-center">Rajshahi</td>
            </tr>
            <tr>
                <td class="text-center">10</td>
                <td>TMSS Medical Institute of Research & Technology, Bogura (TMIRT)</td>
                <td class="text-center">860</td>
                <td class="text-center">Bogura</td>
            </tr>
            <tr>
                <td class="text-center">11</td>
                <td>Rangpur Community Medical College, Rangpur (RCMC)</td>
                <td class="text-center">300</td>
                <td class="text-center">Rangpur</td>
            </tr>

        </table>
    </div>
</mat-card>

<mat-card>
    <p class="programName">Program Name: B.Sc. (Hons) in Food Science and Nutrition Program</p>
    <div class="table-responsive">
        <table class="registration-form-table">
            <tr>
                <th>SL. No.</th>
                <th>Name of the Study Center</th>
                <th>SC Code</th>
                <th>Regional Center</th>
            </tr>
            <tr>
                <td class="text-center">1</td>
                <td>Uttara Study Center, BOU (USC)</td>
                <td class="text-center">111</td>
                <td class="text-center">Dhaka</td>
            </tr>
            <tr>
                <td class="text-center">2</td>
                <td>International Institute of Health Sciences (IIHS)</td>
                <td class="text-center">813</td>
                <td class="text-center">Dhaka</td>
            </tr>
            <tr>
                <td class="text-center">3</td>
                <td>Daffodil International University (DIU)</td>
                <td class="text-center">999</td>
                <td class="text-center">Dhaka</td>
            </tr>
        </table>
    </div>
</mat-card>
<mat-card>
    <p class="programName">Program Name: Post Graduate Diploma in Medical Ultrasound</p>
    <div class="table-responsive">
        <table class="registration-form-table">
            <tr>
                <th>SL. No.</th>
                <th>Name of the Study Center</th>
                <th>SC Code</th>
                <th>Regional Center</th>
            </tr>
            <tr>
                <td class="text-center">1</td>
                <td>Center for Medical Ultrasound, Green Road, Dhaka (CMUD)</td>
                <td class="text-center">812</td>
                <td class="text-center">Dhaka</td>
            </tr>
        </table>
    </div>
</mat-card>
<mat-card>
    <p class="programName">Program Name: Master of Disability Management and Rehabilitation Program</p>
    <div class="table-responsive">
        <table class="registration-form-table">
            <tr>
                <th>SL. No.</th>
                <th>Name of the Study Center</th>
                <th>SC Code</th>
                <th>Regional Center</th>
            </tr>
            <tr>
                <td class="text-center">1</td>
                <td>Uttara Study Center, BOU (USC)</td>
                <td class="text-center">111</td>
                <td class="text-center">Dhaka</td>
            </tr>
        </table>
    </div>

</mat-card>
<br>
<br>
<br>
<br>
<app-footer></app-footer>
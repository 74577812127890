<app-navbar></app-navbar>

<div class="container" style="margin-top: 20px;">
    <div class="card">
        <div class="card-header text-center">
            Application Form for B.Sc (Hons) in Food Science and Nutrition Program 
        </div>
        <div class="card-body">
            <h2 class="card-title">Read the Following Instruction Carefully Before Completing the Form</h2>
            <p class="card-text"><strong>1. Write in capital letters for filling-up the form.</strong></p>
            <p class="card-text">
                <strong>2. The application proccessing fee Tk. 600 (six hundred) will be paid which is
                    non-refundable.</strong>
            </p>
            <p class="card-text">
                <strong>3. The application should contain the following documents:</strong>
            </p>
            <ol type="i">
                <li>Complete filled up application form;</li>
                <li>Attested copies of academic certificate and marks sheets/ transcript of examinations;</li>
                <li>Testimonial form the last educational institution;</li>
                <li>Bank receipt of application fee.</li>
            </ol>
            <p class="card-text">
                <strong>4. Without above documents application will be rejected.</strong>
            </p>
        </div>
    </div>

</div>

<!-- <app-mdmr-reg-form></app-mdmr-reg-form> -->


<!-- <app-footer></app-footer> -->
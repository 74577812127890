<app-navbar></app-navbar>
<mat-card>
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">school</mat-icon>
                &nbsp;&nbsp;MPH
            </ng-template>
            <img style="margin: 20px;" src="../../../../../assets/program_details/image/public_helth.svg" alt="">

            <p class="firstTabPara"> 
                It is expected that the MPH program will prepare the graduates to be specialized fields as well as
                increased expertise in the areas of practice, promoting community awareness about health issues,
                prevention of communicable diseases, research, management and leadership to meet the needs of the health
                care demand of the people and other issues that affect health and safety. The graduates will develop
                deeper understanding and insight knowledge with critical and analytical skills. This will allow them to
                judge rationally as responsible professionals to achieve the national health goals. The graduates will
                be motivated to develop themselves through lifelong learning. The Master of Public Health degree program
                is two-years long. The coursework includes weekly face-to-face tutorial session and online lecturing,
                research project, and comprehensive examination, all cumulate 60 credits.
            </p>
            <h2>Aims and Objectives of the MPH program</h2>
            <p class="firstTabPara">
                The aim of the MPH program is to produce competent skilled manpower in the field of public health,
                community allied health specialists, administrators, leaders and researches who will be able to attain
                the national health goals through their effective contribution in health profession. The overall aims
                and objectives of this program are to-
            </p>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> update knowledge and skills of community
                    diagnosis;</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> increase ability to design strategies to enhance
                    community health;</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> developed skills to implement intervention
                    programs and public health policy;</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> enhanced knowledge to evaluate the impact of
                    public health policies on community health; </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> provide opportunity in research and publication;
                    and</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> create leadership skills in public health
                    administration.</li>
            </ul>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">import_contacts</mat-icon>
                &nbsp;&nbsp;Course Details
            </ng-template>
            <p class="secondTabPara" style="margin-top: 20px; color: rgb(2, 2, 107);">
                There are 13 courses in the Master of Public Health program. These courses are grouped under three major
                headings
            </p>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th>Sl.</th>
                        <th>Type</th>
                        <th>Number of Courses</th>
                        <th>Credits</th>
                    </tr>
                    <tr>
                        <td class="text-center">1</td>
                        <td>Foundation Courses</td>
                        <td class="text-center">03</td>
                        <td class="text-center">12</td>
                    </tr>
                    <tr>
                        <td class="text-center">2</td>
                        <td>Professional Courses</td>
                        <td class="text-center">09</td>
                        <td class="text-center">36</td>
                    </tr>
                    <tr>
                        <td class="text-center">3</td>
                        <td>Research Project 
                            (Project+ Defense+ Comprehensive Viva-voce)
                            </td>
                        <td class="text-center">01</td>
                        <td class="text-center">12</td>
                    </tr>
                    <tr>
                        <th colspan="2">Total</th>
                        <th>13</th>
                        <th>60</th>
                    </tr>
                </table>
            </div>
            <p class="secondTabPara" style="margin-top: 20px;color: rgb(2, 2, 107);">Course Details for MPH program:</p>
             <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th>Level</th>
                        <th>Graduation</th>
                    </tr>
                    <tr>
                        <td>Semester</td>
                        <td>04</td>
                    </tr>
                    <tr>
                        <td>Semester Duration</td>
                        <td>6 months</td>
                    </tr>
                    <tr>
                        <td>Class Duration</td>
                        <td>5 months</td>
                    </tr>
                    <tr>
                        <td>Total Credit Hours</td>
                        <td>60 hours</td>
                    </tr>
                    <tr>
                        <td>Minimum Duration</td>
                        <td>2 years</td>
                    </tr>
                    <tr>
                        <td>Maximum Duration</td>
                        <td>4 years</td>
                    </tr>
                    <tr>
                        <td>Enrollment</td>
                        <td>Once in a Year(January)</td>
                    </tr>
                    <!-- <tr>
                        <td></td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td>Learner’s Guide and Admission Form Fee</td>
                        <td>TK. 1000</td>
                    </tr>
                    <tr>
                        <td>Course Fee (each credit)</td>
                        <td>Tk. 1000</td>
                    </tr>
                    <tr>
                        <td>Digital ID card</td>
                        <td>Tk. 200</td>
                    </tr>
                    <tr>
                        <td>Semester Registration Fee (Each semester)</td>
                        <td>Tk. 1000</td>
                    </tr>
                    <tr>
                        <td>Reexamination Fee per course (if applicable)</td>
                        <td>TK. 350</td>
                    </tr>
                    <tr>
                        <td>Academic Calendar Fee</td>
                        <td>TK. 50</td>
                    </tr>
                    <tr>
                        <td>Program Transcript Fee</td>
                        <td>TK. 400</td>
                    </tr>
                    <tr>
                        <td>Provisional Certificate Fee</td>
                        <td>TK. 300</td>
                    </tr>
                    <tr>
                        <td>Original Certificate Fee</td>
                        <td>TK. 500</td>
                    </tr>
                    <tr>
                        <td>Marks sheet</td>
                        <td>TK. 300</td>
                    </tr>
                    <tr>
                        <td>Semester Marks sheet</td>
                        <td>TK. 100</td>
                    </tr> -->
                </table>
            </div> 
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">verified</mat-icon>
                &nbsp;&nbsp;Eligibility
            </ng-template>
            <h2>
                Eligible criteria for Admission into the MPH Program
            </h2>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Applicant must have Graduates from any discipline</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Preference should be given to whom possess MBBS, BDS, MD, B.Sc.-in-Nursing degree, Physiotherapist and/or a three (03) to four (04) years Allied Health Science Bachelor degree holder with a grade point average 2.5 or more on a scale of 4 and having experience in the health sector; Eligible foreign learners can also be apply through online.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Proficiency in English, Statistics and Computer literacy is essential for pursuing this program. </li>
            </ul>
            <h2>Selection Criteria</h2>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Applicant must be Graduates from any discipline;</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Preference should be given to whom possess MBBS, BDS, MD, B.Sc.-in-Nursing degree
                    and/or a
                    three (03) to four (04) years Bachelor degree holder with a grade point average 2.5
                    or more
                    on a
                    scale of 4 and having experience in the health sector; Eligible foreign learners can
                    also be
                    applied through online.
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Proficiency in English is essential for pursuing this program.</li>
            </ul>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">military_tech</mat-icon>
                &nbsp;&nbsp;Evaluation
            </ng-template>
            <h2>Distribution of Marks</h2>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th style="width: 50px;">Sl.</th>
                        <th colspan="2">Pattern of Marks Distribution</th>
                        <th>Marks</th>
                        <th>Total</th>
                        <th>Pass Marks</th>
                    </tr>
                    <tr>
                        <td rowspan="3" class="text-center">1</td>
                        <td rowspan="3" class="text-center">Formative Assessmentand/or 
                            Online Assessment
                        </td>
                        <td class="text-center">Attendance/
                            OnlineDiscussion Forum
                        </td>
                        <td class="text-center">10
                        </td>
                        <td rowspan="3" class="text-center">40
                        </td>
                        <td rowspan="3" class="text-center">60%</td>
                    </tr>
                    <tr>
                        <td class="text-center">Class test (Quiz, MCQ)</td>
                        <td class="text-center">10</td>
                    </tr>
                    <tr>
                        <td class="text-center">Assignment (2x10 =20, out of 3)</td>
                        <td class="text-center">20</td>
                    </tr>
                    <tr>
                        <td class="text-center">2</td>
                        <td class="text-center">Semester Final Examination </td>
                        <td class="text-center">To be answered 6 questions out of 10 </td>
                        <td class="text-center">60</td>
                        <td class="text-center">60</td>
                        <td class="text-center">60%</td>
                    </tr>
                </table>
            </div>
            <h2 style="margin-top: 20px;">Basis for awarding marks for Tutorial Class and or Online Participation:</h2>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th rowspan="2">Tutorial Class or Online Participation</th>
                        <th colspan="2">Marks (10)</th>
                    </tr>
                    <tr>
                        <td class="text-center">Attendance (5)</td>
                        <td class="text-center">Discussion Forum (5)</td>
                    </tr>
                    <tr>
                        <td>90% and above</td>
                        <td class="text-center">5</td>
                        <td class="text-center">5</td>
                    </tr>
                    <tr>
                        <td>80% to less than 90%</td>
                        <td class="text-center">4</td>
                        <td class="text-center">4</td>
                    </tr>
                    <tr>
                        <td>70% to less than 80%</td>
                        <td class="text-center">3</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td>60% to less than 70%</td>
                        <td class="text-center">2</td>
                        <td class="text-center">2</td>
                    </tr>
                    <tr>
                        <td>50% to less than 60%</td>
                        <td class="text-center">1</td>
                        <td class="text-center">1</td>
                    </tr>
                    <tr>
                        <td>Less than 50%</td>
                        <td class="text-center">0</td>
                        <td class="text-center">0</td>
                    </tr>
                </table>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">account_circle</mat-icon>
                &nbsp;&nbsp;Academic Co-ordinator
            </ng-template>
            <div style="margin: 20px;">
                <h1>For more information:</h1>
                <ul class="fifthTabul" style="padding-inline-start: 20px;">
                    <li>Dr. Sharker Md. Numan</li>
                    <li>Professor (Medical Science) </li>
                    <li>School of Science and Technology</li>
                    <li>Bangladesh Open University</li>
                    <li>Board Bazar, Gazipur-1705.</li>
                    <li>E-mail: sharkermd_numan&#64;yahoo.com</li>
                    <li>Phone: Off: 9291111, 09666730730 Ex.685, 687
                        Fax: 9291111, 9006141(Res), 01727210110(cell).</li>
                </ul>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add_alert</mat-icon>
                &nbsp;&nbsp;Notice
            </ng-template>
            <div class="table-container">
                <table class="notice-table">
                    <ng-container *ngFor="let notice of noticeList; index as i">
                        <ng-container *ngIf="!notice?.isDeleted">
                            <ng-container *ngIf="!notice?.isDisabled">
                                
    
                                    <tr>
                                       
                                        <td class="small-cell">
                                            <div class="dateDiv">
                                                <div class="month">{{notice?.noticeSubmissionDate | date :'MMMM'}}</div>
                                                <div class="date">{{notice?.noticeSubmissionDate | date :'d'}}, {{notice?.noticeSubmissionDate | date :'y'}}</div>
                                                <!-- <div class="year"></div> -->
                                            </div>
                                            <div class="half-circle">
                                            </div>                                    
                                        </td> 
                                       
                                         <td class="notice-cell">
                                             <a href="{{notice?.noticeFile}}" target="blank" matTooltip="Click for download">{{notice?.noticeBody}} </a> <app-new-icon [date]="notice?.noticeSubmissionDate"></app-new-icon>
                                            
                                        </td>
                                    </tr>
                                
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card>

<app-footer></app-footer>
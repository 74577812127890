<app-navbar></app-navbar>
<main class="mx-lg-5">
    <div class="container-fluid">
        <div class="heading-container">
            <div class="verticallyMiddle">
            <span>A</span>
            <span>D</span>
            <span>M</span>
            <span>I</span>
            <span>S</span>
            <span>S</span>
            <span>I</span>
            <span>O</span>
            <span>N</span>
            <div class="brDiv"></div>
            <div class="brDiv"></div>
            <span>N</span>
            <span>O</span>
            <span>T</span>
            <span>I</span>
            <span>C</span>
            <span>E</span>
            </div>
        </div>
        <div class="table-container">
            <table class="notice-table">
                <ng-container *ngFor="let notice of noticeList; index as i">
                    <ng-container *ngIf="!notice?.isDeleted">
                        <ng-container *ngIf="!notice?.isDisabled">
                            

                                <tr>
                                   
                                    <td class="small-cell">
                                        <div class="dateDiv">
                                            <div class="month">{{notice?.noticeSubmissionDate | date :'MMMM'}}</div>
                                            <div class="date">{{notice?.noticeSubmissionDate | date :'d'}}, {{notice?.noticeSubmissionDate | date :'y'}}</div>
                                            <!-- <div class="year"></div> -->
                                        </div>
                                        <div class="half-circle">
                                        </div>                                    
                                    </td> 
                                   
                                     <td class="notice-cell">
                                         <a href="{{notice?.noticeFile}}" target="blank" matTooltip="Click for download">{{notice?.noticeBody}} </a> <app-new-icon [date]="notice?.noticeSubmissionDate"></app-new-icon>
                                        
                                    </td>
                                </tr>
                            
                        </ng-container>
                    </ng-container>
                </ng-container>
            </table>
        </div>
    </div>
</main>

<div class="footer-div">
    <app-footer></app-footer>
</div>
import { Component, OnInit } from '@angular/core';
declare var ol: any;


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  latitude: number = 23.951175100243173;
  longitude: number = 90.37965188465594;
  constructor() { }

  // 23.951175100243173, 90.37965188465594
  map: any;

  ngOnInit() {



    this.map = new ol.Map({
      target: 'map',
      layers: [
        new ol.layer.Tile({
          source: new ol.source.OSM()
        })
      ],
      view: new ol.View({
        center: ol.proj.fromLonLat([90.37965188465594, 23.951175100243173]),
        zoom: 17
      })
    });
    this.setCenter()
  }

  setCenter() {
    var view = this.map.getView();
    view.setCenter(ol.proj.fromLonLat([this.longitude, this.latitude]));
    view.setZoom(17);
  }
}

import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrls: ['./create-admin.component.scss']
})
export class CreateAdminComponent implements OnInit {

  hide = false;
  constructor(private router: Router,
    private authService: AuthService,) { }
  createAdminForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.email]),
    password: new FormControl(null, Validators.required),
  })
  ngOnInit(): void {
  }
  createAdmin() {
    this.authService.createAdmin(this.createAdminForm.value)
      .subscribe((admin) => {

        console.log(admin)
        alert("Admin created successfully !")
        // this.createAdminForm.reset()
        this.router.navigateByUrl('/admin/list');
      }, error => {
        alert('error occured')
        console.error(error)
      })
  }

}

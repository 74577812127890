import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-access',
  templateUrl: './admin-access.component.html',
  styleUrls: ['./admin-access.component.scss']
})
export class AdminAccessComponent implements OnInit {

  constructor(private route: ActivatedRoute, private authService: AuthService) { }
  accessList
  newAccessList = {
    access: [

      { routeName: "admin/notice/create", hasAccess: true }, { routeName: "admin/notice/view", hasAccess: true }, { routeName: "admin/notice/edit", hasAccess: true }, { routeName: "admin/doc/create", hasAccess: true }, { routeName: "admin/doc/view", hasAccess: true }, { routeName: "admin/doc/edit", hasAccess: true }, { routeName: "admin/routine/create", hasAccess: true }, { routeName: "admin/routine/view", hasAccess: true }, { routeName: "admin/routine/edit", hasAccess: true }, { routeName: "admin/list", hasAccess: true }, { routeName: "admin/create", hasAccess: true }, { routeName: "admin/access/:adminId", hasAccess: true }, { routeName: "admin/contact-us-message", hasAccess: true }, { routeName: "admin/view-message/:id", hasAccess: true }, { routeName: "admin/faculty-members", hasAccess: true }, { routeName: "admin/add-member", hasAccess: true }, { routeName: "admin/view-message/:id", hasAccess: true }, { routeName: "admin/view-facuty-member/:id", hasAccess: true }, { routeName: "admin/update-member-info", hasAccess: true }

      , {
        routeName: "complains/reply",
        hasAccess: true
      }

    ],
    adminId: this.route.snapshot.paramMap.get('adminId')
  }
  ngOnInit(): void {
    this.authService.getAccess(this.route.snapshot.paramMap.get('adminId'))
      .subscribe(res => {
        if (res.isFound) {
          this.accessList = res.result

          console.log(this.accessList)
        } else {
          console.log("not found")
          this.authService.createAccess(this.newAccessList)
            .subscribe((result) => {
              this.accessList = result.result
            })
        }

      })
  }
  update(label: string) {
    for (var i = 0; i < this.accessList.access.length; i++) {
      if (label === this.accessList.access[i].routeName) {
        this.accessList.access[i].hasAccess ? this.accessList.access[i].hasAccess = false : this.accessList.access[i].hasAccess = true;
      }
    }
    console.log(this.accessList.access)
  }

  saveAccessControl() {
    this.authService.updateAccess(this.accessList._id, this.accessList)
      .subscribe(res => {
        console.log(res)
      })
  }


}

import { Router } from '@angular/router';
import { Validators } from '@angular/forms';
import { DocumentsService } from 'src/app/services/documents.service';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-gallery-image',
  templateUrl: './upload-gallery-image.component.html',
  styleUrls: ['./upload-gallery-image.component.scss']
})
export class UploadGalleryImageComponent implements OnInit {
  uploadImageForm
  constructor(public fb: FormBuilder, public documentsService: DocumentsService, private router: Router) { }

  ngOnInit(): void {

    this.uploadImageForm = this.fb.group({
      image: ['', Validators.required],
      caption: [''],
      adminId: JSON.parse(localStorage.getItem('_id'))

    });
  }
  onSelectFile(event) {
    const file = event.target.files[0];
    this.uploadImageForm.patchValue({
      image: file
    });
  }


  uploadImage() {
    console.log(this.uploadImageForm.value)
    let form_data = new FormData();

    for (let key in this.uploadImageForm.value) {
      form_data.append(key, this.uploadImageForm.value[key]);
    }
    this.documentsService.createNewImage(form_data)
      .subscribe(data => {
        if (data.isCreated) {
          alert("success")
          // window.location.reload()
          this.router.navigateByUrl("/admin/gallery-image/list")
        }
      })
  }
}

<app-admin-navbar></app-admin-navbar>
<div class="pt-5 mx-lg-5">
  <mat-card>
    <form class="form" [formGroup]="update_faculty_member_form">
      <div class="first-portion">
        <h1>Update Faculty Member</h1>
        <div class="div-right">
          <div class="uploadPhoto-div">Upload Photo</div>
          <div class="image-upload">
            <!-- Image Preview -->
            <label class="hoverable" for="fileInput">
              <img [src]="url ? url : updateFacultyMemberFormData?.applicantPicture" alt="">
              <div class="hover-text">Choose Image
                <p>Size: 300 x 300</p>
              </div>
              <div class="background"></div>
            </label>
            <br>
            <input id="fileInput" type='file' (change)="onSelectFile($event)">
          </div>
        </div>
      </div>

      <div class="second-portion">

        <h1> Faculty Member's Information</h1>

        <mat-form-field appearance="outline">
          <mat-label>Full Name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Designation with subject</mat-label>
          <input matInput formControlName="designation">
        </mat-form-field>

        <!-- <mat-form-field appearance="outline">
                    <mat-label>Subject</mat-label>
                    <input matInput formControlName="subject"  >
                </mat-form-field> -->

        <mat-form-field appearance="outline">
          <mat-label>Years of experience</mat-label>
          <input matInput formControlName="experience">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Valid E-mail</mat-label>
          <input matInput placeholder="pat@example.com" formControlName="email">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Phone Number (Residence)</mat-label>
          <input matInput formControlName="phoneNumber_residence">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Phone Number (Personal)</mat-label>
          <input matInput formControlName="phoneNumber_personal">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Phone Number (Office)</mat-label>
          <input matInput formControlName="phoneNumber_office">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Ex. Number (Office)</mat-label>
          <input matInput formControlName="ex_number_office">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Fax Number (Office)</mat-label>
          <input matInput formControlName="fax_number_office">
        </mat-form-field>

        <!-- <mat-form-field appearance="outline">
                    <mat-label>Days with BOU</mat-label>
                    <input matInput formControlName="days_bou"    >
                </mat-form-field> -->
        <mat-form-field appearance="outline">
          <mat-label>Joining Date (MM/DD/YYYY)</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="days_bou">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Number of Publications</mat-label>
          <input matInput formControlName="num_publiction">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Twitter Id</mat-label>
          <input matInput formControlName="twitter" placeholder="Add Your Twitter Link">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Facebook Id</mat-label>
          <input matInput formControlName="facebook" placeholder="Add Your Twitter Link">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Linkedin Id</mat-label>
          <input matInput formControlName="linkedin" placeholder="Add Your Twitter Link">
        </mat-form-field>

        <!-- <mat-form-field appearance="outline">
                    <mat-label>Email Id</mat-label>
                    <input matInput formControlName="mail"  >
                </mat-form-field> -->

        <mat-form-field appearance="outline">
          <mat-label>Instagram Id</mat-label>
          <input matInput formControlName="bio" placeholder="Add Your Instagram Link">
        </mat-form-field>

        <!-- <mat-form-field appearance="outline">
                    <mat-label>Employment Histroy</mat-label>
                    <textarea matInput formControlName="employment_history"></textarea>
                </mat-form-field> -->

        <mat-form-field appearance="outline">
          <mat-label>Research Interest</mat-label>
          <textarea matInput formControlName="research_interest"></textarea>
        </mat-form-field>
        <!-- <ckeditor [editor]="Editor" formControlName="employment_history" (change)="onChange($event)"></ckeditor> -->

        <mat-form-field appearance="outline">
          <mat-label>Upload CV</mat-label>
          <ngx-mat-file-input type='file' (change)="onSignatureSelectFile($event)" placeholder="Include a CV">
          </ngx-mat-file-input>
          <mat-icon matSuffix>folder</mat-icon>
        </mat-form-field>
        <mat-radio-group [(ngModel)]="deanSelected" [ngModelOptions]="{standalone: true}">
          <mat-radio-button *ngFor="let dean of makeDeanList" [value]="dean.value" (change)="updateDean($event)">
            >{{dean.text}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="fifth-portion">
        <div class="d-flex justify-content-center mt-5">
          <div class="form-group">
            <button class="submit btn btn-primary" mat-raised-button (click)="updateFacultyMember()">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </mat-card>
</div>

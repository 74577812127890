<app-navbar></app-navbar>
<mat-card>
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">school</mat-icon>
                &nbsp;&nbsp;B.Sc in FSN
            </ng-template>
            <img style="margin: 20px;" src="../../../../../assets/program_details/image/foodscience.svg" alt="">
            <p class="firstTabPara">
                Bangladesh is an agricultural based country and has remarkably advanced in food
                production. But like food production, food preservation, processing, packaging and
                dealing with nutritional aspects of food are not so advanced due to lack of sufficient
                skilled manpower in food sector. Additionally, due to lack of proper knowledge
                about food and nutrition, a large number of populations of Bangladesh are suffering
                from malnutrition which is a huge obstacle in building healthy nation. As a result,
                food producers, consumers and as a whole country are not getting maximum
                benefit from foods. So, we need skilled manpower equipped with scientific
                knowledge in food preservation, processing, packaging; able to develop innovative,
                safe and healthy food products; able to advice and provide nutritional information
                to the populations; with entrepreneurial skills to set up safe food related business
                ventures.
            </p>
            <h2>The objectives of the program are:</h2>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To develop graduates with knowledge about the
                    sources, functions and process
                    of utilization of nutrients in the body;</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To develop graduates with required knowledge in
                    promoting health through
                    healthy dietary practices and preventing nutrition-related diseases;
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To develop graduates skill in analyzing food
                    components and food hazards;
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To develop graduates expert in assessing
                    nutritional status and prescribing
                    appropriate diets in different stages of life and conditions;
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To equip graduates with theoretical and technical
                    knowledge in ensuring food
                    safety and hygiene;
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To produce graduates with scientific knowledge in
                    processing, preservation,
                    preparation and controlling quality of foods;
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To develop graduates skill in designing research
                    proposal, conducting research
                    and writing research report;</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To develop graduates with knowledge in
                    running/developing small to medium
                    scale food business and entrepreneurial mind;
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To develop graduates with interpersonal
                    communication (both written and verbal
                    in English) skills, leadership and group work skills to face the challenges in the
                    global setting;
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To develop graduates with high standard ethical,
                    moral and cultural values.</li>
            </ul>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">import_contacts</mat-icon>
                &nbsp;&nbsp;Course Details
            </ng-template>
            <p class="secondTabPara" style="margin: 30px; color: rgb(2, 2, 107);">Course Details for B.Sc (Hons) in Food
                Science and Nutrition Program </p>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Total courses: 46 </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Core courses: 24 (66 credits) (Theory: 18×3=54
                    and Lab: 6×2=12 credits)</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Ancillary courses: 21 (52 credits) (Theory:
                    14×3=42 and Lab: 5×2=10 credits)
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> One Project/Thesis Work course: 6 credits</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> One Comprehensive Viva voce course: 3 credits
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Internship: 3 credits</li>
            </ul>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">verified</mat-icon>
                &nbsp;&nbsp;Eligibility
            </ng-template>
            <h2>
                Minimum Qualification for Admission into the Program
            </h2>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> The minimum qualification for admission into the
                    B.Sc (Hons) in Food Science and
                    Nutrition program is S.S.C (science) and H.S.C (science) with GPA 2.50/2nd
                    division separately or S.S.C (science) with 2
                    nd division/GPA 2.50 and diploma with
                    2
                    nd division/CGPA 2.75/pass from any recognized institute (Institute of Health
                    Technology, Agriculture Training Institute, Nursing Institute, Medical Assistant Training School,
                    Food Technology Institute, etc.) or equivalent.
                    Biology/physiology/nutrition and chemistry must be included in any examination of
                    the applicants.
                </li>
            </ul>
            <h2>Selection Criteria</h2>
            <p class="thirdTabPara">
                Learners shall be selected for admission into the B.Sc (Hons) in Food Science
                and Nutrition program on the basis of result of the admission test. Admission
                test (in MCQ type questions) shall be of 100 marks for one hour with the
                following marks distribution.
            </p>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th>Subject</th>
                        <th>Marks</th>
                    </tr>
                    <tr>
                        <td class="text-center">Chemistry</td>
                        <td class="text-center">30</td>
                    </tr>
                    <tr>
                        <td class="text-center">Biology</td>
                        <td class="text-center">30</td>
                    </tr>
                    <tr>
                        <td class="text-center">English</td>
                        <td class="text-center">20</td>
                    </tr>
                    <tr>
                        <td class="text-center">General Knowledge</td>
                        <td class="text-center">20</td>
                    </tr>
                    <tr>
                        <th>Total</th>
                        <th>100
                        </th>
                    </tr>
                </table>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">military_tech</mat-icon>
                &nbsp;&nbsp;Evaluation
            </ng-template>
            <p class="thirdTabPara" style="margin-top: 20px;">
                To complete a course successfully, a learner shall be required to go through an
                evaluation procedure. The performance of a learner in each course (theoretical
                and practical) shall be evaluated based on a scheme of continuous assessments
                and semester final examination.
            </p>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> For theory courses, the continuous assessment of
                    the learners shall be done
                    through class tests.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> For practical courses, the continuous assessment
                    of the learners shall be done
                    through home work/assignment/quiz/practical work.
                </li>
            </ul>
            <h2>Total Marks</h2>
            <p class="thirdTabPara">
                Total marks of the B.Sc (Hons) in Food Science and Nutrition program shall be
                4150; out of which 3200 marks for theoretical courses, 550 marks for practical
                courses, 200 marks for project work, 100 marks for internship and 100 marks for
                comprehensive viva voce.
            </p>
            <h2>Distribution of Marks for Evaluation</h2>
            <p>(a) Distribution of Marks for Theory and Comprehensive Viva voce Courses</p>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th rowspan="2">Course Type</th>
                        <th rowspan="2">Credits</th>
                        <th colspan="2">Continuous Assessment</th>
                        <th rowspan="2">Semester Final Exam</th>
                        <th rowspan="2">Total Marks</th>
                    </tr>
                    <tr>
                        <td class="text-center">Attendance</td>
                        <td class="text-center">Class test </td>
                    </tr>
                    <tr>
                        <td>Theory</td>
                        <td class="text-center">3</td>
                        <td class="text-center">10 marks </td>
                        <td class="text-center">15×2=30 marks</td>
                        <td class="text-center">60 marks</td>
                        <td class="text-center">100</td>
                    </tr>
                    <tr>
                        <td>Com. Viva voce</td>
                        <td class="text-center">3</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">100</td>
                    </tr>
                </table>
            </div>
            <p style="margin-top: 15px;">(b) Distribution of Marks for Practical Courses</p>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th>Course Type </th>
                        <th>Credits</th>
                        <th>Category </th>
                        <th>Marks</th>
                    </tr>
                    <tr>
                        <td rowspan="6" class="text-center">Practical </td>
                        <td rowspan="6" class="text-center">2</td>
                        <td class="text-center">1. Class attendance</td>
                        <td class="text-center">5</td>
                    </tr>
                    <tr>
                        <td class="text-center">2. HW/Assignment/Quiz </td>
                        <td class="text-center">10</td>
                    </tr>
                    <tr>
                        <td class="text-center">3. Laboratory note book </td>
                        <td class="text-center">10</td>
                    </tr>
                    <tr>
                        <td class="text-center">4. Experiment</td>
                        <td class="text-center">20</td>
                    </tr>
                    <tr>
                        <td class="text-center">5. Viva voce </td>
                        <td class="text-center">5</td>
                    </tr>
                    <tr>
                        <th>Total</th>
                        <th>50</th>
                    </tr>
                </table>
            </div>
            <p style="margin-top: 15px;">(c) Distribution of Marks for Project/Thesis Work</p>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th>Course Type </th>
                        <th>Credits</th>
                        <th>Category </th>
                        <th>Marks</th>
                    </tr>
                    <tr>
                        <td rowspan="7" class="text-center">Project/Thesis Work </td>
                        <td rowspan="7" class="text-center">6</td>
                        <td class="text-center">Objective(s) of the work done</td>
                        <td class="text-center">20</td>
                    </tr>
                    <tr>
                        <td class="text-center">Methodology adopted </td>
                        <td class="text-center">40</td>
                    </tr>
                    <tr>
                        <td class="text-center">Results </td>
                        <td class="text-center">20</td>
                    </tr>
                    <tr>
                        <td class="text-center">Discussions</td>
                        <td class="text-center">40</td>
                    </tr>
                    <tr>
                        <td class="text-center">Conclusions and future perspective </td>
                        <td class="text-center">20</td>
                    </tr>
                    <tr>
                        <td class="text-center">Viva (after project/thesis presentation) </td>
                        <td class="text-center">60</td>
                    </tr>
                    <tr>
                        <th>Total</th>
                        <th>200</th>
                    </tr>
                </table>
            </div>
            <p style="margin-top: 15px;">(d) Distribution of Marks of Internship Course</p>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th>Course Type </th>
                        <th>Credits</th>
                        <th>Category </th>
                        <th>Marks</th>
                    </tr>
                    <tr>
                        <td rowspan="3" class="text-center">Internship</td>
                        <td rowspan="3" class="text-center">3</td>
                        <td class="text-center">Report </td>
                        <td class="text-center">50</td>
                    </tr>  
                    <tr>
                        <td class="text-center">Seminar</td>
                        <td class="text-center">20</td>
                    </tr>  
                    <tr>
                        <td class="text-center">Viva</td>
                        <td class="text-center">30</td>
                    </tr>  
                    <tr>
                        <th colspan="3">Total</th>
                        <th>100</th>
                    </tr>              
                </table>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">account_circle</mat-icon>
                &nbsp;&nbsp;Academic Co-ordinator
            </ng-template>
            <div style="margin: 20px;">
                <h1>For more information:</h1>
                <ul class="fifthTabul" style="padding-inline-start: 20px;">
                    <li>Dr. Md. Abdul Mojid Mondol</li>
                    <li>Associate Professor (Pharmacy) </li>
                    <li>School of Science and Technology</li>
                    <li>Bangladesh Open University</li>
                    <li>Board Bazar, Gazipur-1705.</li>
                    <li>E-mail: drmojidmondol&#64;gmail.com</li>
                    <li>Phone: 09666730730 Ex.689, 01712943214 (cell).</li>
                </ul>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add_alert</mat-icon>
                &nbsp;&nbsp;Notice
            </ng-template>
            <div class="table-container">
                <table class="notice-table">
                    <ng-container *ngFor="let notice of noticeList; index as i">
                        <ng-container *ngIf="!notice?.isDeleted">
                            <ng-container *ngIf="!notice?.isDisabled">
                                
    
                                    <tr>
                                       
                                        <td class="small-cell">
                                            <div class="dateDiv">
                                                <div class="month">{{notice?.noticeSubmissionDate | date :'MMMM'}}</div>
                                                <div class="date">{{notice?.noticeSubmissionDate | date :'d'}}, {{notice?.noticeSubmissionDate | date :'y'}}</div>
                                                <!-- <div class="year"></div> -->
                                            </div>
                                            <div class="half-circle">
                                            </div>                                    
                                        </td> 
                                       
                                         <td class="notice-cell">
                                             <a href="{{notice?.noticeFile}}" target="blank" matTooltip="Click for download">{{notice?.noticeBody}} </a> <app-new-icon [date]="notice?.noticeSubmissionDate"></app-new-icon>
                                            
                                        </td>
                                    </tr>
                                
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card>

<app-footer></app-footer>
<mat-card>
    <form class="form" [formGroup]="complaint_form">

        <h1>Student's Feedback Form</h1>

        <mat-form-field appearance="fill"> 
            <mat-label>Name </mat-label>
            <input matInput formControlName="studentName" required>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Student ID </mat-label>
            <input matInput formControlName="studentID" required>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Email </mat-label>
            <input matInput formControlName="studentEmail" required>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Your Topic </mat-label>
            <input matInput formControlName="topicName" required>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Your Message</mat-label>
            <textarea matInput placeholder="Complaint massage...." formControlName="messageBody"></textarea>
        </mat-form-field>


        <mat-form-field appearance="fill">
            <mat-label>File (Optional) </mat-label>
            <ngx-mat-file-input type='file' (change)="onSelectFile($event)"
                placeholder="Include necessary file (Optional)">
            </ngx-mat-file-input>
            <mat-icon matSuffix>folder</mat-icon>
        </mat-form-field>
        <button mat-raised-button (click)="sendComplaint()">Submit</button>
 
    </form>
</mat-card>
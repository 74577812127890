<app-admin-navbar></app-admin-navbar>
<main class="right-side-content">
  <mat-card>
    <table class="table table-bordered table-hover" fixed-header>
      <thead class="table-active">
        <tr>
          <th scope="row"><strong>No</strong></th>
          <td><strong>Name</strong></td>
          <td><strong>Role Access</strong></td>
        </tr>
      </thead>
      <tbody *ngFor="let menu of accessList?.access; let i = index ">
        <tr>
          <th scope="row">{{i+1}}</th>
          <td>{{menu?.routeName}}</td>
          <td>
            <mat-checkbox [checked]="menu?.hasAccess" (change)='update(menu?.routeName)'>
            </mat-checkbox>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card>
  <button class="btn btn-success" (click)="saveAccessControl()"> Save </button>
</main>

import { Component, OnInit } from '@angular/core';
import { NoticeService } from '../../../../services/notice.service';

@Component({
  selector: 'app-bsc-food-science-nutrition',
  templateUrl: './bsc-food-science-nutrition.component.html',
  styleUrls: ['./bsc-food-science-nutrition.component.scss']
})
export class BscFoodScienceNutritionComponent implements OnInit {

  noticeList
  constructor(public noticeService: NoticeService) { }

  ngOnInit(): void {
    this.noticeService.getNoticeByProgram('B.Sc (Hons) in Food Science and Nutrition')
      .subscribe(data => {
        if (data.isFound) {
          this.noticeList = data.result;
         console.log(this.noticeList)
        }
      })
  }

}

<app-admin-navbar></app-admin-navbar>
<main class="pt-5 mx-lg-5">
    <div class="container-fluid mt-5">
        <mat-card>
            <h3>Edit Routine</h3>
            <div class="form-1">
                <form [formGroup]="editRoutineForm">


                    <mat-form-field appearance="fill">
                        <mat-label>Routine Year</mat-label>
                        <mat-select formControlName="routineYear">
                            <mat-option *ngFor="let routineYear of RoutineYear" [value]="routineYear" required>
                                {{routineYear}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Routine Semister</mat-label>
                        <mat-select formControlName="routineSemister">
                            <mat-option *ngFor="let routineSemister of RoutineSemister" [value]="routineSemister"
                                required>
                                {{routineSemister}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Routine Session</mat-label>
                        <mat-select formControlName="routineSession">
                            <mat-option *ngFor="let routineSession of RoutineSession" [value]="routineSession" required>
                                {{routineSession}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>



                    <mat-form-field appearance="fill">
                        <mat-label>Program Name</mat-label>
                        <mat-select formControlName="programName">
                            <mat-option *ngFor="let programName of ProgramName" [value]="programName" required>
                                {{programName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>


                    <mat-form-field appearance="fill">
                        <mat-label>File</mat-label>
                        <ngx-mat-file-input type='file' (change)="onSelectFile($event)"
                            placeholder="Include necessary file">
                        </ngx-mat-file-input>
                        <mat-icon matSuffix>folder</mat-icon>
                    </mat-form-field>
                </form>
                <button class="btn btn-primary" (click)="editRoutine()">Create</button>
            </div>
        </mat-card>
    </div>
</main>
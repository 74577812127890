<app-admin-navbar></app-admin-navbar>
<main class="left-side">
    <section> 
        <div class="container">
            <mat-card>
                <h1>Message</h1>
                <mat-card-header>
                    <mat-card-title>
                        <h3>Message ID :
                            {{contactData.contactID}}</h3>
                        <h3>Name : {{contactData.name}}</h3>
                        <h3>Email : {{contactData.email}}</h3>
                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <h2>Message : </h2>
                    <p>
                        {{contactData.messageBody}} 
                    </p>
                </mat-card-content>
            </mat-card>
        </div>
    </section>
</main>


import { ContactUsService } from './../../../app/services/contact-us.service';
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  isValidFormSubmitted = null;
  contact_us_form: any;

  constructor(
    public fb: FormBuilder,
    public router: Router,
    public ContactUsService: ContactUsService
  ) { }

  ngOnInit(): void {
    this.contact_us_form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      messageBody: ['', Validators.required],
    })
  }

  sendComplaint() {
    this.isValidFormSubmitted = false;
    if (this.contact_us_form.invalid) {
      return;
    }
    this.isValidFormSubmitted = true;
    this.ContactUsService.createNewContact(this.contact_us_form.value)
      .subscribe((res) => {
        if (res.isCreate) {
          // this.snotifyService.success("Your Message is Submit Successfully!", {
          //   timeout: 3000,
          //   showProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   position: SnotifyPosition.centerCenter
          // })
          console.log(res)
          alert('Your Message is Submit');
          // window.location.reload();
          this.contact_us_form.reset();
        } else {
          console.log(res)
          alert('Something Wrong');
        }
      })
  }

}

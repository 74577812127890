import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bsn-video-content',
  templateUrl: './bsn-video-content.component.html',
  styleUrls: ['./bsn-video-content.component.scss']
})
export class BsnVideoContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<app-navbar></app-navbar>
<div class="container-fluid">
  <mat-card class="alumni-info">
    <mat-card-content>
      <div class="container">

        <form class="form" [formGroup]="alumnimember">
          <div>
            <!-- Image Preview -->
            <label class="hoverable" for="fileInput">
              <img [src]="url ? url:'../../../assets/camera.svg'">
              <div class="hover-text">Choose Image</div>
              <div class="background"></div>
            </label>
            <br>
            <input id="fileInput" type='file' (change)="onSelectFile($event)">

            <!-- <div *ngIf="isImageLoaded"><button class="btn btn-primary" type="submit" data-toggle="modal"
                data-target="#cropModalCenter" (click)="cropImageNeed()">Crop
                Image</button></div> -->
            <!-- Modal -->
            <div class="modal fade" id="cropModalCenter" tabindex="-1" role="dialog" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <strong class="modal-title">Crop your Image</strong>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">

                    <div class="cropperPreviewContainer">
                      <!-- <div>
                        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                          [aspectRatio]="4 / 3" format="png" (imageCropped)="imageCropped($event)"
                          (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                          (loadImageFailed)="loadImageFailed()"></image-cropper>
                      </div> -->

                    </div>


                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                    <div><button class="btn btn-success" type="button" data-dismiss="modal"
                        (click)="cropImageDone()">Done</button></div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput name="name" formControlName="name">
          </mat-form-field>


          <mat-form-field appearance="fill">
            <mat-label>Batch</mat-label>
            <mat-select formControlName="batch">
              <mat-option *ngFor="let batch of batch_list" [value]="batch">
                {{batch}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Registration Number</mat-label>
            <input matInput name="regno" formControlName="regno">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Study Center</mat-label>
            <input matInput formControlName="studyCenter">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Degree</mat-label>
            <input matInput formControlName="degree">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Program</mat-label>
            <mat-select formControlName="dept_group">
              <mat-option *ngFor="let dept_group of dept_groups" [value]="dept_group">
                {{dept_group}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <mat-form-field appearance="fill">
            <mat-label>Program</mat-label>
            <input matInput formControlName="faculty">
          </mat-form-field> -->

          <mat-form-field appearance="fill">
            <mat-label>Session</mat-label>
            <input matInput formControlName="session">
          </mat-form-field>


          <mat-form-field appearance="fill">
            <mat-label>Phone Number</mat-label>
            <input matInput formControlName="phoneNumber">
          </mat-form-field>


          <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email">
          </mat-form-field>


          <!-- <mat-form-field appearance="fill">
            <mat-label>Department</mat-label>
            <mat-select formControlName="dept_group">
              <mat-option *ngFor="let dept of alumniDeptList" [value]="dept">
                {{dept}}
              </mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field appearance="fill">
            <mat-label>Faculty or Group</mat-label>
            <mat-select formControlName="faculty">
              <mat-option *ngFor="let faculty of alumniFacultyList" [value]="faculty">
                {{faculty}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Name of Degree</mat-label>
            <mat-select formControlName="degree">
              <mat-option style="margin-bottom: 2px;" *ngFor="let degree of degreeList" [value]="degree">
                {{degree}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

          <!-- Submit -->
          <div class="form-group">
            <button [disabled]="isSubmit" mat-raised-button (click)="createAlMember()">Join</button>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<app-footer></app-footer>

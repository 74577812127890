<nav class="navbar sticky-top navbar-expand-lg navbar-light">
  <a class="navbar-brand" routerLink="/home"><img src="../../../assets/index1.png" class="logo"><span
      id="vname">Bangladesh
      Open
      University</span>
    <p class="sst">School of Science and Technology</p>
  </a>


  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="main_nav">

    <ul class="navbar-nav m-auto" id="ul-2">
      <li class="nav-item"> <a class="nav-link ul-2-a" routerLink="/home">Home </a> </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle ul-2-a" routerLink="#" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          About
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/about-sst">About SST</a>

          <a class="dropdown-item" routerLink="/faculty-members">Faculty Members</a>

          <a class="dropdown-item" routerLink="/alumni-dept-list">Alumni</a>

          <a class="dropdown-item" routerLink="/officials">Officials</a>

          <a class="dropdown-item" routerLink="/gallery">Gallery</a>

        </div>
      </li>
      <li class="nav-item dropdown has-megamenu">
        <a class="nav-link dropdown-toggle ul-2-a" routerLink="#" data-toggle="dropdown"> Program </a>
        <div class="dropdown-menu megamenu">
          <div class="row">
            <div class="col-md-3">
              <h3>Diploma</h3>
              <ul>
                <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/dcsa"> &nbsp;Diploma in
                    Computer
                    Science and Application (DCSA)</a></li>
              </ul>
            </div>
            <div class="col-md-3">
              <h3>Under Graduate</h3>
              <ul>
                <!-- <li><a routerLink="/BSc-nursing"> &nbsp;B. Sc in
                    Nursing (BSN)</a></li> -->
                <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/BSc-CSE"> &nbsp;Bachelor of
                    Science in Computer Science and Engineering (B.Sc in CSE)</a>
                </li>
                <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/BSc-foodScience"> &nbsp;B.Sc
                    (Hons) in Food Science and NutritionB.Sc (Hons) in Food
                    Science and Nutrition</a>
                </li>
              </ul>
            </div>
            <div class="col-md-3">
              <h3>Graduate</h3>
              <ul>
                <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/MDMR"> &nbsp;Master of
                    Disability Management and Rehabilitation (MDMR)</a></li>
                <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/MPH"> &nbsp;Master of Public
                    Health</a></li>
              </ul>
            </div>
            <div class="col-md-3">
              <h3>Post Graduate</h3>
              <ul>
                <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/PGDMU"> &nbsp;Post Graduate
                    Diploma in Medical Ultrasound (PGDMU)</a></li>
              </ul>
            </div>
          </div>
        </div> <!-- dropdown-mega-menu.// -->
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle ul-2-a" routerLink="#" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Notice
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/notice/admission-notice">Admission</a>

          <a class="dropdown-item" routerLink="/notice/registration-class-notice">Registration/Class </a>

          <a class="dropdown-item" routerLink="/notice/examination-notice">Examination</a>

          <a class="dropdown-item" routerLink="/notice/result-notice">Result</a>

          <a class="dropdown-item" routerLink="/notice/general-notice">General</a>

          <a class="dropdown-item" routerLink="/notice/employment-notice">Employment</a>

        </div>
      </li>

      <li class="nav-item dropdown has-megamenu">
        <a class="nav-link dropdown-toggle ul-2-a" routerLink="#" data-toggle="dropdown"> Admission </a>
        <div class="dropdown-menu megamenu">
          <div class="row">
            <div class="col-md-3">
              <h3>Diploma</h3>
              <ul>
                <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/dcsa-application-form">
                    &nbsp;Diploma in Computer Science and Application (DCSA)</a></li>
              </ul>
            </div>
            <div class="col-md-3">
              <h3>Under Graduate</h3>
              <ul>
                <!-- <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/nursing-application-form">
                    &nbsp;B. Sc in Nursing (BSN)</a></li> -->
                <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/cse-application-form">
                    &nbsp;Bachelor of Science in Computer Science and Engineering (B.Sc
                    in
                    CSE)</a></li>
                <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/FSN-application-form">
                    &nbsp;B.Sc (Hons) in Food Science and Nutrition (FSN)</a>
                </li>
              </ul>
            </div>
            <div class="col-md-3">
              <h3>Graduate</h3>
              <ul>
                <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/mdmr-application-form">
                    &nbsp;Master of Disability Management and Rehabilitation (MDMR)</a>
                </li>
                <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/mph-application-form">
                    &nbsp;Master of Public Health (MPH)</a></li>
                <!-- <li><a routerLink="#">Subject3</a></li> -->
              </ul>
            </div>
            <div class="col-md-3">
              <h3>Post Graduate</h3>
              <ul>
                <li><i class="fa fa-graduation-cap" aria-hidden="true"></i><a routerLink="/pgdmu-application-form">
                    &nbsp;Post Graduate Diploma in Medical Ultrasound (PGDMU)</a></li>
                <!-- <li><a routerLink="#">Master of Public Health</a></li> -->
                <!-- <li><a routerLink="#">Subject3</a></li> -->
              </ul>
            </div>
          </div>
        </div> <!-- dropdown-mega-menu.// -->
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle ul-2-a" routerLink="#" id="navbarDropdown" role="button"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Resources
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/study-guides">Program Handbook</a>

          <a class="dropdown-item" routerLink="/registration-form">Semester Registration Form</a>

          <a class="dropdown-item" routerLink="/study-centers">Study Centers</a>

          <!-- <a class="dropdown-item" routerLink="#">List of Tutors</a> -->

          <a class="dropdown-item" routerLink="/class-routine">Class Routine</a>

          <a class="dropdown-item" routerLink="/assignment">Assignment</a>

          <a class="dropdown-item" routerLink="/academic-calender">Academic Calender</a>

          <a class="dropdown-item" routerLink="/e-book">E-book</a>

          <a class="dropdown-item" routerLink="/video-audio-content">Video/Audio Content</a>

        </div>
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle ul-2-a" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Feedback
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">

          <a class="dropdown-item" routerLink="/complaint">New Feedback</a>

          <a class="dropdown-item" routerLink="/complains/status">Feedback Status</a>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item highlightedList"><a class="nav-link" routerLink="/contact-us">Contact</a></li>
    </ul>
  </div> <!-- navbar-collapse.// -->
</nav>

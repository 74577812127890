<nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" routerLink="#"></a>
  
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="main_nav">
  
      <ul class="navbar-nav m-auto" id="ul-2">
        <li class="nav-item"> <a class="nav-link ul-2-a" routerLink="/home">Home </a> </li>
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle ul-2-a" routerLink="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              School 
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="#">Open School</a>
    
              <a class="dropdown-item" routerLink="#">School of Agriculture and Rural Development</a>
    
              <a class="dropdown-item" routerLink="#">School of Business</a>
    
              <a class="dropdown-item" routerLink="#">School of Education</a>
    
              <a class="dropdown-item" routerLink="#">School of Social Science, Humanities and Languages</a>
    
              <a class="dropdown-item" routerLink="#">School of Science and Technology</a>
    
            </div>
          </li>

          <li class="nav-item dropdown has-megamenu">
            <a class="nav-link dropdown-toggle ul-2-a" routerLink="#" data-toggle="dropdown"> Program </a>
            <div class="dropdown-menu megamenu">
              <div class="row">
                <div class="col-md-3">
                  <h3>Diploma</h3>
                  <ul>
                    <li><a routerLink="#">Diploma in Computer Science and Application (DCSA)</a></li>
                  </ul>
                </div>
                <div class="col-md-3">
                  <h3>Under Graduate</h3>
                  <ul>
                    <li><a routerLink="#">B. Sc in Nursing (BSN)</a></li>
                    <li><a routerLink="#">Bachelor of Science in Computer Science and Engineering (B.Sc in CSE)</a></li>
                    <li><a routerLink="#">B.Sc (Hons) in Food Science and NutritionB.Sc (Hons) in Food Science and Nutrition</a>
                    </li>
                  </ul>
                </div>
                <div class="col-md-3">
                  <h3>Graduate</h3>
                  <ul>
                    <li><a routerLink="#">Master of Disability Management and Rehabilitation (MDMR)</a></li>
                    <li><a routerLink="#">Master of Public Health</a></li>
                  </ul>
                </div>
                <div class="col-md-3">
                  <h3>Post Graduate</h3>
                  <ul>
                    <li><a routerLink="#">Post Graduate Diploma in Medical Ultrasound (PGDMU)</a></li>
                  </ul>
                </div>
              </div>
            </div> <!-- dropdown-mega-menu.// -->
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle ul-2-a" routerLink="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              News/Notice
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/notice/admission-notice">Admission Notice</a>
    
              <a class="dropdown-item" routerLink="/notice/registration-class-notice">Registration/Class Notice</a>
    
              <!-- <a class="dropdown-item" routerLink="#">Class Notice</a> -->
    
              <a class="dropdown-item" routerLink="/notice/examination-notice">Examination Notice</a>
    
              <a class="dropdown-item" routerLink="/notice/result-notice">Result Notice</a>
    
              <a class="dropdown-item" routerLink="/notice/general-notice">General Notice</a>
    
              <a class="dropdown-item" routerLink="/notice/employment-notice">Employment Notice</a>
    
            </div>
          </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle ul-2-a" routerLink="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            Resources
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" routerLink="#">About SST</a>
  
            <a class="dropdown-item" routerLink="#">Program</a>
  
            <a class="dropdown-item" routerLink="#">Faculty Member</a>
  
            <a class="dropdown-item" routerLink="#">Gallery</a>
  
            <a class="dropdown-item" routerLink="#">Contact Us</a>
  
          </div>
        </li>

        <li class="nav-item dropdown has-megamenu">
          <a class="nav-link dropdown-toggle ul-2-a" routerLink="#" data-toggle="dropdown"> Admission </a>
          <div class="dropdown-menu megamenu">
            <div class="row">
              <div class="col-md-3">
                <h3>Under Graduate</h3>
                <ul>
                  <li><a routerLink="/application-form">Bachelor of Science in Computer Science and Engineering (B.Sc in
                      CSE)</a></li>
                  <li><a routerLink="/FSN-application-form">B.Sc (Hons) in Food Science and NutritionB.Sc (Hons) in Food
                      Science and Nutrition</a>
                  </li>
                </ul>
              </div>
              <div class="col-md-3">
                <h3>Graduate</h3>
                <ul>
                  <li><a routerLink="/application-form">Master of Disability Management and Rehabilitation (MDMR)</a></li>
                  <li><a routerLink="/application-form">Master of Public Health</a></li>
                </ul>
              </div>
              <div class="col-md-3">
                <h3>Post Graduate</h3>
                <ul>
                  <li><a routerLink="/application-form">Post Graduate Diploma in Medical Ultrasound (PGDMU)</a></li>
                </ul>
              </div>
            </div>
          </div> <!-- dropdown-mega-menu.// -->
        </li>
  
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle ul-2-a" routerLink="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            RCs/SRCs
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" routerLink="#">Barisal</a>
  
            <a class="dropdown-item" routerLink="#">Bogra</a>
  
            <a class="dropdown-item" routerLink="#">Chittagong</a>
  
            <a class="dropdown-item" routerLink="#">Comilla</a>
  
            <a class="dropdown-item" routerLink="#">Dhaka</a>
  
            <a class="dropdown-item" routerLink="#">Faridpur</a>
  
            <a class="dropdown-item" routerLink="#">Jessore</a>
  
            <a class="dropdown-item" routerLink="#">Khulna</a>
  
            <a class="dropdown-item" routerLink="#">Mymensingh</a>
  
            <a class="dropdown-item" routerLink="#">Rajshahi</a>
  
            <a class="dropdown-item" routerLink="#">Rangpur</a>
  
            <a class="dropdown-item" routerLink="#">Sylhet</a>
  
          </div>
        </li>
      </ul>
    </div> <!-- navbar-collapse.// -->
  </nav>
<app-navbar></app-navbar>
<mat-card>
    <div class="wrapper">
        <h1>Academic Calender</h1>
      </div>
    <hr>
    <div class="table-responsive">
        <table class="registration-form-table">
            <tr>
                <th width="70">SL.</th>
                <th>ACADEMIC CALENDER</th>
                <th>View / Download</th>
            </tr>
            <tr *ngFor="let form of AcademicCalendar; index as i">
                <td class="text-center">{{i+1}}</td>
                <!-- <td (click)="viewPDF(form.documentFile)"><img src="https://img.icons8.com/cute-clipart/64/000000/pdf.png"/>{{form.programName}}</td>
                <td><button class="btn btn-success" (click)="downloadPDF(form.documentFile)">Download</button></td> 
                <td class="text-center"><button  (click)="viewPDF(form?.documentFile)"><img src="../../../../assets/icon/pdf.png"/></button> </td>-->
                <td class="text-center"><button  (click)="downloadPDF(form?.documentFile)"><img src="../../../../assets/icon/pdf.png"/></button> </td>
            </tr>

        </table>
    </div>
</mat-card>
<div style="margin-top: 200px;"></div>
<app-footer></app-footer>
<app-navbar></app-navbar>
<mat-card>
    <div class="wrapper">
        <h1>Assignments</h1>
      </div>
    <div class="table-responsive">
        <table class="class-routine-table">
            <tr>
                <th>Date</th> 
                <th>Title</th>
                <th>Program Name</th>
                <th colspan="2">View / Download</th>
            </tr>
           
            <tr *ngFor="let doc of assignments; index as i">
                <td class="text-center">{{doc?.documentSubmissionDate | date}}</td>
                <td class="text-center">{{doc?.documentFirstName}}</td>
                <td class="text-center">{{doc?.programName}}</td>
                <!-- <td class="text-center"><button  (click)="viewPDF(routine?.routineFile)" matTooltip="View PDF"><img src="../../../../assets/icon/pdf.png"/></button> </td> -->
                <td class="text-center"><button  (click)="downloadPDF(routine?.routineFile)" matTooltip="View PDF"><img src="../../../../assets/icon/pdf.png"/></button> </td>

            </tr> 

        </table>
    </div>

</mat-card>
<br>
<br>
<br>
<br>
<app-footer></app-footer>
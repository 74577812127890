<header>

  <!-- Navbar -->
  <nav class="navbar fixed-top navbar-expand-lg navbar-light scrolling-navbar">
    <div class="container-fluid">
      <!-- Brand -->
      <a class="navbar-brand waves-effect" routerLink="/dashboard" target="_blank">
        <img src="../../../../assets/home/sstName.png" class="img-fluid hideLogoBigScreen" alt="SST">
      </a>

      <!-- Collapse -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Links -->
      <div class="collapse navbar-collapse" id="navbarSupportedContent">

        <!-- Left -->
        <ul class="navbar-nav mr-auto hidingul">
          <li class="nav-item">
            <a class="nav-link waves-effect white-text" routerLink="/dashboard">Dashboard
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link waves-effect white-text" routerLink="/complains">Feedback</a>
          </li>
          <li class="nav-item">
            <a class="nav-link waves-effect white-text" routerLink="/admin/contact-us-message">Contact Message</a>
          </li>
          <li class="nav-item">
            <a class="nav-link waves-effect white-text" routerLink="/admin/faculty-members">Faculty Members</a>
          </li>
          <li class="nav-item dropdown" id="paddingRemove">
            <a class="nav-link waves-effect dropdown-toggle white-text" routerLink="#" id="navbarDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Notice
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/admin/notice/view">All Notice</a>
              <a class="dropdown-item" routerLink="/admin/notice/create">Create Notice</a>
            </div>
          </li>
          <li class="nav-item dropdown" id="paddingRemove">
            <a class="nav-link waves-effect dropdown-toggle white-text" routerLink="#" id="navbarDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Documents
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/admin/doc/view">All Documents</a>
              <a class="dropdown-item" routerLink="/admin/doc/create">Create Documents</a>
            </div>
          </li>
          <li class="nav-item dropdown" id="paddingRemove">
            <a class="nav-link waves-effect dropdown-toggle white-text" routerLink="#" id="navbarDropdown" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Routines
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/admin/routine/view">All Routines</a>
              <a class="dropdown-item" routerLink="/admin/routine/create">Create Routine</a>
            </div>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/admin/list">All Admin</a>
            </div>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" routerLink="/admin/gallery-image/list">Gallery</a>
            </div>
          </li>

        </ul>
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <button class="nav-link waves-effect logout-btn" (click)="logOut()">Log Out <i class="fa fa-sign-out"
                data-toggle="tooltip" data-placement="bottom" title="Logout"></i> </button>
          </li>
        </ul>

      </div>

    </div>
  </nav>
  <!-- Navbar -->

  <!-- Sidebar -->
  <div class="sidebar-fixed position-fixed">

    <a class="logo-wrapper waves-effect">
      <img src="../../../../assets/home/sstName.png" class="img-fluid hideLogoSmallScreen" alt="">
    </a>

    <div class="list-group list-group-flush sidelistContainer">
      <a routerLink="/dashboard" class="list-group-item list-group-item-action waves-effect">
        <img class="mr-3" src="../../../../assets/icon/dashboard.png" />Dashboard
      </a>
      <a routerLink="/complains" class="list-group-item list-group-item-action waves-effect">
        <img class="mr-3" src="../../../../assets/icon/complains.png" />Feedback</a>
      <a routerLink="/admin/contact-us-message" class="list-group-item list-group-item-action waves-effect">
        <img class="mr-3" src="../../../../assets/icon/contact.png" />Contact Message</a>
      <a routerLink="/admin/faculty-members" class="list-group-item list-group-item-action waves-effect">
        <img class="mr-3" src="../../../../assets/icon/faculty.png" />Faculty Member</a>
      <a routerLink="/admin/notice/view" class="list-group-item list-group-item-action waves-effect">
        <img class="mr-3" src="../../../../assets/icon/notice.png" />Notice</a>
      <a routerLink="/admin/doc/view" class="list-group-item list-group-item-action waves-effect">
        <img class="mr-3" src="../../../../assets/icon/document.png" />Documents</a>
      <a routerLink="/admin/routine/view" class="list-group-item list-group-item-action waves-effect">
        <img class="mr-3" src="../../../../assets/icon/routine.png" />Routines</a>

      <a routerLink="/admin/list" class="list-group-item list-group-item-action waves-effect">
        <img class="mr-3" src="../../../../assets/icon/admin.png" />Admin</a>
      <a routerLink="/admin/gallery-image/list" class="list-group-item list-group-item-action waves-effect">
        <img class="mr-3" src="../../../../assets/icon/gellary.png" />Gallery</a>

      <!-- <div class="dropdown list-group-item list-group-item-action waves-effect">
        <a class="dropbtn"><img class="mr-3"
            src="../../../../assets/icon/notice.png"/>Notice</a>
        <div class="dropdown-content">
          <a class="list-group-item list-group-item-action waves-effect" routerLink="/admin/notice/view">All Notice</a>
          <a class="list-group-item list-group-item-action waves-effect" routerLink="/admin/notice/create">Creat
            Notice</a>
        </div>
      </div>

      <div class="dropdown list-group-item list-group-item-action waves-effect">
        <a class="dropbtn"><img class="mr-3"
            src="../../../../assets/icon/document.png" />Documents</a>
        <div class="dropdown-content">
          <a class="list-group-item list-group-item-action waves-effect" routerLink="/admin/doc/view">All Documents</a>
          <a class="list-group-item list-group-item-action waves-effect" routerLink="/admin/doc/create">Create
            Document</a>
        </div>
      </div>

      <div class="dropdown list-group-item list-group-item-action waves-effect">
        <a class="dropbtn"><img class="mr-3" src="../../../../assets/icon/routine.png" />Routine</a>
        <div class="dropdown-content">
          <a class="list-group-item list-group-item-action waves-effect" routerLink="/admin/routine/view">All
            Routine</a>
          <a class="list-group-item list-group-item-action waves-effect" routerLink="/admin/routine/create">Create
            Routine</a>
        </div>
      </div> -->
    </div>

  </div>
  <!-- Sidebar -->

</header>
<!--Main Navigation-->

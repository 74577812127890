<app-navbar></app-navbar>

<div class="container">
    <div class="row">
        <div class="col-sm"></div>

        <div class="col-sm">
            <mat-card class="border">
                <img class="image" mat-card-image src="../../../assets/face.png">
                <mat-card-title class="text-center">Success!</mat-card-title>
                <mat-card-content class="text-center">Your Payment has been successed</mat-card-content>
                <mat-card-actions class="text-center" routerLink="/home"><button class="btn-text-center"
                        mat-raised-button>Got it!</button>
                </mat-card-actions>
            </mat-card>
        </div>

        <div class="col-sm"></div>
    </div>

</div>
<br>
<br>
<br>
<br>
<br>
<app-footer></app-footer>
<app-admin-navbar></app-admin-navbar>
<main class="left-side">
    <section>
        <mat-card>

            <div class="table-3 table-responsive">
                <h1>Contact List</h1>
                <table class="table table-bordered table-hover table-stripped">
                        <tr *ngFor="let contact of contactList; index as i" >
                        <td class="text-center" (click)="viewContact(contact._id)">{{contact.contactSubmissionDate | date}}</td>
                        <td (click)="viewContact(contact._id)">{{contact.name}}</td>
                        <td (click)="viewContact(contact._id)">{{contact.email}}</td>
                        <td><a type="button" 
                            (click)="makeDelete(contact._id)" matTooltip="Delete"><img src="../../../assets/icon/bin.png"/></a></td>
                       </tr>
                </table>
            </div>
        </mat-card>
    </section>
</main>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admit-card',
  templateUrl: './admit-card.component.html',
  styleUrls: ['./admit-card.component.scss']
})
export class AdmitCardComponent implements OnInit {
url = ''
  constructor() { }

  ngOnInit(): void {
  }

}

<app-navbar></app-navbar>
<div class="container-div">
    <h1 style="padding-top: 20px;">Email us with any questions or inquiries. We would be happy to answer your question.</h1>
    <div class="form-container">
        <div class="form-div">
            <mat-card>
                <form class="form" [formGroup]="contact_us_form">
            
                    <h1>Contact Us</h1>
            
                    <mat-form-field appearance="fill">
                        <mat-label>Name </mat-label>
                        <input matInput formControlName="name" required>
                    </mat-form-field>
            
                    <mat-form-field appearance="fill">
                        <mat-label>Email </mat-label>
                        <input matInput formControlName="email" required>
                    </mat-form-field>
            
                    <mat-form-field appearance="outline">
                        <mat-label>Your Massage</mat-label>
                        <textarea matInput placeholder="Complaint massage...." formControlName="messageBody"></textarea>
                    </mat-form-field>
                    <button [disabled]="contact_us_form.invalid" (click)="sendComplaint()">Send</button>             
                </form>
            </mat-card>
        </div>
    </div>

</div>

<div class="footer-div">
    <app-footer></app-footer>
</div>

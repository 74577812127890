import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alumni-dept-list',
  templateUrl: './alumni-dept-list.component.html',
  styleUrls: ['./alumni-dept-list.component.scss']
})
export class AlumniDeptListComponent implements OnInit {

  deptNameList = [
    {
      name: "Diploma",
      dept_list: [
        { name: 'Diploma in Computer Science and Application Program (DCSA)' }
      ]
    },
    {
      name: "Under Graduate",
      dept_list: [
        { name: 'B.Sc in Computer Science and Engineering' },
        { name: 'B.Sc.in Nursing' },
        { name: 'B.Sc (Hons) in Food Science and Nutrition' }
      ]
    },
    {
      name: "Graduate",
      dept_list: [
        { name: 'Master of Disability Management and Rehabilitation (MDMR)' },
        { name: 'Master of Public Health (MPH)' }
      ]
    },
    {
      name: "Post-Graduate",
      dept_list: [
        { name: 'Post Graduate Diploma in Medical Ultrasound (PGDMU)' }
      ]
    }

  ]

  dept_list = ['Diploma in Computer Science and Application Program (DCSA)', 'B.Sc in Computer Science and Engineering', 'B.Sc.in Nursing', 'B.Sc (Hons) in Food Science and Nutrition', 'Master of Disability Management and Rehabilitation (MDMR)', 'Master of Public Health (MPH)', 'Post Graduate Diploma in Medical Ultrasound (PGDMU)']
  constructor(public router: Router) { }

  ngOnInit(): void {

  }
  goToDept(dept) {
    console.log(dept)
    this.router.navigate(['/alumni-dept-batch-list', dept]);
  }

}

<app-navbar></app-navbar>
<div class="container-fluid">
  <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-ride="carousel" data-interval="2000">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
      <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="../../../assets/gallery/carousel-images/image1.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../../../assets/gallery/carousel-images/image2.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../../../assets/gallery/carousel-images/image3.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../../../assets/gallery/carousel-images/image4.jpg" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item">
        <img src="../../../assets/gallery/carousel-images/image5.jpg" class="d-block w-100" alt="...">
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
  <div class="photo-gallery">
    <div class="wrapper">
      <h1>photo-gallery</h1>
    </div>
    <div class="gallery-container">
      <div class="normal"><img src="../../../assets/gallery/gallery-images/image11.jpg" alt=""></div>
      <div class="big"><img src="../../../assets/gallery/gallery-images/image12.jpg" alt=""></div>
      <div class="normal"><img src="../../../assets/gallery/gallery-images/image13.jpg" alt=""></div>
      <div class="horizontal"><img src="../../../assets/gallery/gallery-images/image14.jpg" alt=""></div>
      <div class="normal"><img src="../../../assets/gallery/gallery-images/image15.jpg" alt=""></div>
      <div class="big"><img src="../../../assets/gallery/gallery-images/image16.jpg" alt=""></div>
      <div class="normal"><img src="../../../assets/gallery/gallery-images/image17.jpg" alt=""></div>
      <div class="vertical"><img src="../../../assets/gallery/gallery-images/image18.jpg" alt=""></div>
      <div class="normal"><img src="../../../assets/gallery/gallery-images/image1.jpg" alt=""></div>
      <div class="vertical"><img src="../../../assets/gallery/gallery-images/image2.jpg" alt=""></div>
      <div class="horizontal"><img src="../../../assets/gallery/gallery-images/image3.jpg" alt=""></div>
      <div class="normal"><img src="../../../assets/gallery/gallery-images/image4.jpg" alt=""></div>
      <div class="normal"><img src="../../../assets/gallery/gallery-images/image5.jpg" alt=""></div>
      <div class="big"><img src="../../../assets/gallery/gallery-images/image6.jpg" alt=""></div>
      <div class="normal"><img src="../../../assets/gallery/gallery-images/image7.jpg" alt=""></div>
      <div class="vertical"><img src="../../../assets/gallery/gallery-images/image8.jpg" alt=""></div>
      <div class="normal"><img src="../../../assets/gallery/gallery-images/image9.jpg" alt=""></div>
      <div class="horizontal"><img src="../../../assets/gallery/gallery-images/image10.jpg" alt=""></div>

      <div class="normal"><img src="../../../assets/gallery/gallery-images/image19.jpg" alt=""></div>
      <div class="big"><img src="../../../assets/gallery/gallery-images/image20.jpg" alt=""></div>
      <div class="normal"><img src="../../../assets/gallery/gallery-images/image21.jpg" alt=""></div>
      <div class="horizontal"><img src="../../../assets/gallery/gallery-images/image22.jpg" alt=""></div>
      <div class="normal"><img src="../../../assets/gallery/gallery-images/image23.jpg" alt=""></div>
    </div>
  </div>
</div>
<app-footer></app-footer>

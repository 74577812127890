<app-navbar></app-navbar>
<mat-card>
    <mat-tab-group>
        <mat-tab label="Nursing"> 
            <img style="margin: 20px;" src="../../../../../assets/program_details/image/bsc_nursing.svg" alt="">
    <p class="firstTabPara">
        The School of Science and Technology of Bangladesh Open University is offering a post-basic B.Sc.-in-Nursing
        program to upgrade the existing educational level of the diploma holder nurses in the country. The main
        objective of this program is to provide higher level of professional education and training to the nurses in
        order to increase the effectiveness of human resource in the health sector.
    </p>
    <h2>Program Aims:</h2>
    <ul class="firstTabUl">
        <li><i class="fa fa-pagelines" aria-hidden="true"></i> To provide higher educational opportunities to diploma holder nurses</li>
        <li><i class="fa fa-pagelines" aria-hidden="true"></i> To develop clinical competence for quality assurance of nursing care services.</li>
        <li><i class="fa fa-pagelines" aria-hidden="true"></i> To develop teaching, management and research skills and abilities.</li>
        <li><i class="fa fa-pagelines" aria-hidden="true"></i> To provide opportunities for personal and professional growth.</li>
        <li><i class="fa fa-pagelines" aria-hidden="true"></i> To improve the professional standing of the nurses in Bangladesh.</li>
    </ul>
</mat-tab>
<mat-tab label="Course Details"> 
    <div class="table-responsive">
        <table id="dcsa-table">
            <tr>
                <th>Level</th>
                <th>Graduation</th>
            </tr>
            <tr>
                <td>Entry Criteria</td>
                <td>Diploma in Nursing and Midwifery with 3 years Professional Experiences</td>
            </tr>
            <tr>
                <td>Semester</td>
                <td>06</td>
            </tr>
            <tr>
                <td>Semester Duration</td>
                <td>6 months</td>
            </tr>
            <tr>
                <td>Class Duration</td>
                <td>5 months</td>
            </tr>
            <tr>
                <td>Total Credit Hours</td>
                <td>100 hours</td>
            </tr>
            <tr>
                <td>Minimum Duration</td>
                <td>3 years</td>
            </tr>
            <tr>
                <td>Maximum Duration</td>
                <td>6 years</td>
            </tr>
            <tr>
                <td>1st Admission Time</td>
                <td>December</td>
            </tr>
            <tr>
                <td>Class Begins</td>
                <td>January</td>
            </tr>
            <tr>
                <td>Course Fees (Each Cources)</td>
                <td>TK. 870.00</td>
            </tr>
            <tr>
                <td>Registration Fee</td>
                <td>Tk. 100.00</td>
            </tr>
            <tr>
                <td>Re-exam fees (Per course)</td>
                <td>Tk. 300.00</td>
            </tr>
            <tr>
                <td>Admission guide with form</td>
                <td>Tk. 100.00</td>
            </tr>
        </table>
    </div>
    <p class="secondTabPara" style="margin: 30px; color: rgb(2, 2, 107);">
        B. Sc.-in-Nursing program is a 100 credits program having 6 Semesters with 15 theoretical and 11 practical
        courses. This program has been launched at April 2003 at 13 tutorial centers of 13 Medical College and Hospital
        attached Nursing Institute of Bangladesh.
    </p>
    <div class="table-responsive">
        <table id="dcsa-table2">
            <tr>
                <th>Course Title</th>
                <th>Credits</th>
            </tr>
            <tr>
                <td>Communicative English</td>
                <td>Credits</td>
            </tr>
            <tr>
                <td>Applied Science-I</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>Practical Applied Science-I</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>Behavioral Science-I</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>English for Nurses</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>Applied Science-II</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>Practical applied Science-II</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>Nutrition and Dietetics</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>Practical Nutrition and Dietetics</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>Behavioral Science-II</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>Medical Surgical Nursing</td>
                <td class="text-center">4</td>
            </tr>
            <tr>
                <td>Practical Medical Surgical Nursing</td>
                <td class="text-center">8</td>
            </tr>
            <tr>
                <td>Psychiatry & Mental Health Nursing</td>
                <td class="text-center">2</td>
            </tr>
            <tr>
                <td>Practical Psychiatry & Mental Health Nursing</td>
                <td class="text-center">2</td>
            </tr>
            <tr>
                <td>Community Health Nursing</td>
                <td class="text-center">6</td>
            </tr>
            <tr>
                <td>Practical Community Health Nursing</td>
                <td class="text-center">6</td>
            </tr>
            <tr>
                <td>Maternal and Child Health Nursing</td>
                <td class="text-center">4</td>
            </tr>
            <tr>
                <td>Practical Maternal and Child Health Nursing</td>
                <td class="text-center">8</td>
            </tr>
            <tr>
                <td>Nursing Education</td>
                <td class="text-center">4</td>
            </tr>
            <tr>
                <td>Practical Nursing Education</td>
                <td class="text-center">8</td>
            </tr>
            <tr>
                <td>Introduction to Nursing Research & Statistics</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>Practical Nursing Research & Statistics</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>Nursing Administration & Management</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>Practical Nursing Administration & Management</td>
                <td class="text-center">6</td>
            </tr>
            <tr>
                <td>Project in Senior focus Elective</td>
                <td class="text-center">3</td>
            </tr>
            <tr>
                <td>Total</td>
                <td class="text-center">100</td>
            </tr>
        </table>
    </div>
    </mat-tab>
    <mat-tab label="Eligibility"> 
        <h2>Details will be coming soon</h2>
</mat-tab>
<mat-tab label="Evaluation"> 
    <h2>Details will be coming soon</h2>
</mat-tab>
<mat-tab label="Academic Co-ordinator"> 
    <h2>Details will be coming soon</h2>
</mat-tab>
</mat-tab-group>
</mat-card>

<app-footer></app-footer>
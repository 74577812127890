import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  baseURL: string = environment.API;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  editDocumentData: any;
  pdfURL: string = '';
  constructor(private http: HttpClient) { }

  createNewDocument(document: any) {
    return this.http.post<any>(`${this.baseURL}/api/document/new`, document)
  }
  editDocument(document: any, id: string) {
    return this.http.put<any>(`${this.baseURL}/api/document/update/${id}`, document)
  }
  disableDocument(document: any, id: string) {
    return this.http.put<any>(`${this.baseURL}/api/document/disable/${id}`, document)
  }
  deleteDocument(id: string) {
    return this.http.delete<any>(`${this.baseURL}/api/document/delete/${id}`)
  }
  getAllDocument() {
    return this.http.get<any>(`${this.baseURL}/api/document/list`, { headers: this.headers })
  }
  getDocument(documentCategory) {
    return this.http.get<any>(`${this.baseURL}/api/document/list/${documentCategory}`, { headers: this.headers })
  }
  getAllImage() {
    return this.http.get<any>(`${this.baseURL}/api/gallery/list`, { headers: this.headers })
  }
  createNewImage(image: any) {
    return this.http.post<any>(`${this.baseURL}/api/gallery/new`, image)
  }
}

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FacultyMemberService {

  baseURL: string = environment.API;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  updateFacultyMemberData: any;
  pdfURL: string = '';
  constructor(private http: HttpClient) { }

  createNewMember(facultyMember: any) {
    return this.http.post<any>(`${this.baseURL}/api/faculty-member/add-new-member`, facultyMember)
  }
  makeDean(facultyMember: any) {
    return this.http.post<any>(`${this.baseURL}/api/dean/new`, facultyMember)
  }
  viewDean() {
    return this.http.get<any>(`${this.baseURL}/api/dean/view`, { headers: this.headers })
  }
  getDean(id) {
    return this.http.get<any>(`${this.baseURL}/api/dean/viewById/${id}`, { headers: this.headers })
  }
  updateDean(dean: any, id: string) {
    return this.http.put<any>(`${this.baseURL}/api/dean/update/${id}`, dean)
  }
  editMember(facultyMember: any, id: string) {
    return this.http.put<any>(`${this.baseURL}/api/faculty-member/update/${id}`, facultyMember)
  }
  disableMember(facultyMember: any, id: string) {
    return this.http.put<any>(`${this.baseURL}/api/faculty-member/disable/${id}`, facultyMember)
  }
  getAllMember() {
    return this.http.get<any>(`${this.baseURL}/api/faculty-member/all-member`, { headers: this.headers })
  }
  getMember(id: string) {
    return this.http.get<any>(`${this.baseURL}/api/faculty-member/view/${id}`, { headers: this.headers })
  }
  deleteMember(id: string) {
    return this.http.delete<any>(`${this.baseURL}/api/faculty-member/delete-member/${id}`, { headers: this.headers })
  }
}

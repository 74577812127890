import { Component, OnInit } from '@angular/core';
import { ContactUsService } from './../../../app/services/contact-us.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-us-message',
  templateUrl: './contact-us-message.component.html',
  styleUrls: ['./contact-us-message.component.scss']
})
export class ContactUsMessageComponent implements OnInit {

  constructor(private ContactUsService: ContactUsService, private router: Router) { }
  contactList
  ngOnInit(): void {
    this.ContactUsService.getAllContact()
      .subscribe((res) => { 
        console.log(res)
          this.contactList = res
      })
  }
  viewContact(id: string) {
    this.router.navigate([`/admin/view-message/${id}`])
  }

  makeDelete(id: string) {
    let data = {
      isDeleted: true
    }
    this.ContactUsService.deleteContactById(id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("success")
          window.location.reload()
        }
      })
  }

}

<app-navbar></app-navbar>
<div class="container">
    <mat-card>

        <h2>Enter your Complain ID</h2> 

        <mat-card-content>
            <mat-form-field appearance="fill">
                <mat-label>Complain ID </mat-label>
                <input matInput [(ngModel)]="complaintID" required>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
            <button class="btn btn-primary" (click)="getStatus()">Get Status</button>
        </mat-card-actions>
    </mat-card>
    <div *ngIf="complaintData">
        <mat-card>
            <h1>Your Complain</h1>
            <mat-card-header>
                <mat-card-title>
                    <h3>Complain ID :
                        {{complaintData?.complaintID}}</h3>
                    <h3>Student ID :
                        {{complaintData?.studentID}}</h3>
                    <h3>Student Name : {{complaintData?.studentName}}</h3>
                    <h3>Student Email : {{complaintData?.studentEmail}}</h3>

                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <h2>Message : {{complaintData?.topicName}} </h2>
                <p>
                    {{complaintData?.messageBody}}
                </p>
                <div *ngIf="complaintData?.messageFile != ''">
                    <button class="btn btn-success" (click)='viewPdf(complaintData?.messageFile)'> View PDF</button>

                    <button class="btn btn-primary" (click)='downloadPdf(complaintData?.messageFile)'> Download
                        PDF</button>
                </div>
            </mat-card-content>

        </mat-card>

        <mat-card *ngIf="complaintData?.messageReply.length>0">
            <h1>Reply From University</h1>
            <table class="table table-hover">
                <tbody>
                    <tr *ngFor="let reply of complaintData?.messageReply; index as i">
                        <th scope="col">{{i+1}}</th>
                        <th scope="col">Reply Massage</th>
                        <td scope="col">{{reply?.replyBody}}</td>
                        <td *ngIf="reply.replyFile != ''" scope="col"><a href="{{reply?.replyFile}}">Attachment</a></td>
                    </tr>
                </tbody>
            </table>
        </mat-card>
    </div>


</div>

<app-footer></app-footer>
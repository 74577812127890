import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AlumniService {

  baseURL: string = environment.API;
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  createNewMember(alumniMember: any) {
    return this.http.post<any>(`${this.baseURL}/api/alumni/application`, alumniMember)
  }
  getAllMember() {
    return this.http.get<any>(`${this.baseURL}/api/alumni/list`, { headers: this.headers })
  }
  getAllMemberByDept(dept: any, batch: any) {
    return this.http.get<any>(`${this.baseURL}/api/alumni/list/${dept}/${batch}`, { headers: this.headers })
  }
  editMember(facultyMember: any, id: string) {
    return this.http.put<any>(`${this.baseURL}/api/alumni/update/${id}`, facultyMember)
  }
  disableMember(facultyMember: any, id: string) {
    return this.http.put<any>(`${this.baseURL}/api/alumni/disable/${id}`, facultyMember)
  }
  getMember(id: string) {
    return this.http.get<any>(`${this.baseURL}/api/alumni/view/${id}`, { headers: this.headers })
  }
  deleteMember(id: string) {
    return this.http.delete<any>(`${this.baseURL}/api/alumni/delete-member/${id}`, { headers: this.headers })
  }
}

<app-admin-navbar></app-admin-navbar>
<main class="pt-5 mx-lg-5">
  <div class="container-fluid mt-5">
    <mat-card>
      <h3>Create New Admin</h3>
      <div class="form-1">
        <form [formGroup]="createAdminForm">


          <mat-form-field appearance="fill">
            <mat-label>Admin Email</mat-label>
            <input name="email" matInput placeholder="Email" formControlName="email" required>

          </mat-form-field>

          <mat-form-field class="full-width-input" appearance="fill">
            <mat-label>Password</mat-label>
            <input name="password" matInput [type]="hide ? 'text' : 'password'" placeholder="Password"
              formControlName="password" required>
            <mat-icon matSuffix (click)="hide = !hide" class="eye-icon">{{ hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>

          </mat-form-field>

        </form>
        <button [disabled]="!this.createAdminForm.valid" mat-raised-button (click)="createAdmin()">Create</button>
      </div>
    </mat-card>
  </div>
</main>

import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-messages-from-teachers',
  templateUrl: './messages-from-teachers.component.html',
  styleUrls: ['./messages-from-teachers.component.scss']
})
export class MessagesFromTeachersComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute) { }
  private scrollExecuted: boolean = false;
  ngOnInit(): void {
  }

  ngAfterViewChecked() {

    if (!this.scrollExecuted) {
      let routeFragmentSubscription: Subscription;

      // Automatic scroll
      routeFragmentSubscription =
        this.activatedRoute.fragment
          .subscribe(fragment => {
            if (fragment) {
              let element = document.getElementById(fragment);
              if (element) {
                element.scrollIntoView();

                this.scrollExecuted = true;

                // Free resources
                setTimeout(
                  () => {
                    console.log('routeFragmentSubscription unsubscribe');
                    routeFragmentSubscription.unsubscribe();
                  }, 1000);

              }
            }
          })
    }
  }

}

<app-navbar></app-navbar>
<!-- <mat-card>
  <div class="wrapper">
    <h1>E-Book</h1>
  </div><hr>
<div class="flex-container">
<div *ngFor='let guide of AcademicBook; index as i' class="book-cntainer-card">
    <h3>SL. No. {{i+1}}</h3>
    <div class="book-cntainer">
        <div class="book">
          <div class="cover"></div>
          <h2>{{guide.documentFirstName}}<span> {{guide.documentLastName}}</span></h2>
          <span class="writer"><i>Study Guides</i></span>
        </div>
      </div>
      <p><b>Program Name:</b> {{guide.programName}}</p>
      <div class="btn-container">
          <button class="btn btn-primary" (click)="viewPDF(guide.documentFile)">View PDF</button>
          <button class="btn btn-success" (click)="downloadPDF(guide.documentFile)">Download PDF</button>
      </div>
</div>
</div>
</mat-card> -->
<div class="container">
  <div class="wrapper">
      <h1>E-Book</h1>
    </div><hr>
<div class="flex-container">
  <mat-card>
    <a href="http://www.ebookbou.edu.bd/dcsa.php">
    <h3 class="heading3">Diploma in Computer Science & Application (DCSA)</h3>
    <mat-icon>developer_board</mat-icon>
    </a>
</mat-card>

<mat-card>
  <a>
  <h3 class="heading3">B.Sc.in Computer Science & Engineering (upcoming..)</h3>
  <mat-icon>cast_for_education</mat-icon>
  </a>
</mat-card>

  <mat-card>
      <a href="http://www.ebookbou.edu.bd/bsn.php">
      <h3 class="heading3">B.Sc.in-Nursing (BSN)</h3>
      <mat-icon>local_hospital</mat-icon>
      </a>
  </mat-card>

  <mat-card>
      <a href="http://www.ebookbou.edu.bd/mdmr.php">
      <h3 class="heading3">Master of Disability Management & Rehabilitation (MDMR)</h3>
      <mat-icon>local_hotel</mat-icon>
      </a>
  </mat-card>
      
</div>
</div>

<app-footer></app-footer>

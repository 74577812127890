<app-navbar></app-navbar>
<mat-card>
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">school</mat-icon>
                &nbsp;&nbsp;DCSA
            </ng-template>

            <img style="margin: 20px;" src="../../../../../assets/program_details/image/computerScience.svg" alt="">
            <p class="firstTabPara">
                School of Science and Technology offers a Diploma in Computer Science and Application program for the
                large number of students who do not avail to study in the conventional education system and who need to
                further study in computer science. The DCSA program aims to make computer professionals meet the recent
                increasing demand in the computer field.
            </p>
            <h2>The objectives of the program are to develop skills in :</h2>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Usage of computer application.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Computer programming as well as software
                    development.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Office automation, desktop publishing, computer
                    networking, multimedia, webpage designing, etc.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Databases and database management systems,
                    computer-aided design and associated packages, etc.</li>
                <li> <i class="fa fa-pagelines" aria-hidden="true"></i> Analyzing digital logic, computer organization,
                    microcomputers, and their maintenance and
                    troubleshooting, etc.</li>

            </ul>

        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">import_contacts</mat-icon>
                &nbsp;&nbsp;Course Details
            </ng-template>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th>Level</th>
                        <th>Graduation</th>
                    </tr>
                    <tr>
                        <td>Entry Criteria</td>
                        <td>HSC or Equivalent</td>
                    </tr>
                    <tr>
                        <td>Semester</td>
                        <td>03</td>
                    </tr>
                    <tr>
                        <td>Semester Duration</td>
                        <td>6 months</td>
                    </tr>
                    <tr>
                        <td>Class Duration</td>
                        <td>5 months</td>
                    </tr>
                    <tr>
                        <td>Total Credit Hours</td>
                        <td>35 hours</td>
                    </tr>
                    <tr>
                        <td>Minimum Duration</td>
                        <td>1.5 years</td>
                    </tr>
                    <tr>
                        <td>Maximum Duration</td>
                        <td>5 years</td>
                    </tr>
                    <tr>
                        <td>Admission Time</td>
                        <td>December</td>
                    </tr>
                    <tr>
                        <td>Class Begins</td>
                        <td>January</td>
                    </tr>
                    <tr>
                        <td>Course Fees (Each Cources)</td>
                        <td>TK. 800.00</td>
                    </tr>
                    <tr>
                        <td>Practical Fees</td>
                        <td>Tk. 350.00</td>
                    </tr>
                    <tr>
                        <td>Re-exam fees (Per course)</td>
                        <td>Tk. 200.00</td>
                    </tr>
                    <tr>
                        <td>Admission guide with form</td>
                        <td>Tk. 100.00</td>
                    </tr>

                </table>

            </div>
            <p class="secondTabPara" style="margin-top: 20px;margin-bottom: 20px; color: rgb(2, 2, 107);">
                DCSA program is a 33 credits program having 3 Semesters with 11 courses and one Project. This program
                has been
                launched in 1998 and at 09 tutorial centers of Public University/Institutions.
            </p>
            <div class="table-responsive">
                <table id="dcsa-table2">
                    <tr>
                        <th>Course Title</th>
                        <th>Credits</th>
                    </tr>
                    <tr>
                        <td>Computer Basics (including designing web page)</td>
                        <td class="text-center">2</td>
                    </tr>
                    <tr>
                        <td>Office Automation and MS Office</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td>Computer Programming (with C)</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td>Visual Programming</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td>Digital Systems and Computer Organizations</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td>Operating Systems</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td>Internet Technology and Web Designing</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td>Database Management System</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td>Graphics Design</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td>Microcomputer Troubleshooting</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td>Computer Networks</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td>Project Work</td>
                        <td class="text-center">3</td>
                    </tr>
                    <tr>
                        <td>Total</td>
                        <td class="text-center">35</td>
                    </tr>
                </table>
            </div>
            <div style="text-align: center; padding-top: 20px;">
                <a href="../../../../../assets/program_details/dcsa_detail_260417.pdf" target="blank">Click here for
                    details</a>
            </div>

        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">verified</mat-icon>
                &nbsp;&nbsp;Eligibility
            </ng-template>
            <p class="thirdTabPara" style="margin-top: 20px;">Candidates for the program will be selected after proper
                scrutiny of
                the application form along with the educational certificates and
                other relevant documents. Regarding admission, BOU’s decision
                will be considered as final. Selected candidates are to get them
                admitted to the program within the stipulated time by paying
                necessary fees to the entitled Bank or mobile banking prescribed by
                the local RC.</p>


            <div class="table-responsive">
                <h1>Selection Criteria</h1>
                <div>
                    <h2>1. Degree</h2>
                    <p class="thirdTabPara">Learners will be selected on the basis of points calculated as
                        follows–</p>
                    <table id="dcsa-table2">

                        <tr>
                            <th>Degree</th>
                            <th>Point
                                <div>
                                    <table id="dcsa-table">
                                        <tr>
                                            <th>1st Division</th>
                                            <th>2nd Division</th>
                                            <th>3rd Division</th>
                                        </tr>
                                    </table>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <td class="text-center">S.S.C</td>
                            <td class="text-center">
                                <div>
                                    <table id="dcsa-table">
                                        <tr>
                                            <td>5</td>
                                            <td>3</td>
                                            <td>X</td>
                                        </tr>
                                    </table>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td class="text-center">H.S.C</td>
                            <td class="text-center">
                                <div>
                                    <table id="dcsa-table">
                                        <tr>
                                            <td>5</td>
                                            <td>3</td>
                                            <td>X</td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center">Higher Degree
                                (Undergraduate/
                                Postgraduate)</td>
                            <td class="text-center">1 Point</td>
                        </tr>

                    </table>
                </div>


            </div>
            <div style="margin-top:20px;text-align: center;" class="table-responsive">
                <table id="dcsa-table2">
                    <tr>
                        <th>Degree</th>
                        <th>GPA 3 & above</th>
                        <th>Below GPA 3 & upto GPA 2.5</th>
                        <th>Below GPA 2.5</th>
                    </tr>
                    <tr>
                        <td>SSC</td>
                        <td>5</td>
                        <td>3</td>
                        <td>X</td>
                    </tr>
                    <tr>
                        <td>HSC</td>
                        <td>5</td>
                        <td>3</td>
                        <td>X</td>
                    </tr>

                </table>

            </div>
            <div style="margin-top: 30px;">
                <h2>2. Age</h2>
                <p class="thirdTabPara">For every 10 years of age of the learner, 1 (One) extra point will be
                    added.</p>
            </div>

        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">military_tech</mat-icon>
                &nbsp;&nbsp;Evaluation
            </ng-template>
            <h2 style="margin-top: 20px;">Examination</h2>
            <p class="fourTabPara" style="margin-left: 20px;">At the end of a semester, learners will have to appear at the
                examination for evaluation. Examination consists of theory and
                practical. Evaluation will be made by the sum of marks obtained in
                theory, practical and TMA of a course (please see evaluation).</p>
            <h2>Attention!</h2>
          
            <ul class="fourTabUl" style="list-style-type:square;margin: 10px;">
                <li>
                    You will be promoted to the next semester and allowed to
                    register if you appear at least one of the examinations
                    mentioned for a semester. But for awarding the degree of
                    diploma you have to complete all the courses successfully.
                </li>
                <li>
                    BOU authority does not consider prayer of changing
                    examination center.
                </li>
                <li>
                    Learners who pass the theoretical part of a course but do not
                    pass the practical one or do not appear at the practical
                    examination or vice versa, will be considered as fail. In that
                    case, students have to reappear at both the theoretical and the
                    practical examinations of that course in order to pass.
                </li>

            </ul>

            <h2>Types of Questions</h2>
            <p class="fourTabPara" style="margin-left: 20px;">All questions will consist of MCQ and essay type (short and
                analytical). A model exercise is given in the last section of the
                lesson for each course. The marks and time distributions are as
                follows -
            </p>
            <div class="table-responsive">
                <table id="dcsa-table2">
                    <tr>
                        <th>Type</th>
                        <th>Marks</th>
                        <th>Time</th>
                        <th>Number of Questions</th>
                    </tr>
                    <tr>
                        <td class="text-center">MCQ</td>
                        <td class="text-center">20</td>
                        <td class="text-center">20 m</td>
                        <td class="text-center">40</td>
                    </tr>
                    <tr>
                        <td class="text-center">Essay type</td>
                        <td class="text-center">50</td>
                        <td class="text-center">2;40 h</td>
                        <td class="text-center">Short: 8 out of 10 Analytical: 5 out of 8</td>
                    </tr>
                    <tr>
                        <td class="text-center">Practical</td>
                        <td class="text-center">20</td>
                        <td class="text-center">30 m (for each course)</td>
                        <td class="text-center">To be informed</td>
                    </tr>
                    <tr>
                        <td class="text-center">TMA (for each courses)</td>
                        <td class="text-center">10</td>
                        <td class="text-center">-</td>
                        <td class="text-center">To be informed</td>
                    </tr>

                </table>          
            </div>
            <div>
                <h2 style="margin-top: 20px;">Distribution of Practical Marks</h2>
                <div class="table-responsive">
                    <table id="dcsa-table2">
                        <tr>
                            <td class="text-center">Experiment</td>
                            <td class="text-center">10 marks</td>
                        </tr>
                        <tr>
                            <td class="text-center">Record book</td>
                            <td class="text-center">5 marks</td>
                        </tr>
                        <tr>
                            <td class="text-center">Viva Voice</td>
                            <td class="text-center">5 marks</td>
                        </tr>

                    </table>
                </div>
            </div>
            <h2 style="margin-top: 20px;">Evaluation of Project</h2>
            <p class="fourTabPara" style="margin-left: 20px;">Total Marks 100 Pass Marks 50 (50%)</p>

            <div>
                <h2>Distribution of Marks</h2>
                <div class="table-responsive">
                    <table id="dcsa-table2">
                        <tr>
                            <td class="text-center">(a) Real life Project report</td>
                            <td class="text-center">80 marks</td>
                        </tr>
                        <tr>
                            <td class="text-center">(b) Viva Voice</td>
                            <td class="text-center">20 marks</td>
                        </tr>
                        <tr>
                            <td style="text-align: center;font-style:italic;">Total</td>
                            <td class="text-center">100 marks</td>
                        </tr>
                    </table>
                </div>
            </div>
            <h2 style="margin-top: 20px;">Evaluation Procedure</h2>
            <p class="fourTabPara">Each course will be evaluated through written test (70%), Practical
                (20%) and TMA (Tutor’s Mark Assignment 10%). The pass marks
                for the practical examinations will be 10 (50% out of the total
                practical marks). To successfully complete a course learner must
                obtain a total of 40 (40% of the total marks) marks out of 100
                including TMA, written test and practical examination. The results
                will be given in accordance with BOU’s existing rules of Grade
                Point Average.</p>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">account_circle</mat-icon>
                &nbsp;&nbsp;Academic Co-ordinator
            </ng-template>
            <div style="margin: 20px;">
                <h1>For more information:</h1>
                <ul class="fifthTabul" style="padding-inline-start: 20px;">
                    <li>Md.Moshiur Rahman</li>
                    <li>Assistant Professor, Computer Science and Engineering</li> 
                    <li>School of Science and Technology</li>
                    <li>Bangladesh Open University</li> 
                    <li>Board Bazar, Gazipur-1705.</li>
                    <li>E-mail: moshiurbou&#64;gmail.com</li>
                    <li>Phone: 09666730730 Ex.845, 01748987282 (cell).</li>
                    
                </ul>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add_alert</mat-icon>
                &nbsp;&nbsp;Notice
            </ng-template>
            <div class="table-container">
                <table class="notice-table">
                    <ng-container *ngFor="let notice of noticeList; index as i">
                        <ng-container *ngIf="!notice?.isDeleted">
                            <ng-container *ngIf="!notice?.isDisabled">
                                
    
                                    <tr>
                                       
                                        <td class="small-cell">
                                            <div class="dateDiv">
                                                <div class="month">{{notice?.noticeSubmissionDate | date :'MMMM'}}</div>
                                                <div class="date">{{notice?.noticeSubmissionDate | date :'d'}}, {{notice?.noticeSubmissionDate | date :'y'}}</div>
                                                <!-- <div class="year"></div> -->
                                            </div>
                                            <div class="half-circle">
                                            </div>                                    
                                        </td> 
                                       
                                         <td class="notice-cell">
                                             <a href="{{notice?.noticeFile}}" target="blank" matTooltip="Click for download">{{notice?.noticeBody}} </a> <app-new-icon [date]="notice?.noticeSubmissionDate"></app-new-icon>
                                            
                                        </td>
                                    </tr>
                                
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card>

<app-footer></app-footer>
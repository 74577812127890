<app-navbar></app-navbar> 
<div id="about-sst"> 
    <img src="../../../assets/aboutSST/aboutSST.jpg" class="mainImage">
    <div class="mainContainer">
        <div class="wrapper">
            <h1>School of Science and Technology</h1>
          </div>
        <div class="flexContainer1">
        <div class="largerFont text-justify">
            School of Science and Technology is one of the six schools of Bangladesh Open University. The main objective
            of this school is to provide a platform for teaching, learning and research in the field of science and
            technology and to create scientific and technically skilled manpower in the country. The extended activities
            of this school are scientific awareness, degree awarding and advance research programs. The ongoing academic
            programs of the school are Diploma in Computer Science and Application (DCSA) and B.Sc.in Nursing. After
            completing both of the program and receiving degree, learners would be become well placed in the society.
        </div>
        <div class="emptyChild"></div>
        </div>
        <div class="flexContainer2">
        <div class="textChild">
        <div class="smallHeading">
            <h1>Vision</h1>
        </div>
        <p class="text-justify">
            To recognize the school of School of Science and Technology of Bangladesh Open University as the leading
            school offering science, technology and health-sciences based education and providing IT's & Health Sector's
            professionals with skills and expertise necessaries to improve the IT & Health status and quality of life in
            developing countries like Bangladesh.
        </p>
        </div>
        <div class="imageDiv">
            <img src="../../../assets/aboutSST/footerimg.jpg" alt="" class="img-fluid">
        </div>
        </div>
        <div class="smallHeading">
            <h1>Mission</h1>
        </div>
        <div class="flexContainer3">
            <div>
                <img src="../../../assets/aboutSST/microscope.png">
                <p>
                    To provide a platform for teaching, learning and research in the field of science and technology and
                    Health sciences
                </p>
            </div>
            <div>
                <img src="../../../assets/aboutSST/statistics.png">
                <p>
                    To create scientific and technically skilled manpower in the country. 
                </p>
            </div>
            <div>
                <img src="../../../assets/aboutSST/idea-sharing.png">
                <p>
                    To create an environment for building up scientific awareness, degree awarding and advance research
                programs.
                </p>
            </div>
            <div>
                <img src="../../../assets/aboutSST/biotech.png">
                <p>
                    Enhance research skill for new insights and innovative solution's to meet various Challenges.  
                </p>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>
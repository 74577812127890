import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { FacultyMemberService } from 'src/app/services/faculty-member.service';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';
import { data } from 'jquery';
import { MatRadioChange } from '@angular/material/radio';
@Component({
  selector: 'app-update-member-info',
  templateUrl: './update-member-info.component.html',
  styleUrls: ['./update-member-info.component.scss']
})
export class UpdateMemberInfoComponent implements OnInit {

  public Editor = ClassicEditor;
  employment_history

  public regionalCenter: any;
  public sscResult: any;
  public hscResult: any;
  public bachelorResult: any;
  public otherResult: any;

  update_faculty_member_form: any;
  updateFacultyMemberFormData: any;

  isDean = false;
  makeNewDean = false;

  deanSelected = true

  makeDeanList = [
    {
      text: "Yes",
      value: true
    },
    {
      text: "No",
      value: false
    }
  ]

  myControl = new FormControl();
  hide = true;
  filteredOptions: Observable<string[]>;
  url: any;
  preview: string;
  constructor(
    private activatedRoute: ActivatedRoute,
    public fb: FormBuilder,
    public router: Router,
    private facultyMemberService: FacultyMemberService
  ) { }

  ngOnInit(): void {

    this.updateFacultyMemberFormData = this.facultyMemberService.updateFacultyMemberData

    console.log(this.updateFacultyMemberFormData)
    if (this.updateFacultyMemberFormData == undefined || this.updateFacultyMemberFormData == null) {
      this.router.navigate(['/admin/faculty-members'])
    }
    this.facultyMemberService.getDean(this.updateFacultyMemberFormData?._id)
      .subscribe((res) => {
        console.log(res)
        if (res.isFound) {
          this.deanSelected = true
          if (res.result == null) {

            this.deanSelected = false
            this.makeNewDean = true
          } else {
            if (res.result.isDean == false) {
              this.deanSelected = false
            }
          }

        }
      }, (error) => {
        console.log(error)
      })

    this.update_faculty_member_form = this.fb.group({
      name: [this.updateFacultyMemberFormData?.name],
      designation: [this.updateFacultyMemberFormData?.designation],
      //subject: [this.updateFacultyMemberFormData?.subject],
      bio: [this.updateFacultyMemberFormData?.bio],
      experience: [this.updateFacultyMemberFormData?.experience],
      email: [this.updateFacultyMemberFormData?.email],
      phoneNumber_residence: [this.updateFacultyMemberFormData?.phoneNumber_residence],
      phoneNumber_personal: [this.updateFacultyMemberFormData?.phoneNumber_personal],
      phoneNumber_office: [this.updateFacultyMemberFormData?.phoneNumber_office],
      ex_number_office: [this.updateFacultyMemberFormData?.ex_number_office],
      fax_number_office: [this.updateFacultyMemberFormData?.fax_number_office],
      days_bou: [this.updateFacultyMemberFormData?.days_bou],
      num_publiction: [this.updateFacultyMemberFormData?.num_publiction],
      employment_history: [this.updateFacultyMemberFormData?.employment_history],
      research_interest: [this.updateFacultyMemberFormData?.research_interest],
      applicantPicture: [this.updateFacultyMemberFormData?.applicantPicture],
      twitter: [this.updateFacultyMemberFormData?.twitter],
      facebook: [this.updateFacultyMemberFormData?.facebook],
      linkedin: [this.updateFacultyMemberFormData?.linkedin],
      // mail: [this.updateFacultyMemberFormData?.mail],
      cvUrl: [this.updateFacultyMemberFormData?.cvUrl],
    })
  }
  updateDean() {

  }

  onSelectFile(event) {
    const file = event.target.files[0];
    const preview = event.target.files;
    if (preview && file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
      }
    }
    this.update_faculty_member_form.patchValue({
      applicantPicture: file
    });

  }

  onSignatureSelectFile(event) {
    console.log('sign')
    const file = event.target.files[0];
    this.update_faculty_member_form.patchValue({
      cvUrl: file
    });

  }
  public onChange({ editor }: ChangeEvent) {
    this.update_faculty_member_form.
      patchValue({ employment_history: editor.getData() })
  }
  updateFacultyMember() {

    console.log(this.update_faculty_member_form.value)
    let form_data = new FormData();

    for (let key in this.update_faculty_member_form.value) {
      form_data.append(key, this.update_faculty_member_form.value[key]);
    }
    this.facultyMemberService.editMember(form_data, this.updateFacultyMemberFormData?._id)
      .subscribe(data => {
        if (data.isUpdate) {
          console.log(data)
          if (this.makeNewDean) {

            let deanInformation = {
              deanId: this.updateFacultyMemberFormData?._id
            }
            this.facultyMemberService.makeDean(deanInformation).subscribe((res) => {
              console.log(res);

            }, (error) => {
              console.log(error);
            })
          } else if (this.makeNewDean == false) {
            let deanInformation = {

              deanAddDate: Date.now(),
              deanId: this.updateFacultyMemberFormData?._id,
              isDean: false
            }
            this.facultyMemberService.updateDean(deanInformation, this.updateFacultyMemberFormData?._id)
              .subscribe((result) => {
                console.log(result)
              }, error => {
                console.log(error)
              })
          }

          this.router.navigateByUrl("/admin/faculty-members")
          // window.location.reload();

        }
      })
  }
}

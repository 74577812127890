<app-navbar></app-navbar>
<mat-card>
    <h1>Research Activities and Publications </h1>
    <p>
        The faculty members of the School of Science and Technology publish their research work every year in renowned
        national and international journals and conference proceedings. The research works are motivated by the academic
        interest and socio-economic development at the national level as well. The school publishes a research journal
        named <a href="https://jstr.bousst.edu.bd/index.php/jstr">“Journal of Scientific and Technological Research (JSTR)”</a> bi-annually. List of research activities of SST
        are given below-
    </p>

      <ol>
        <li>
            Challenges in Providing Support Services to the Distant Learners of the School of Science and Technology at
            Bangladesh Open University.
        </li>
        <li>
            Quality Assurance and Quality control of distance education.
        </li>
        <li>
            Prospects and Challenges of Health Education through Blended Learning for the Professionals
        </li>
        <li>
            Implementing Online Courses in Bangladesh Open University (BOU): Challenges and Prospects
        </li>
        <li>
            Antimicrobial activity screening of selected medicinal plants of Bangladesh against antibiotic-resistant
            human pathogens
        </li>
        <li>
            Analysis of Examination Question Papers According to Blomm’s Taxonomy: A Case Study on Bangladesh Open
            University
        </li>
        <li>
            Bioassay guided discovery of new medicines and agrochemicals from natural sources such as plants, fungi,
            actinomycetes, sponges, seaweeds, etc.
        </li>
    </ol>

    <div>
        <p>
            <a href="https://jstr.bousst.edu.bd/index.php/jstr">For additional information, kindly explore the "Journal of Scientific and Technological Research (JSTR)."</a>
        </p>
    </div>

    <h1>Laboratory Facilities of SST </h1>
    <p>
        SST, BOU has set up different labs related with computer science (software, networking, digital hardware and
        electronics labs), physiotherapy (physical wellness lab) and biological science. Teachers, researchers and Learners
        of BOU can use state of the art instruments of these labs for tutorial class, training class, practical class,
        academic and research purpose.
    </p>
</mat-card>
<br>
<br>
<br>
<br>
<br>
<app-footer></app-footer>
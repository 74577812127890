<app-navbar></app-navbar>
<div class="container" style="margin-top: 20px;">
    <div class="card">
        <div class="card-header text-center">
            Application Form for B.Sc (Hons) in Master of Disability Management and Rehabilitation (MDMR) Program
        </div>
        <div class="card-body">
            <h2 class="card-title">Read the Following Instruction Carefully Before Completing the Form</h2>
            <p class="card-text"><strong>1. Write in capital letters for filling-up the form.</strong></p>
            <p class="card-text">
                <strong>2. The application proccessing fee Tk. 1000 (one thousand) will be paid which is
                    non-refundable.</strong>
            </p>
            <p class="card-text">
                <strong>3. The application should contain the following documents:</strong>
            </p>
            <ol type="i">
                <li>Complete filled up application form;</li>
                <li>Attested copies of academic certificate and marks sheets/ transcript of examinations;</li>
                <li>Testimonial form the last educational institution;</li>
                <li>Bank receipt of application fee.</li>
            </ol>
            <p class="card-text">
                <strong>4. Without above documents application will be rejected.</strong>
            </p>
        </div>
    </div>

</div>
<div class="container">
    <mat-card>
        <form class="form" [formGroup]="application_form">
            <div class="first-portion">
                <h1>Application Information</h1>
                <div class="div-right">
                    <div class="uploadPhoto-div">Upload Photo</div>

                    <div class="image-upload">
                        <!-- Image Preview -->
                        <label class="hoverable" for="fileInput">
                            <img [src]="url ? url:'../../../assets/img_avatar.png'">
                            <div class="hover-text">Choose Image
                                <p>Size: 300 x 300</p>
                            </div>
                            <div class="background"></div>
                        </label>
                        <br>
                        <input id="fileInput" type='file' (change)="onSelectFile($event)" required>
                    </div>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>Session</mat-label>
                    <input matInput formControlName="session" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Name of Regional Center</mat-label>
                    <mat-select formControlName="nameRC" [(ngModel)] = "regionalCenter">
                        <mat-option *ngFor="let RC of RCList" [value]="RC" required>{{RC.key}}
                        </mat-option>
                        <!-- <mat-option *ngFor="let nameRC of regeionalCenterList" [value]="nameRC" required>{{nameRC}}
                        </mat-option> -->
                    </mat-select>
                </mat-form-field>

                <mat-form-field (click)="checkRC()" appearance="outline">
                    <mat-label>Name of Study Center</mat-label>
                    <mat-select formControlName="studyCenterName">
                        <ng-container *ngFor="let RC of regionalCenter?.value">
                        <mat-option [value]="RC" required>{{ RC }}
                        </mat-option>
                    </ng-container>
                        <!-- <mat-option *ngFor="let studyCenterName of studyCenterList" [value]="studyCenterName" required>{{studyCenterName}}
                        </mat-option> -->
                    </mat-select>
                </mat-form-field>

                <!-- <mat-form-field appearance="outline">
                    <mat-label>Name of Study Center</mat-label>
                    <mat-select formControlName="studyCenterName">
                        <mat-option *ngFor="let studyCenterName of studyCenterList" [value]="studyCenterName" required>{{studyCenterName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Name of Regional Center</mat-label>
                    <mat-select formControlName="nameRC">
                        <mat-option *ngFor="let nameRC of regeionalCenterList" [value]="nameRC" required>{{nameRC}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
            </div>
            <div class="second-portion">

                <h1> Applicant's Personal Information</h1>

                <mat-form-field appearance="outline">
                    <mat-label>Name of the Applicant</mat-label>
                    <input matInput formControlName="applicantName" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Mother's Name</mat-label>
                    <input matInput formControlName="motherName" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Father's Name</mat-label>
                    <input matInput formControlName="fatherName" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Date of Birth (MM/DD/YYYY)</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="dob" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Gender</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option *ngFor="let gender of genderList" [value]="gender" required>{{gender}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Marital Status</mat-label>
                    <mat-select formControlName="maritalStatus">
                        <mat-option *ngFor="let maritalStatus of marital_Status" [value]="maritalStatus" required>
                            {{maritalStatus}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>National ID Number</mat-label>
                    <input matInput formControlName="nid" ngModel pattern="/^[0-9]*$/" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Citizenship</mat-label>
                    <input matInput formControlName="citizenship" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Valid E-mail</mat-label>
                    <input matInput placeholder="pat@example.com" formControlName="email" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Phone Number (Residence)</mat-label>
                    <input matInput formControlName="phoneNumber_residence" ngModel pattern="/^[0-9]*$/" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Phone Number (Personal)</mat-label>
                    <input matInput formControlName="phoneNumber_personal" ngModel pattern="/^[0-9]*$/" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Phone Number (Guardian)</mat-label>
                    <input matInput formControlName="phoneNumber_guardian" ngModel pattern="/^[0-9]*$/" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Present Address</mat-label>
                    <textarea matInput formControlName="present_add" required></textarea>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Permanent Address</mat-label>
                    <textarea matInput formControlName="permanent_add" required></textarea>
                </mat-form-field>


                <mat-form-field appearance="outline">
                    <mat-label>Postal Code</mat-label>
                    <input matInput formControlName="presentZip" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>District</mat-label>
                    <input matInput formControlName="presentDistrict" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>District</mat-label>
                    <input matInput formControlName="permanentDistrict" required>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Postal Code</mat-label>
                    <input matInput formControlName="permanentZip" required>
                </mat-form-field>
            </div>
            <div class="third-portion">
                <h1> Academic Qualification</h1>
                <div class="table-responsive">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col" rowspan="2">Name of the Examination</th>
                                <th scope="col" rowspan="2">Name of the Degree</th>
                                <th scope="col" rowspan="2">Session</th>
                                <th scope="col" rowspan="2">Roll</th>
                                <th scope="col" rowspan="2">Passing Year</th>
                                <th scope="col" rowspan="2">Board/University</th>
                                <th scope="col" rowspan="2">Name of the Institution</th>
                                <th scope="col" rowspan="2">Group/ Discipline</th>
                                <th scope="col" rowspan="2">Division/ GPA/ CGPA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">S.S.C/Equivalent</th>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="sscDegree">
                                            <mat-option *ngFor="let sscDegree of sscDegreeList" [value]="sscDegree"
                                                required>{{sscDegree}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="sscSession">
                                            <mat-option *ngFor="let sscSession of sscSessionList" [value]="sscSession"
                                                required>{{sscSession}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Roll</mat-label>
                                        <input matInput formControlName="sscRoll" required>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="sscPassingYear">
                                            <mat-option *ngFor="let sscPassingYear of sscPassingYearList" [value]="sscPassingYear"
                                                required>{{sscPassingYear}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="sscBoardUniversity">
                                            <mat-option *ngFor="let sscBoardUniversity of sscBoardUniversityList" [value]="sscBoardUniversity"
                                                required>{{sscBoardUniversity}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Institution Name</mat-label>
                                        <input matInput formControlName="sscInstitution" required>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="sscGroupDiscipline">
                                            <mat-option *ngFor="let sscGroupDiscipline of sscGroupDisciplineList" [value]="sscGroupDiscipline"
                                                required>{{sscGroupDiscipline}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                   <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="sscDivision" [(ngModel)]="sscResult">
                                            <mat-option *ngFor="let sscDivision of sscDivisionList" [value]="sscDivision"
                                                required>{{sscDivision}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" *ngIf="sscResult == 'GPA/CGPA in scale 4'">
                                        <input matInput formControlName="sscCgpa" required>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" *ngIf="sscResult == 'GPA/CGPA in scale 5'">
                                        <input matInput formControlName="sscGpa" required>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">H.S.C/Equivalent</th>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="hscDegree">
                                            <mat-option *ngFor="let hscDegree of hscDegreeList" [value]="hscDegree"
                                                required>{{hscDegree}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="hscSession">
                                            <mat-option *ngFor="let hscSession of hscSessionList" [value]="hscSession"
                                                required>{{hscSession}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Roll</mat-label>
                                        <input matInput formControlName="hscRoll" required>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="hscPassingYear">
                                            <mat-option *ngFor="let hscPassingYear of hscPassingYearList" [value]="hscPassingYear"
                                                required>{{hscPassingYear}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="hscBoardUniversity">
                                            <mat-option *ngFor="let hscBoardUniversity of hscBoardUniversityList" [value]="hscBoardUniversity"
                                                required>{{hscBoardUniversity}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Institution Name</mat-label>
                                        <input matInput formControlName="hscInstitution" required>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="hscGroupDiscipline">
                                            <mat-option *ngFor="let hscGroupDiscipline of hscGroupDisciplineList" [value]="hscGroupDiscipline"
                                                required>{{hscGroupDiscipline}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="hscDivision" [(ngModel)]="hscResult">
                                            <mat-option *ngFor="let hscDivision of hscDivisionList" [value]="hscDivision"
                                                required>{{hscDivision}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    
                                    <mat-form-field appearance="outline" *ngIf="hscResult == 'GPA/CGPA in scale 4'">
                                        <input matInput formControlName="hscCgpa" required>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" *ngIf="hscResult == 'GPA/CGPA in scale 5'">
                                        <input matInput formControlName="hscGpa" required>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">Bachelor/Equivalent</th>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="bachelorDegree">
                                            <mat-option *ngFor="let bachelorDegree of bachelorDegreeList"
                                                [value]="bachelorDegree" required>{{bachelorDegree}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="bachelorSession">
                                            <mat-option *ngFor="let bachelorSession of bachelorSessionList" [value]="bachelorSession"
                                                required>{{bachelorSession}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Roll</mat-label>
                                        <input matInput formControlName="bachelorRoll" required>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="bachelorPassingYear">
                                            <mat-option *ngFor="let bachelorPassingYear of bachelorPassingYearList" [value]="bachelorPassingYear"
                                                required>{{bachelorPassingYear}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="bachelorBoardUniversity">
                                            <mat-option *ngFor="let bachelorBoardUniversity of bachelorUniversityList" [value]="bachelorBoardUniversity"
                                                required>{{bachelorBoardUniversity}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Institution Name</mat-label>
                                        <input matInput formControlName="bachelorInstitution" required>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="bachelorGroupDiscipline">
                                            <mat-option *ngFor="let bachelorGroupDiscipline of bachelorSubjectList" [value]="bachelorGroupDiscipline"
                                                required>{{bachelorGroupDiscipline}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="bachelorDivision" [(ngModel)]="bachelorResult">
                                            <mat-option *ngFor="let bachelorDivision of bachelorDivisionList" [value]="bachelorDivision"
                                                required>{{bachelorDivision}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" *ngIf="bachelorResult == 'GPA/CGPA in scale 4'">
                                        <input matInput formControlName="bachelorCgpa" required>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" *ngIf="bachelorResult == 'GPA/CGPA in scale 5'">
                                        <input matInput formControlName="bachelorGpa" required>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <input type="checkbox" id="applicableBtn" value="1" (click)="ifApplicable2()">
                                    <span style="color: #323A89;font-size: 12px;"> &nbsp;If Applicable</span><br>
                                    Others
                                </th>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Other Degree</mat-label>
                                        <input matInput formControlName="othersDegree">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="othersSession">
                                            <mat-option *ngFor="let othersSession of othersSessionList" [value]="othersSession"
                                                required>{{othersSession}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Roll</mat-label>
                                        <input matInput formControlName="othersRoll">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="othersPassingYear">
                                            <mat-option *ngFor="let othersPassingYear of othersPassingYearList" [value]="othersPassingYear"
                                                required>{{othersPassingYear}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Board/ University</mat-label>
                                        <input matInput formControlName="othersBoardUniversity">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Institution Name</mat-label>
                                        <input matInput formControlName="othersInstitution">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Group/ Discipline</mat-label>
                                        <input matInput formControlName="othersGroupDiscipline">
                                    </mat-form-field>
                                </td>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Select One</mat-label>
                                        <mat-select formControlName="othersDivision" [(ngModel)]="otherResult">
                                            <mat-option *ngFor="let othersDivision of othersDivisionList" [value]="othersDivision"
                                                required>{{othersDivision}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" *ngIf="otherResult == 'GPA/CGPA in scale 4'">
                                        <input matInput formControlName="otherCgpa" required>
                                    </mat-form-field>

                                    <mat-form-field appearance="outline" *ngIf="otherResult == 'GPA/CGPA in scale 5'">
                                        <input matInput formControlName="otherGpa" required>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="fourth-portion">
                <div class="row">
                    <div class="col-sm-8">
                        <legend class="col-form-label col-sm-8 pt-0">1. Are you a freedom fighter/ Dependent of
                            freedom fighter?<br>If yes, provide necessary document.</legend>
                    </div>

                    <div class="col-sm-4">
                        <mat-radio-group [(ngModel)]='freedomFighter' formControlName="freedomFighter">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="col-sm-6"></div>
                    <div class="col-sm-2"></div>
                    <div *ngIf="freedomFighter!='no'" class=" col-sm-4">
                        <div class="form-group">
                            <div class="profile-upload">
                                <div class="upload-input">
                                    <input type="file" class="form-control upload"
                                        (change)="onSelectFreedomFighterFile($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-sm-8">
                        <legend class="col-form-label col-sm-8 pt-0">2. Do you belong to small ethnic group?<br>If
                            yes, provide necessary document.</legend>
                    </div>
                    <div class="col-sm-4">
                        <mat-radio-group [(ngModel)]='smallEthnic' formControlName="smallEthnic"
                            aria-label="Select an option">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="col-sm-6"></div>
                    <div class="col-sm-2"></div>
                    <div *ngIf="smallEthnic!='no'" class=" col-sm-4">
                        <div class="form-group">
                            <div class="profile-upload">
                                <div class="upload-input">
                                    <input type="file" class="form-control uploade"
                                        (change)="onSelectSmallEthnicFile($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-sm-8">
                        <legend class="col-form-label col-sm-8 pt-0">3. Are you a disable person?<br>If yes, provide
                            necessary document.</legend>
                    </div>
                    <div class="col-sm-4">
                        <mat-radio-group [(ngModel)]='disablePerson' formControlName="disablePerson"
                            aria-label="Select an option">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="col-sm-6"></div>
                    <div class="col-sm-2"></div>
                    <div *ngIf="disablePerson!='no'" class="col-sm-4">
                        <div class="form-group">
                            <div class="profile-upload">
                                <div class="upload-input">
                                    <input type="file" class="form-control uploade"
                                        (change)="onSelectDisablePersonFile($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br>

                <div class="row">
                    <div class="col-sm-8">
                        <legend class="col-form-label col-sm-9 pt-0">4. Have you ever been dismissed, suspended or
                            expelled from any educational institution?<br>If yes, provide necessary document.
                        </legend>
                    </div>
                    <div class="col-sm-3">
                        <mat-radio-group [(ngModel)]='dismissed' formControlName="dismissed"
                            aria-label="Select an option">
                            <mat-radio-button value="yes">Yes</mat-radio-button>
                            <mat-radio-button value="no">No</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="col-sm-6"></div>
                    <div class="col-sm-2"></div>
                    <div *ngIf="dismissed!='no'" class=" col-sm-4">
                        <div class="form-group">
                            <div class="profile-upload">
                                <div class="upload-input">
                                    <input type="file" class="form-control uploade"
                                        (change)="onSelectDismissedFile($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="fifth-portion">
                <div>
                    <h1>Declaration by Applicant</h1>
                    <p class="text-justify">I hereby undertake that if I am admitted into Bangladesh Open University, I
                        will, by any means, abide
                        by all the rules and regulations of Bangladesh Open University related with me as a learner. I
                        swear
                        that I will not involve in manufacturing, distribution, possession and consumption of tobacco
                        products, alcohol, drugs and other controlled substances which are strictly prohibited in the
                        premises of Bangladesh Open University. I can be expelled for violating any rule and regulation
                        of
                        Bangladesh Open University or for assisting any criminal activity. I agree that if I perform
                        well,
                        the university can use my name in its documents or any other form wherever relevant/required.
                        Bangladesh Open University reserves the right to change its policies, curricula or any other
                        matter
                        and to revise tuition and other fees when necessary. If I provide any false information or
                        document,
                        BOU will have all rights to take any disciplinary action against me including expulsion,
                        cancellation of my Admission/Results/Degree(s), etc.
                        <br>
                        <br>
                        I hereby declare that the above information is correct and complete to the best of my knowledge.
                    </p>
                </div>
 
                <div class="sig-upload-div">
                    <!-- <div>
                        <h2>Uploade your Signature</h2>
                    <div class="image-upload2">
                        <input type='file' (change)="onSignatureSelectFile($event)" required>
                    </div>
                </div> -->
                    <mat-form-field appearance="outline">
                        <mat-label>Upload Your Signature</mat-label>
                        <ngx-mat-file-input type='file' (change)="onSignatureSelectFile($event)"
                            placeholder="Include necessary file" required>
                        </ngx-mat-file-input>
                        <mat-icon matSuffix>fingerprint</mat-icon>
                        <mat-hint>Size: 180 x 80</mat-hint>
                    </mat-form-field>
                    <!-- <div>
                    <div style="margin-bottom: 10px;">
                        <h2>Uploade your Signature</h2>
                    </div>
                    <div class="image-upload2">
                         Image Preview 
                        <label class="hoverable2" for="fileInput">
                            <img class="img2" [src]="url ? url:'../../../assets/signature.png'">
                            <div class="hover-text">Uploade Signature</div>
                            <div class="background"></div>
                        </label>
                        <br>
                        <input id="fileInput" type='file' (change)="onSelectFile($event)" required>
                    </div>
                </div> -->

                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Date (MM/DD/YYYY)</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="signDate" required>
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

                <div class="d-flex justify-content-center mt-5">
                    <div class="form-group">
                        <button class="submit btn btn-primary" mat-raised-button
                            (click)="createApplicant()">Submit</button>
                        <h5>All filds are required</h5>
                    </div>
                </div>
            </div>
        </form>
    </mat-card>
</div>

<app-footer></app-footer>
import { ActivatedRoute } from '@angular/router';
import { AlumniService } from './../../services/alumni.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alumni-member-list',
  templateUrl: './alumni-member-list.component.html',
  styleUrls: ['./alumni-member-list.component.scss']
})
export class AlumniMemberListComponent implements OnInit {
  Users: any = [];
  cseUsers: any = [];
  userSingle: any = [];
  profile_img;
  name;
  email;
  phoneNumber;
  institute;
  regno;
  dept_group;
  faculty;
  session;
  batch;

  alumniBatch
  alumniDept

  constructor(private route: ActivatedRoute, private readonly _alumniService: AlumniService) { }

  ngOnInit(): void {
    this.alumniBatch = this.route.snapshot.paramMap.get('batch')
    this.alumniDept = this.route.snapshot.paramMap.get('dept')
    this._alumniService.getAllMemberByDept(this.alumniDept, this.alumniBatch)
      .subscribe((result) => {
        if (result.isFound) {
          this.cseUsers = result.result
        }
        console.log(this.cseUsers)
      })
    this._alumniService.getAllMember()
      .subscribe((res) => {
        if (res.isFound) {
          this.Users = res.result

        }
        console.log(this.Users)
      })
  }
  getById(_id: any) {
    console.log(_id)
    this.userSingle = this.Users.find(record => record._id === _id);
    this.profile_img = this.userSingle.profile_img;
    this.name = this.userSingle.name;
    this.email = this.userSingle.email;
    this.phoneNumber = this.userSingle.phoneNumber;
    this.institute = this.userSingle.institute;
    this.regno = this.userSingle.regno;
    this.dept_group = this.userSingle.dept_group;
    this.faculty = this.userSingle.faculty;
    this.session = this.userSingle.session;
    this.batch = this.userSingle.batch;

  }

}

import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-list',
  templateUrl: './admin-list.component.html',
  styleUrls: ['./admin-list.component.scss']
})
export class AdminListComponent implements OnInit {
  adminList
  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.getAllAdmin()
      .subscribe(data => {
        if (data.isFound) {
          this.adminList = data.result;
          // console.log(this.adminList)
        }
      })
  }

  giveAdminAccess(adminId) {
    this.router.navigate(['/admin/access', adminId]);
  }
}

<app-navbar></app-navbar>
<mat-card>
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">school</mat-icon>
                &nbsp;&nbsp;MDMR
            </ng-template>
            <img style="margin: 20px;" src="../../../../../assets/program_details/image/disability.svg" alt="">
            <p>
                The School of Science and Technology of Bangladesh Open University is offering Master of Disability
                Management
                and Rehabilitation (MDMR) program to upgrade the existing educational level of the graduate in health
                science in
                the country.
            </p>
            <h2>Program Aims and objectives:</h2>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To develop skilled human resources for services,
                    education, policy and research in Disability Management
                    Rehabilitation in Bangladesh.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To deliver local, regional, inter-professional
                    and graduate programs.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To create a cadre of leaders capable of advancing
                    education, research and practice in the field of
                    disability management and rehabilitation in Bangladesh and SAARC region as well.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To build up social awareness through
                    rehabilitation education and promotional activities directly to the
                    stakeholders and their care givers.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To innovate new knowledge and establish new
                    evidence through education and research.</li>
            </ul>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">import_contacts</mat-icon>
                &nbsp;&nbsp;Course Details
            </ng-template>
            <!-- <h3>Master of Disability Management and Rehabilitation (MDMR) Program:</h3> -->
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th style="width: 180px;">Level</th>
                        <th>Description</th>
                    </tr>
                    <tr>
                        <td>Entry Criteria</td>
                        <td>Bachelor of Science in physiotherapy/Disability Management/Occupational Therapy/Speech
                            language
                            Therapy
                            medical Science/Biological Science/Health science with at least 50% mark of at least GPA
                            2.50 out of
                            4
                            form a recognized University and S.S.C and H.S.C/equivalent examination result GPA-6.5 with
                            biology.
                            S.S.C and H.S.C/equivalent examination result at least one first division or second division
                            in
                            S.S.C
                            and H.S.C/equivalent examination with GPA-2.5 for B.SC in Nursing.</td>
                    </tr>
                    <tr>
                        <td>Semester</td>
                        <td>04</td>
                    </tr>
                    <tr>
                        <td>Semester Duration</td>
                        <td>6 months</td>
                    </tr>
                    <tr>
                        <td>Duration of the program </td>
                        <td>2 years</td>
                    </tr>
                    <tr>
                        <td>Total Credit Hours</td>
                        <td>72 hours</td>
                    </tr>
                    <tr>
                        <td>Number of Courses</td>
                        <td>18 Courses (Details in overleaf page)</td>
                    </tr>
                    <tr>
                        <td>Medium of Instruction</td>
                        <td>Medium of instruction of the Master of Disability Management and Rehabilitation (MDMR) must
                            be in
                            English unless otherwise directed.</td>
                    </tr>
                    <tr>
                        <td>1st Admission Time</td>
                        <td>December (Approx.)</td>
                    </tr>
                    <tr>
                        <td>Class Begins</td>
                        <td>January (Approx.)</td>
                    </tr>
                    <tr>
                        <td>Course Fees (Each Cources)</td>
                        <td>Tk. 1,000 per credit plus other fees</td>
                    </tr>
                    <tr>
                        <td>Registration Fee</td>
                        <td>Tk. 1000.00</td>
                    </tr>
                    <tr>
                        <td>Re-exam fees (Per course)</td>
                        <td>Tk. 500.00</td>
                    </tr>
                    <tr>
                        <td>Admission guide with form</td>
                        <td>Tk. 1000.00</td>
                    </tr>
                </table>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">verified</mat-icon>
                &nbsp;&nbsp;Eligibility
            </ng-template>
            <h2>Eligibility</h2>
            <p class="secondTabPara">
                Graduation in Physiotherapy/ Disability Management/ Occupational Therapy/ Speech and Language Therapy/
                Medical Science/ Biological Science/ Health Science with at least 50% marks or GPA 2.50 out of 4.00 from
                a
                recognized University and S.S.C and H.S.C/ equivalent examinaiton result GPA 6.5 with Biology/ Bachelor
                of Science in Nursing with S.S.C and H.S.C/ equivalent
                examination result with at least one first division or second division in S.S.C and H.S.C/ equivalent
                examination or GPA 2.50 out of 4.00 from a recognized University.
            </p>
            <h2>Admission Procedure</h2>
            <p class="secondTabPara">
                Candidate will be selected for the program on the basis of merit through admission test, which shall
                consist of written and oral examinations or set off criteria by BOU. However, admission work will be
                done by a committee headed by the Dean of SST or Chairman of examination committee or course coordinator
                of the MDMR program.
            </p>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">military_tech</mat-icon>
                &nbsp;&nbsp;Evaluation
            </ng-template>
            <h2>Performance Evaluation System</h2>
            <p class="secondTabPara">Method of assessment will be both formative and summative for all courses. Major
                emphasis will be given in the acquisition of personal development in the areas of health research and
                professional courses.
            </p>
            <h3>a) Formative Assessment/ Continuous Assessment</h3>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> After Completing 40% and 80% syllabus of a
                    specific course, the respective course teacher/ tutor shall conduct the 1st and the 2nd tutor marks
                    assessment respectively.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> All class tests, assignments, presentations,
                    class performance will be evaluated by the course tutor or faculty of BOU. At the end of syllabus of
                    each course, he/she shall calculate total number of the continuous assessment, class participation
                    marks and prepare a marks sheet. He/she shall submit the same to the chairman of the respective
                    examination committee as well as to the controller of the examination of BOU before start the
                    semester final examination.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> The course tutor shall also submit the class
                    attendance report with the register/documents to the Chairman of the respective examination
                    committee.</li>
            </ul>
            <h3>b) Summative Assessment/ Semester End Evaluation</h3>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> The semester final examination will be held at
                    the end of first year semester I, first year semester II, second year semester I and second year
                    semester II. The examinations will be conducted centrally by the controller of Examination as per
                    existing system</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Oral/Practical examintion: Oral/Practical
                    examination will be conducted by the respective departmental examination committee approved by the
                    University.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Report/Assignment: Respective tutors will
                    formulate and give notice for report/assignment according to learning needs. Learners need to submit
                    report/assignment accordingly. If learners fail to submit report/assignment, he/she will be required
                    to resubmit the relevant assignment to pass based on comments provided by tutor.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> The entire Master degree program is covered by a
                    set of the theoretical, tutor marks assessment, semester final examination, oral/ practical
                    examination and thesis.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Theoretical Courses: A minimum of 12 to 15
                    hours-class will constitute 1 (one) credit.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Thesis: Entire thesis will constitute 8 credits.
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> A learner must earn 72 credits for successful
                    completion of the program.</li>
            </ul>
            <h3>Distribution of Marks Evaluation</h3>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th>SL.</th>
                        <th>Description</th>
                        <th>Marks Distribution (%)</th>
                    </tr>
                    <tr>
                        <td class="text-center">1</td>
                        <td>Class attendance</td>
                        <td class="text-center">10</td>
                    </tr>
                    <tr>
                        <td class="text-center">2</td>
                        <td>Tutor Marks Assessment</td>
                        <td class="text-center">20</td>
                    </tr>
                    <tr>
                        <td class="text-center">3</td>
                        <td>Semester Final Examination</td>
                        <td class="text-center">50</td>
                    </tr>
                    <tr>
                        <td class="text-center">4</td>
                        <td>Oral/ Practical examination</td>
                        <td class="text-center">20</td>
                    </tr>
                    <tr>
                        <th colspan="2">Total</th>
                            <th>100</th>
                    </tr>
                </table>
            </div>
            <h3>a) The distribution of marsk for Thesis = 200 marks</h3>
            <p class="firstTabPara">
                Thesis Defense &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;= 100 Marks<br>
                Thesis Write Up and Evaluation &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; = 100 Marks
            </p>
            <h3>b) Basis for class attendance awarding marks</h3>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th>Class attendance (%)</th>
                        <th>Marks distribution (%)</th>
                    </tr>
                    <tr>
                        <td class="text-center">95% to 100%</td>
                        <td class="text-center">10</td>
                    </tr>
                    <tr>
                        <td class="text-center">90% to &#60;95%</td>
                        <td class="text-center">09</td>
                    </tr>
                    <tr>
                        <td class="text-center">85% to &#60;90%</td>
                        <td class="text-center">08</td>
                    </tr>
                    <tr>
                        <td class="text-center">80% to &#60;85%</td>
                        <td class="text-center">07</td>
                    </tr>
                    <tr>
                        <td class="text-center">75% to &#60;80%</td>
                        <td class="text-center">06</td>
                    </tr>
                    <tr>
                        <td class="text-center">70% to &#60;75%</td>
                        <td class="text-center">05</td>
                    </tr>
                    <tr>
                        <td class="text-center">65% to &#60;70%</td>
                        <td class="text-center">04</td>
                    </tr>
                    <tr>
                        <td class="text-center">60% to &#60;65%</td>
                        <td class="text-center">03</td>
                    </tr>
                    <tr>
                        <td class="text-center">55% to &#60;60%</td>
                        <td class="text-center">02</td>
                    </tr>
                    <tr>
                        <td class="text-center">50% to &#60;55%</td>
                        <td class="text-center">01</td>
                    </tr>
                    <tr>
                        <td class="text-center">&#60;50%</td>
                        <td class="text-center">00</td>
                    </tr>
                </table>
            </div>
            <p class="firstTabPara">c) There shall be two tutor marks assessment which will be conducted by each course
                tutor. Ouestions for tutor marks assessment should preferable be of short answer type or methods set by
                tutors in consulation with program coordinator.</p>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">account_circle</mat-icon>
                &nbsp;&nbsp;Academic Co-ordinator
            </ng-template>
            <div style="margin: 20px;">
                <h1>For more information:</h1>
                <ul class="fifthTabul" style="padding-inline-start: 20px;">
                    <li>Dr. Mohammad Habibur Rahman</li>
                    <li>Lecturer (Physiotherapy) </li>
                    <li>School of Science and Technology</li>
                    <li>Bangladesh Open University</li>
                    <li>Board Bazar, Gazipur-1705.</li>
                    <li>E-mail: sumonpt1983&#64;gmail.com</li>
                    <li>Phone: 09666730730 Ex.846 01919902188 (cell).</li>
                </ul>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add_alert</mat-icon>
                &nbsp;&nbsp;Notice
            </ng-template>
            <div class="table-container">
                <table class="notice-table">
                    <ng-container *ngFor="let notice of noticeList; index as i">
                        <ng-container *ngIf="!notice?.isDeleted">
                            <ng-container *ngIf="!notice?.isDisabled">
                                
    
                                    <tr>
                                       
                                        <td class="small-cell">
                                            <div class="dateDiv">
                                                <div class="month">{{notice?.noticeSubmissionDate | date :'MMMM'}}</div>
                                                <div class="date">{{notice?.noticeSubmissionDate | date :'d'}}, {{notice?.noticeSubmissionDate | date :'y'}}</div>
                                                <!-- <div class="year"></div> -->
                                            </div>
                                            <div class="half-circle">
                                            </div>                                    
                                        </td> 
                                       
                                         <td class="notice-cell">
                                             <a href="{{notice?.noticeFile}}" target="blank" matTooltip="Click for download">{{notice?.noticeBody}} </a> <app-new-icon [date]="notice?.noticeSubmissionDate"></app-new-icon>
                                            
                                        </td>
                                    </tr>
                                
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card>

<app-footer></app-footer>
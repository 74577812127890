import { Router } from '@angular/router';
import { DocumentsService } from 'src/app/services/documents.service';
import { NoticeService } from 'src/app/services/notice.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery-image',
  templateUrl: './gallery-image.component.html',
  styleUrls: ['./gallery-image.component.scss']
})
export class GalleryImageComponent implements OnInit {
  imageList
  constructor(public noticeService: NoticeService,
    public documentsService: DocumentsService,
    private router: Router) { }

  ngOnInit(): void {
    this.documentsService.getAllImage()
      .subscribe(data => {
        if (data.isFound) {
          this.imageList = data.result;
          console.log(this.imageList)
        }
      })
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentsService } from 'src/app/services/documents.service';
import { NoticeService } from 'src/app/services/notice.service';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent implements OnInit {
  documentList
  creatDocumentForm
  constructor(
    public noticeService: NoticeService,
    public documentsService: DocumentsService,
    private router: Router
  ) { }


  ngOnInit(): void {

    this.documentsService.getAllDocument()
      .subscribe(data => {
        if (data.isFound) {
          this.documentList = data.result;
          console.log(this.documentList)
        }
      })
  }
  doEdit(data) {

    this.documentsService.editDocumentData = data
    this.router.navigate(['/admin/doc/edit'])

  }
  makeDelete(id: string) {
    let data = {
      isDeleted: true
    }
    this.documentsService.deleteDocument(id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("success")
          window.location.reload()
        }
      })

  }
  makeDisable(id: string) {
    let data = {
      isDisabled: true
    }
    this.documentsService.disableDocument(data, id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("success")
          window.location.reload()
        }
      })
  }
  makeEnable(id) {
    let data = {
      isDisabled: false
    }
    this.documentsService.disableDocument(data, id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("success")
          window.location.reload()
        }
      })
  }
  viewNotice(url) { 
    this.fileLink(url[0])
      }
  fileLink(file: string) {
    console.log(file)
    if (file.substr(-4) == '.pdf') {
      this.noticeService.pdfURL = file;
      this.router.navigate(['/pdfviewer-notice'])
    }
    else {
      window.open(
        file,
        '_blank'
      );

    }
  }
}

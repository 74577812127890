import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alumni-dept-batch-list',
  templateUrl: './alumni-dept-batch-list.component.html',
  styleUrls: ['./alumni-dept-batch-list.component.scss']
})
export class AlumniDeptBatchListComponent implements OnInit {

  batch_list = ['1st'];
  dept
  constructor(private route: ActivatedRoute, public router: Router) { }

  ngOnInit(): void {
    this.dept = this.route.snapshot.paramMap.get('dept')
  }
  goToDeptBatch(batch) {
    this.router.navigate(['/alumni-member-list', this.dept, batch]);
  }

}

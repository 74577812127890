<app-navbar></app-navbar>
<div class="container" style="margin-top: 20px;">
  <div class="row">
    <div class="col-md text-center">

      <h2>Program of {{ dept }}</h2>
      <div class="bg-uni">
        <h2>Select Alumni Batch</h2>
      </div>
      <div class="friendlist-container">

        <!-- <mat-selection-list [multiple]="false">
          <mat-list-option *ngFor="let batch of batch_list" [value]="batch" (click)="goToDeptBatch(batch)">
            {{batch}}
          </mat-list-option>
        </mat-selection-list> -->


        <div class="media" *ngFor="let batch of batch_list;">
          <div class="kard" (click)="goToDeptBatch(batch)">

            <div class="big-font">
              {{batch}}
            </div>

          </div>
        </div>
      </div>
      <div class="friendlist-container">
        <button mat-raised-button routerLink="/alumni-member-application">Alumni Application</button>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

import { Component, OnInit, AfterViewInit, HostListener  } from '@angular/core';
import { Router } from '@angular/router'; 
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // scrolled = false;
  // sidenav = false;
  // @HostListener("window:scroll") 
  // scrollEvent() {
  //   // this.scrolled = window.pageYOffset >= 30;
  //   let lastScroll = 0;
    
  //   const currentScroll = window.pageYOffset;
  //   if(currentScroll >= 200){
  //     this.scrolled = true
  //   }
  //   else if (lastScroll = currentScroll) {
  //     this.scrolled = false  
  //   }
  //   else{
  //     this.scrolled = false
  //   }
  
  // }
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }
  private scrollExecuted: boolean = false;
  ngOnInit(): void {
  }
  ngAfterViewChecked() {

    if (!this.scrollExecuted) {
      let routeFragmentSubscription: Subscription;

      // Automatic scroll
      routeFragmentSubscription =
        this.activatedRoute.fragment
          .subscribe(fragment => {
            if (fragment) {
              let element = document.getElementById(fragment);
              if (element) {
                element.scrollIntoView();

                this.scrollExecuted = true;

                // Free resources
                setTimeout(
                  () => {
                    console.log('routeFragmentSubscription unsubscribe');
                    routeFragmentSubscription.unsubscribe();
                  }, 1000);

              }
            }
          })
    }
  }
  goToLink(link,id){
    this.router.navigate( [ '/'+link ], { fragment: id } );
  }

}

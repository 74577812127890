import { FacultyMemberService } from './../../services/faculty-member.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-allfaculty-members',
  templateUrl: './allfaculty-members.component.html',
  styleUrls: ['./allfaculty-members.component.scss']
})
export class AllfacultyMembersComponent implements OnInit {

  constructor(private facultyMemberService: FacultyMemberService, private router: Router) { }
  facultyMemberList
  ngOnInit(): void {
    this.facultyMemberService.getAllMember()
      .subscribe((res) => {
        console.log(res)
        this.facultyMemberList = res
        // if (res.isFound) {
        //   this.facultyMemberList = res.result
        // }
      })
  }
  viewMember(id: string) {

    this.router.navigate([`/admin/view-facuty-member/${id}`])

  }
  addMember() {
    this.router.navigate([`/admin/add-member`]);
  }
  doEdit(data) {
console.log(data)
console.log('edit')
    this.facultyMemberService.updateFacultyMemberData = data
    this.router.navigate(['/admin/update-member-info'])

  }
  makeDelete(id: string) {
    let data = {
      isDeleted: true
    }
    this.facultyMemberService.deleteMember(id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("Member deleted successfully...")
          window.location.reload()
        }
      })

  }

}

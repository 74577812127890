import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {
  baseURL: string = environment.API;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  editNoticeData: any;
  pdfURL: string = '';
  constructor(private http: HttpClient) { }

  createNewNotice(notice: any) { 
    return this.http.post<any>(`${this.baseURL}/api/notice/new`, notice)
  }
  editNotice(notice: any, id: string) {
    return this.http.put<any>(`${this.baseURL}/api/notice/update/${id}`, notice)
  }
  disableNotice(notice: any, id: string) {
    return this.http.put<any>(`${this.baseURL}/api/notice/disable/${id}`, notice)
  }
  deleteNotice(id: string) {
    return this.http.delete<any>(`${this.baseURL}/api/notice/delete/${id}`)
  }
  getAllNotice() {
    return this.http.get<any>(`${this.baseURL}/api/notice/list`, { headers: this.headers })
  }
  getNotice(noticeCategory) {
    return this.http.get<any>(`${this.baseURL}/api/notice/list/${noticeCategory}`, { headers: this.headers })
  }
  getNoticeByProgram(noticeProgram) {
    return this.http.get<any>(`${this.baseURL}/api/notice/listByProgram/${noticeProgram}`, { headers: this.headers })
  }
}

<app-navbar></app-navbar>
<br>
<br>
<div style="width: 90%; height:100%; margin:auto">
    <!-- <pdf-viewer 
    [src]="pdfSource"
              [render-text]="true"
              [original-size]="false">
    </pdf-viewer> -->
</div>
<br>
<br>
<br>
<br>
<br>
<br>

<app-footer></app-footer>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashbord',
  templateUrl: './dashbord.component.html',
  styleUrls: ['./dashbord.component.scss']
})
export class DashbordComponent implements OnInit {

  sideBarOpen = true; 
  constructor() { }

  ngOnInit(): void {
  }

  sideBarToggler(){
    this.sideBarOpen=!this.sideBarOpen;
  }
}

import { Router } from '@angular/router';
import { NoticeService } from './../../../services/notice.service';
import { DocumentsService } from './../../../services/documents.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-academic-calender',
  templateUrl: './academic-calender.component.html',
  styleUrls: ['./academic-calender.component.scss']
})
export class AcademicCalenderComponent implements OnInit {
  public AcademicCalendar;
  constructor(private documentsService: DocumentsService
    , public noticeService: NoticeService, private router: Router) { }

  ngOnInit(): void {
    this.documentsService.getDocument('AcademicCalendar')
      .subscribe((res) => {
        if (res.isFound) {
          this.AcademicCalendar = res.result
          console.log(this.AcademicCalendar)
        }
        else {
          alert(res.error)
        }
      })

  }
  viewPDF(url) {
    this.fileLink(url[0])
  }
  downloadPDF(url) {
    window.open(
      url[0],
      '_blank'
    );
  }
  fileLink(file: string) {
    console.log(file)
    if (file.substr(-4) == '.pdf') {
      this.noticeService.pdfURL = file;
      this.router.navigate(['/pdfviewer'])
    }
    else {
      window.open(
        file,
        '_blank'
      );

    }

  }

}


import { Component, OnInit } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { Router } from '@angular/router';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
  selector: 'app-documents-edit',
  templateUrl: './documents-edit.component.html',
  styleUrls: ['./documents-edit.component.scss']
})
export class DocumentsEditComponent implements OnInit {
  editDocumentForm: any;
  editDocumentFormData: any;
  url: any;
  public category = ['Program Handbook', 'AcademicBook', 'AcademicCalendar', 'ClassRoutine', 'ResearchPaper']
  constructor(
    public documentsService: DocumentsService,
    public fb: FormBuilder,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.editDocumentFormData = this.documentsService.editDocumentData
    if (this.editDocumentFormData == undefined || this.editDocumentFormData == null) {
      this.router.navigate(['/admin/doc/view'])
    }
    console.log(this.editDocumentFormData)
    this.editDocumentForm = this.fb.group({
      documentFirstName: [this.editDocumentFormData?.documentFirstName],
      documentLastName: [this.editDocumentFormData?.documentLastName],
      documentFile: [''],
      documentCategory: [this.editDocumentFormData?.documentCategory],
      programName: [this.editDocumentFormData?.programName]
    });
  }

  onSelectFile(event) {
    const file = event.target.files[0];
    this.editDocumentForm.patchValue({
      documentFile: file
    });
  }
  editDocument() {
    console.log(this.editDocumentForm.value)
    let form_data = new FormData();

    for (let key in this.editDocumentForm.value) {
      form_data.append(key, this.editDocumentForm.value[key]);
    }
    this.documentsService.editDocument(form_data, this.editDocumentFormData._id)
      .subscribe(data => {
        if (data.isUpdate) {
          alert("success")
          // window.location.reload()

          this.router.navigateByUrl("admin/doc/view")
        }
      })
  }

}

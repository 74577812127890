<app-navbar></app-navbar>
<div class="heading-container">
  <div class="verticallyMiddle">
    <span>F</span>
    <span>A</span>
    <span>C</span>
    <span>U</span>
    <span>L</span>
    <span>T</span>
    <span>Y</span>
    <div class="brDiv"></div>
    <div class="brDiv"></div>
    <span>M</span>
    <span>E</span>
    <span>M</span>
    <span>B</span>
    <span>E</span>
    <span>R</span>
    <span>S</span>
  </div>
</div>
<!-- <div class="dean-section">
  <div style="text-align: center;">
    <div class="dean-card">
      <div class="dean-social">
        <span *ngIf=""><a class="fab fa-twitter" href="" target="blank"></a></span>
        <span *ngIf=""><a class="fab fa-facebook" href="" target="blank"></a></span>
        <span *ngIf=""><a class="fab fa-linkedin" href="" target="blank"></a></span>
        <span *ngIf=""> <a class="fas fa-envelope" href="" target="blank"></a></span>
      </div>
      <div class="dean-pic"><img src="../../../assets/facultyMembers/dean.JPG" alt="profile-picture" /></div>
      <div class="dean-content">
        <h3 class="dean-title">Dr. Sharker Md. Numan</h3><span>Professor (Medical Science) & Dean, SST</span>
      </div>
      <div class="dean-btnContainer">
        <button>View Details</button>
      </div>
    </div>
  </div>
</div> -->
<div class="container">
  <div class="card mb-3">
    <div class="row no-gutters">
      <div class="col-md-4">
        <img class="img-fluid" [src]="deanInformation?.applicantPicture" alt="profile-picture">
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h1 class="card-title">Dean, School of Science and Technology</h1>

          <div class="nameDiv">
            <h1>{{deanInformation?.name}}</h1>
            <h3>{{deanInformation?.designation}}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="box-container">
  <div *ngFor="let member of facultyMembers">
    <div class="card">
      <div class="social">
        <span *ngIf="!member?.twitter==' '"><a class="fab fa-twitter" href="{{member?.twitter}}"
            target="blank"></a></span>

        <span *ngIf="!member?.facebook==' '"><a class="fab fa-facebook" href="{{member?.facebook}}"
            target="blank"></a></span>
        <span *ngIf="!member?.linkedin==' '"><a class="fab fa-linkedin" href="{{member?.linkedin}}"
            target="blank"></a></span>
        <span *ngIf="!member?.email==' '"> <a class="fas fa-envelope" href="mailto:{{member?.email}}"
            target="blank"></a></span>
      </div>
      <div class="pic"><img [src]="member?.applicantPicture" alt="profile-picture" /></div>
      <div class="content">
        <h3 class="title">{{member?.name}}</h3><span>{{member?.designation}}</span>
      </div>
      <div class="btnContainer">
        <button (click)="goToLink(member?._id)">View Details</button>
      </div>
    </div>
  </div>

</div>
<br>
<br>
<br>
<br>
<br>
<app-footer></app-footer>

<app-navbar></app-navbar>
<div class="container-fluid">
  <div id="vc-message">
    <div class="headingContainer">
      <div class="emptyDiv">
        <section class="social">
          <a href="">
            <i class="fas fa-at"></i>
          </a>
          <a href="">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a href="">
            <i class="fab fa-instagram"></i>
          </a>

          <a href="">
            <i class="fab fa-telegram-plane"></i>
          </a>
        </section>
      </div>
      <div class="headingDiv" style="margin-right:40px;">
        <h1>A message from Vice Chancellor Professor Syed Humayun Akhter PhD
        </h1>
        <p>
          Bangladesh Open University (BOU) is a place of hope, a place that inspires imagination, a place that nurtures creativity, 
          and a hub of innovative ideas. We are delighted to welcome you to Bangladesh Open University where your energy and excitement 
          enrich our vibrant educational life. Whether you are beginning or continuing your educational journey with us, 
          we look forward to learning, exploring, and growing together. 
          We appreciate your decision to invest in your academic journey 
          with BOU.
        </p>
      </div>
    </div>
    <div class="imageContainer">
      <img src="../../../assets/home/vcProvc/VC_sir.webp">
      <div class="titleDiv">
        <p>
          <b>Professor Syed Humayun Akhter PhD</b>
          <br>
          Vice-Chancellor<br>
          Bangladesh Open University
        </p>
      </div>
    </div>
    <div class="detailsMessageContainer">
      <div class="emptyDiv"></div>
      <div class="messageDiv" style="margin-right:40px;">
        <p>
          Since its inception in 1992 Bangladesh Open University (BOU) is the only university in the country which provides Open and Distance learning (ODL). In the meantime BOU has become a mega university having 424,067 learners in the secondary, higher secondary and higher level in the year of 2012. During this time, BOU has contributed immensely in the development of the country. School of Science and Technology (SST) is one of the 6 schools of the university.
        </p>
        <p>
          In the world of today, the modern information and communication technology is playing greater role in providing facilities for the delivery system and quality education. BOU is making new strides in employing most modern communication technology to the service of education. The rapid advancement in information technology has helped a great deal in eliminating the physical distances. With latest innovations in computer and communication technology, the world has become a global village where information is readily available and accessible to everyone.
        </p>
        <p>
          I do cordially invite you to visit the newly developed website of School of Science and Technology by own experts and resources. A quick glance through this window will familiarize you with the approach adopted by the university to realize its human resources development mission. Look through the programs of the school and activities, you may find something interesting to develop your true potential. The website would be helpful for prospective learners of the B.Sc.(Hons.) in Computer Science and other academic programs of the school.
         </p>
         <p>
          On behalf of everyone here at BOU, I thank you for visiting us here in cyberspace and welcome you to visit us in real life.
         </p>
         </div>
    </div>
  </div>

  <!-- <div id="dean-message" class="proVc-message">
    <div class="headingContainer">
      <div class="headingDiv" style="margin-left:40px;">
        <h1>A message from Pro-Vice-Chancellor Professor Dr. Nasim Banu</h1>
        <p>
          Bangladesh Open University (BOU) is the only public university for open and distance learning in Bangladesh.
          The university established in 1992 with a vision for enrolling the students of under privileged and
          disadvantage community. Using every means of advanced communication technology.
        </p>
      </div>
      <div class="emptyDiv">
        <section class="social">
          <a href="">
            <i class="fas fa-at"></i>
          </a>
          <a href="">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a href="">
            <i class="fab fa-instagram"></i>
          </a>

          <a href="">
            <i class="fab fa-telegram-plane"></i>
          </a>
        </section>
      </div>
    </div>
    <div class="imageContainer2">
      <img src="../../../assets/home/vcProvc/proVc1.jpg">
      <div class="titleDiv2">
        <p>
          <b>Professor Dr. Nasim Banu</b><br>
          Pro-Vice-Chancellor<br>
          Bangladesh Open University
        </p>
      </div>
    </div>
    <div class="detailsMessageContainer">

      <div class="messageDiv" style="margin-left:40px;">
        <p>
          BOU started its operation using print and electronic media as medium of communication and delivery of learning
          content and instructions. Recently, BOU uses technology mediated learning system like web-based learning,
          Audio/Video conferencing, interactive virtual class delivery. Very recently the university has introduced
          efficient learning management system. The School of Science and Technology (SST) of BOU based on previous
          experiences and achievements for offering science based programs, recently introduced a four years Bachelor of
          Science (Hons.) in Computer Science program.
        </p>
        <p>
          I am delighted to know that the SST has designed and hosted individual website by its own experts and
          resources for faciliting and showing the overall activities of SST faculties. The website (webpage) of the
          school so far uploaded the academic programs, admission and relevant information, FAQs and online counseling
          feed back from learners, publishing of results etc. of the school.
        </p>
        <p>
          I would like to extend by heartfelt gratitude to all concerned who designed the webpage and helped a lot to
          host the domain to the website.

          I hope that the website would be catalyst and be treated as the hubs of knowledge production, sharing and
          dissemination.
        </p>
        <p>
          Finally, I wish all success of the website designed by the school.
        </p>
      </div>
      <div class="emptyDiv"></div>
    </div>
  </div>

  <div id="vc-message" class="proVc-message" style="margin-top: 60px;">
    <div class="headingContainer">
      <div class="emptyDiv">
        <section class="social">
          <a href="">
            <i class="fas fa-at"></i>
          </a>
          <a href="">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a href="">
            <i class="fab fa-instagram"></i>
          </a>

          <a href="">
            <i class="fab fa-telegram-plane"></i>
          </a>
        </section>
      </div>
      <div class="headingDiv" style="margin-right:40px;">
        <h1>A message from Pro-Vice-Chancellor Dr. Mahbuba Nasreen
        </h1>
        <p>
          Bangladesh Open University (BOU) is the only public university for open and distance learning in Bangladesh.
          The university established in 1992 with a vision for enrolling the students of under privileged and
          disadvantage community. Using every means of advanced communication technology.
        </p>
      </div>
    </div>
    <div class="imageContainer">
      <img src="../../../assets/home/vcProvc/proVc2.png">
      <div class="titleDiv">
        <p>
          <b>Professor Dr. Mahbuba Nasreen
          </b><br>
          Pro-Vice-Chancellor<br>
          Bangladesh Open University
        </p>
      </div>
    </div>
    <div class="detailsMessageContainer">
      <div class="emptyDiv"></div>
      <div class="messageDiv" style="margin-right:40px;">
        <p>
          BOU started its operation using print and electronic media as medium of communication and delivery of learning
          content and instructions. Recently, BOU uses technology mediated learning system like web-based learning,
          Audio/Video conferencing, interactive virtual class delivery. Very recently the university has introduced
          efficient learning management system. The School of Science and Technology (SST) of BOU based on previous
          experiences and achievements for offering science based programs, recently introduced a four years Bachelor of
          Science (Hons.) in Computer Science program.
        </p>
        <p>
          I am delighted to know that the SST has designed and hosted individual website by its own experts and
          resources for faciliting and showing the overall activities of SST faculties. The website (webpage) of the
          school so far uploaded the academic programs, admission and relevant information, FAQs and online counseling
          feed back from learners, publishing of results etc. of the school.
        </p>
        <p>
          I would like to extend by heartfelt gratitude to all concerned who designed the webpage and helped a lot to
          host the domain to the website.

          I hope that the website would be catalyst and be treated as the hubs of knowledge production, sharing and
          dissemination.
        </p>
        <p>
          Finally, I wish all success of the website designed by the school.
        </p>
      </div>
    </div>
  </div>


  <div id="dean-message" class="treasurer-message">
    <div class="headingContainer">
      <div class="headingDiv" style="margin-left:40px;">
        <h1>Professor Mostafa Azad Kamal</h1>
        <p>
        </p>
      </div>
      <div class="emptyDiv">
        <section class="social">
          <a href="">
            <i class="fas fa-at"></i>
          </a>
          <a href="">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a href="">
            <i class="fab fa-instagram"></i>
          </a>

          <a href="">
            <i class="fab fa-telegram-plane"></i>
          </a>
        </section>
      </div>
    </div>
    <div class="imageContainer2">
      <img src="../../../assets/home/vcProvc/treasurer.jpg">
      <div class="titleDiv2">
        <p>
          <b>Professor Mostafa Azad Kamal</b><br>
          Treasurer<br>
          Bangladesh Open University
        </p>
      </div>
    </div>
    <div class="detailsMessageContainer">

      <div class="messageDiv" style="margin-left:40px;">

      </div>
      <div class="emptyDiv"></div>
    </div>
  </div> -->

  <div id="dean-message">
    <div class="headingContainer">
      <div class="headingDiv" style="margin-left:40px;">
        <h1>A message from Dean Professor Dr. K. M. Rezanur Rahman</h1>
        <p>
          Welcome to the Open and Distance learners of the programs of School of Science and Technology (SST), 
          Bangladesh Open University. This school offers need-based educational programs in multi-disciplines 
          with a view to develop skilled manpower and learners with life-long learning mindset. 
          The offered programs are delivered through two modes: regular mode and distance mode. 
          Those who are unable to attend classes in working days regularly may choose programs delivered by distance mode. 
          Teaching-learning takes place through blended mode: face-to-face and online classes as well as hands on training in lab equipped with modern 
          instruments according to class routine prepared based on mode of the programs.
        </p>
      </div>
      <div class="emptyDiv">
        <section class="social">
          <a href="">
            <i class="fas fa-at"></i>
          </a>
          <a href="">
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a href="">
            <i class="fab fa-instagram"></i>
          </a>

          <a href="">
            <i class="fab fa-telegram-plane"></i>
          </a>
        </section>
      </div>
    </div>
    <div class="imageContainer2">
      <img src="../../../assets/facultyMembers/DrKMRezanurRahman.PNG">
      <div class="titleDiv2">
        <p>
          <b>Professor Dr. K. M. Rezanur Rahman</b><br>
          Dean and Professor<br>
          School of Science and Technology<br>
          Bangladesh Open University
        </p>
      </div>
    </div>
    <div class="detailsMessageContainer">

      <div class="messageDiv" style="margin-left:40px;">
        <p>
          In order to facilitate the learning process, 
          the learners are provided with different educational 
          resources such as modules, manual, lecture notes, ppt files, audio-video programs, 
          text books and online study materials in addition to face-to-face and 
          online classes as well as hands on training in lab.
        </p>
        <p>
          The curriculum of each program offered by School of Science and Technology 
          has been developed on the basis of the latest discovery in relevant fields 
          which are equivalent to international standard and reflects Bangladesh 
          perspective as well. We strongly believe that the content of the offered programs will be helpful for learners to be 
          skilled with theoretical and practical knowledge and can apply gained knowledge and skills in the core curricular areas of 
          the program. All the programs have separate Handbook containing detailed syllabus as well as academic and exam regulations 
          which are very helpful for the learners conducting day to day academic 
          activities. The learners are advised to study the particular topic of the syllabus 
          of a particular course in advance that is going to be lectured in the next class for 
          better understanding and solving problems.
        </p>
        <p>
          The programs offered by SST are job orientated. We firmly believe that after successful completion of these programs 
          your employment opportunity in home and abroad will increase many folds. 
          We are committed to provide all-out support for your successful completion of the admitted 
          program. Finally, it is my sincere gratitude to all academics and staff of SST, BOU for their cordial support for 
          successfully running all the offered programs.
        </p>
        <p>
          Best wishes for your success.
        </p>
      </div>
      <div class="emptyDiv"></div>
    </div>
  </div>
</div>
<app-footer></app-footer>

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from "@angular/forms";
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FacultyMemberService } from 'src/app/services/faculty-member.service';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit {

  public regionalCenter: any;
  public sscResult: any;
  public hscResult: any;
  public bachelorResult: any;
  public otherResult: any;

  faculty_member_form: any;
  myControl = new FormControl();
  hide = true;
  filteredOptions: Observable<string[]>;
  url: any;
  preview: string;
  constructor(
    public fb: FormBuilder,
    public router: Router,
    private facultyMemberService: FacultyMemberService
  ) { }

  ngOnInit(): void {
    this.faculty_member_form = this.fb.group({
      name: [''],
      designation: [''],
      // subject: [''],
      bio: [''],
      experience: [''],
      email: [''],
      phoneNumber_residence: [''],
      phoneNumber_personal: [''],
      phoneNumber_office: [''],
      ex_number_office: [''],
      fax_number_office: [''],
      days_bou: [''],
      num_publiction: [''],
      employment_history: [''],
      research_interest: [''],
      applicantPicture: [],
      twitter: [''],
      facebook: [''],
      linkedin: [''],
      // mail: [''],
      cvUrl: [''],
    })
  }

  onSelectFile(event) {
    const file = event.target.files[0];
    const preview = event.target.files;
    if (preview && file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
      reader.onload = (event) => { // called once readAsDataURL is completed
        this.url = event.target.result;
      }
    }
    this.faculty_member_form.patchValue({
      applicantPicture: file
    });

  }

  onSignatureSelectFile(event) {
    console.log('sign')
    const file = event.target.files[0];
    this.faculty_member_form.patchValue({
      cvUrl: file
    });

  }

  createFacultyMember() {
    console.log(this.faculty_member_form.value)
    let form_data = new FormData();

    for (let key in this.faculty_member_form.value) {
      form_data.append(key, this.faculty_member_form.value[key]);
    }
    this.facultyMemberService.createNewMember(form_data)
      .subscribe(data => {
        if (data.isCreated) {
          alert("success")

          this.router.navigateByUrl("/admin/faculty-members")

          // window.location.reload();

        }
      })
  }
}

import { ComplaintService } from './../../../services/complaint.service';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-complaint-form',
  templateUrl: './complaint-form.component.html',
  styleUrls: ['./complaint-form.component.scss']
})
export class ComplaintFormComponent implements OnInit {
  complaint_form: any;
  constructor(
    public fb: FormBuilder,
    public router: Router,
    public ComplaintService: ComplaintService
  ) { }

  ngOnInit(): void {
    this.complaint_form = this.fb.group({
      studentID: ['', Validators.required],
      studentName: ['', Validators.required],
      topicName: ['', Validators.required],
      studentEmail: ['', Validators.required],
      messageBody: ['', Validators.required],
      messageFile: [''],
    })
  }

  sendComplaint() {

    console.log(this.complaint_form.value)
    let form_data = new FormData();

    for (let key in this.complaint_form.value) {
      form_data.append(key, this.complaint_form.value[key]);
    }
    this.ComplaintService.createNewComplaint(form_data)
      .subscribe((res) => {
        alert('Your message has been submitted successfully!');

        if (res.isCreated) {
          window.location.reload();
        }
      })
  }

  onSelectFile(event) {
    const file = event.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file); // read file as data url
    }
    this.complaint_form.patchValue({
      messageFile: file
    });

  }
  fileReset(){
    this.complaint_form.patchValue({
      messageFile: null
    });
  }

}

<app-admin-navbar></app-admin-navbar>
<main class=" mx-lg-5">
    <div class="container-fluid mt-5">
        <mat-card>
            <h3>Edit Notice</h3>
            <div class="form-1">
                <form [formGroup]="editNoticeForm">

                    <mat-form-field appearance="fill">
                        <mat-label>Notice Titel</mat-label>
                        <input matInput formControlName="noticeTitle" placeholder="Notice Titel" required>
                        <!-- <mat-icon matSuffix>title</mat-icon> -->
                        <mat-hint>Notice Title</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Notice Category</mat-label>
                        <mat-select formControlName="noticeCategory">
                            <mat-option *ngFor="let noticeCategory of category" [value]="noticeCategory" required>
                                {{noticeCategory}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Select Programme</mat-label>
                        <mat-select formControlName="noticeProgram">
                            <mat-option *ngFor="let noticeProgram of programList" [value]="noticeProgram" required>{{noticeProgram}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Notice boddy</mat-label>
                        <textarea matInput formControlName="noticeBody" placeholder="Write Notice Here"
                            required></textarea>
                    </mat-form-field>

                    <mat-form-field appearance="fill"> 
                        <mat-label>File (Optional) </mat-label>
                        <ngx-mat-file-input type='file' (change)="onSelectFile($event)"
                            placeholder="Include necessary file (Optional)">
                        </ngx-mat-file-input>
                        <mat-icon matSuffix>folder</mat-icon>
                    </mat-form-field>
                </form>
                <button class="btn btn-primary" (click)="editNotice()">Edit</button>
            </div>
        </mat-card>
    </div>
</main>
<app-admin-navbar></app-admin-navbar>
<main class="right-side-content">
    <mat-card>
        <div class="table-3 table-responsive">
            <h1>Notice List <button routerLink="/admin/notice/create" style="position: absolute; right: 34px;"  matTooltip="Create Notice"><img src="../../../assets/icon/faculty_add.png"/></button></h1>
            <table class="table table-bordered table-hover table-stripped">
                    <tr>
                         <th>Date</th>
                        <th>Category</th>
                        <th>Title</th>
                        <th colspan="4">Action</th> 
                    </tr>
                    <!--  *ngIf="notice?.isDisabled ==false || notice?.isDeleted == false"   -->
                    <ng-container *ngFor="let notice of noticeList; index as i">
                        <tr *ngIf="!notice?.isDeleted">
                            <td style="width: 15%;">{{notice?.noticeSubmissionDate | date}}</td>
                            <td style="width: 15%;">{{notice?.noticeCategory}}</td>
                            <td>{{notice?.noticeTitle}}</td>
                                                     
                                
                            <td><button type="button" class="btn btn-success"
                                (click)="viewNotice(notice?.noticeFile)" matTooltip="View"><img src="../../../../assets/icon/binocular.png" alt=""></button>
                            </td>
                            <td> <button type="button" class="btn btn-primary" (click)="doEdit(notice)" matTooltip="Edit"><img src="../../../../assets/icon/edit.png" alt=""></button>
                            </td>
                            <td><button *ngIf="!notice?.isDisabled" type="button" class="btn btn-secondary"
                                    (click)="makeDisable(notice?._id)" matTooltip="Disable"><img src="../../../../assets/icon/disable.png" alt=""></button>
                                <button *ngIf="notice?.isDisabled" type="button" class="btn btn-success"
                                    (click)="makeEnable(notice?._id)" matTooltip="Enable"><img src="../../../../assets/icon/enable.png" alt=""></button>
                            </td>
                            <td><button type="button" class="btn btn-danger"
                                    (click)="makeDelete(notice?._id)" matTooltip="Delete"><img src="../../../../assets/icon/bin.png" alt=""></button></td>
                        </tr>
                    </ng-container>
            </table>
        </div>
    </mat-card>
</main>
import { ComplaintService } from './../../services/complaint.service';
import { ActivatedRoute, Router } from '@angular/router';

import { Component, OnInit } from '@angular/core';
import { NoticeService } from 'src/app/services/notice.service';

@Component({
  selector: 'app-get-status',
  templateUrl: './get-status.component.html',
  styleUrls: ['./get-status.component.scss']
})

export class GetStatusComponent implements OnInit {
  complaintData: any
  complaintID: string
  constructor(private noticeService: NoticeService, private complaintService: ComplaintService, private router: Router) {

  }

  ngOnInit(): void {


  }
  getStatus() {
    this.complaintService.getStatusById(
      this.complaintID
    )
      .subscribe(data => {
        if (data.isFound) {
          this.complaintData = data.result;
        } else {
          alert('Please Check Your Complaint ID!')
        }
      })
  }
  viewPdf(url) {
    this.fileLink(url)
  }

  downloadPdf(url) {

    window.open(
      url,
      '_blank'
    );
  }
  fileLink(file: string) {
    console.log(file)
    if (file.substr(-4) == '.pdf') {
      this.noticeService.pdfURL = file;
      this.router.navigate(['/pdfviewer'])
    }
    else {
      window.open(
        file,
        '_blank'
      );

    }
  }


}


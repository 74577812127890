import { Router } from '@angular/router';
import { NoticeService } from 'src/app/services/notice.service';
import { Component, OnInit } from '@angular/core';
import { RoutineService } from 'src/app/services/routine.service';

@Component({
  selector: 'app-class-routine',
  templateUrl: './class-routine.component.html',
  styleUrls: ['./class-routine.component.scss']
})
export class ClassRoutineComponent implements OnInit {
  routineList
  constructor(public routineService: RoutineService, public noticeService: NoticeService, private router: Router) { }

  ngOnInit(): void {
    this.routineService.getAllRoutine()
      .subscribe((res) => {
        if (res.isFound) {
          this.routineList = res.result
          console.log(this.routineList)
        }
      })
  }
  downloadPDF(url) {

    window.open(
      url[0],
      '_blank'
    );
  }
  viewPDF(url) {
    this.fileLink(url[0]) 
  }
  
  fileLink(file: string) {
    console.log(file)
    if (file.substr(-4) == '.pdf') {
      this.noticeService.pdfURL = file;
      this.router.navigate(['/pdfviewer-notice'])
    }
    else {
      window.open(
        file,
        '_blank'
      );

    }
  }

}

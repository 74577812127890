import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-icon',
  templateUrl: './new-icon.component.html',
  styleUrls: ['./new-icon.component.scss']
})
export class NewIconComponent implements OnInit {
  @Input() date;
  isDateOver;
  constructor() { }

  ngOnInit(): void {
    let today = new Date() as any;
    let oldDate = new Date(this.date) as any;
    let diffDays =  Math.floor(Math.abs(oldDate - today)/(1000 * 3600 * 24));
    if (diffDays > 29) {
      // more then 29 days
      this.isDateOver = true;
      
    } else {
      this.isDateOver = false;
    }
    console.log(this.isDateOver)


  }

}

import { AccessGuardService } from './services/access-guard.service';
import { AdminLoginComponent } from './bousst-admin/admin-login/admin-login.component';
import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { CommonModule} from '@angular/common';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { ApplyformComponent } from './components/applyform/applyform.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MdmrRegFormComponent } from './components/mdmr-reg-form/mdmr-reg-form.component';
import { FsnRegFormComponent } from './components/fsn-reg-form/fsn-reg-form.component';
import { PaymentCanceledComponent } from './components/payment-canceled/payment-canceled.component';
import { PaymentFailedComponent } from './components/payment-failed/payment-failed.component';
import { SuccessComponent } from './components/success/success.component';
import { MakePaymentComponent } from './components/make-payment/make-payment.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ComplaintComponent } from './components/complaint/complaint.component';
import { ComplaintFormComponent } from './components/complaint/complaint-form/complaint-form.component';

import { ComplainsComponent } from './bousst-admin/complain/complains/complains.component';
import { ViewComplainComponent } from './bousst-admin/complain/view-complain/view-complain.component';
import { ReplyComplainComponent } from './bousst-admin/complain/reply-complain/reply-complain.component';
import { ComplainStatusComponent } from './bousst-admin/complain/complain-status/complain-status.component';
import { DashbordComponent } from './bousst-admin/dashbord/dashbord.component';
import { NoticeCreatComponent } from './bousst-admin/notice/notice-creat/notice-creat.component';
import { GetStatusComponent } from './components/get-status/get-status.component';
import { NoticeComponent } from './bousst-admin/notice/notice/notice.component';
import { ViewNoticeComponent } from './components/view-notice/view-notice.component';
import { AdmissionNoticeComponent } from './components/admission-notice/admission-notice.component';
import { RegistrationClassNoticeComponent } from './components/registration-class-notice/registration-class-notice.component';
import { ExaminationNoticeComponent } from './components/examination-notice/examination-notice.component';
import { ResultNoticeComponent } from './components/result-notice/result-notice.component';
import { GeneralNoticeComponent } from './components/general-notice/general-notice.component';
import { EmploymentNoticeComponent } from './components/employment-notice/employment-notice.component';
import { AdmitCardComponent } from './components/admit-card/admit-card.component';
import { AboutSstComponent } from './components/about-sst/about-sst.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { FacultyMembersComponent } from './components/faculty-members/faculty-members.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { DcsaComponent } from './components/program/diploma/dcsa/dcsa.component';
import { BscCseComponent } from './components/program/under-graduate/bsc-cse/bsc-cse.component';
import { BscNursingComponent } from './components/program/under-graduate/bsc-nursing/bsc-nursing.component';
import { BscFoodScienceNutritionComponent } from './components/program/under-graduate/bsc-food-science-nutrition/bsc-food-science-nutrition.component';
import { MdmrComponent } from './components/program/graduate/mdmr/mdmr.component';
import { MphComponent } from './components/program/graduate/mph/mph.component';
import { PgdmuComponent } from './components/program/post-graduate/pgdmu/pgdmu.component';
import { DcsaRegFormComponent } from './components/admisstion-forms/dcsa-reg-form/dcsa-reg-form.component';
import { BscCseRegFormComponent } from './components/admisstion-forms/bsc-cse-reg-form/bsc-cse-reg-form.component';
import { BscNursingRegFormComponent } from './components/admisstion-forms/bsc-nursing-reg-form/bsc-nursing-reg-form.component';
import { MphRegFormComponent } from './components/admisstion-forms/mph-reg-form/mph-reg-form.component';
import { PgdmuRegFormComponent } from './components/admisstion-forms/pgdmu-reg-form/pgdmu-reg-form.component';
import { StudyGuidesComponent } from './components/resources/study-guides/study-guides.component';
import { RegistrationFormComponent } from './components/resources/registration-form/registration-form.component';
import { StudyCentersComponent } from './components/resources/study-centers/study-centers.component';
import { ListOfTutorsComponent } from './components/resources/list-of-tutors/list-of-tutors.component';
import { ClassRoutineComponent } from './components/resources/class-routine/class-routine.component';
import { AcademicCalenderComponent } from './components/resources/academic-calender/academic-calender.component';
import { AssignmentsComponent } from './components/resources/assignments/assignments.component';
import { EBookComponent } from './components/resources/e-book/e-book.component';
import { VideoAudioContentComponent } from './components/resources/video-audio-content/video-audio-content.component';
import { ViewPdfComponent } from './components/view-pdf/view-pdf.component';
import { ResearchAndJournalComponent } from './components/research-and-journal/research-and-journal.component';
import { NewsComponent } from './components/news/news.component';
import { DocumentsComponent } from './bousst-admin/documents/documents.component';
import { CreateDocumentsComponent } from './bousst-admin/documents/create-documents/create-documents.component';
import { DocumentsListComponent } from './bousst-admin/documents/documents-list/documents-list.component';
import { DocumentsEditComponent } from './bousst-admin/documents/documents-edit/documents-edit.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ViewPdfNoticeComponent } from './components/view-pdf-notice/view-pdf-notice.component';
import { EditNoticeComponent } from './bousst-admin/notice/edit-notice/edit-notice.component';
import { MessagesFromTeachersComponent } from './components/messages-from-teachers/messages-from-teachers.component';
import { NoticeEditComponent } from './bousst-admin/notice/notice-edit/notice-edit.component';
import { AdminNavbarComponent } from './bousst-admin/sidebar-and-navbar/admin-navbar/admin-navbar.component';
import { RoutineListComponent } from './bousst-admin/routine/routine-list/routine-list.component';
import { RoutineViewComponent } from './bousst-admin/routine/routine-view/routine-view.component';
import { RoutineEditComponent } from './bousst-admin/routine/routine-edit/routine-edit.component';
import { RoutineCreateComponent } from './bousst-admin/routine/routine-create/routine-create.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { CseVideoContentComponent } from './components/resources/video-audio-content/cse-video-content/cse-video-content.component';
import { DcsaVideoContentComponent } from './components/resources/video-audio-content/dcsa-video-content/dcsa-video-content.component';
import { MdmrVideoContentComponent } from './components/resources/video-audio-content/mdmr-video-content/mdmr-video-content.component';
import { BsnVideoContentComponent } from './components/resources/video-audio-content/bsn-video-content/bsn-video-content.component';
import { MphVideoContentComponent } from './components/resources/video-audio-content/mph-video-content/mph-video-content.component';
import { FsnVideoContentComponent } from './components/resources/video-audio-content/fsn-video-content/fsn-video-content.component';
import { PgdmuVideoContentComponent } from './components/resources/video-audio-content/pgdmu-video-content/pgdmu-video-content.component';
import { FacultyMembersDetailsComponent } from './components/faculty-members-details/faculty-members-details.component';
import { NewIconComponent } from './components/new-icon/new-icon.component';
import { ContactUsMessageComponent } from './bousst-admin/contact-us-message/contact-us-message.component';
import { ViewMessageComponent } from './bousst-admin/contact-us-message/view-message/view-message.component';
import { AllfacultyMembersComponent } from './bousst-admin/allfaculty-members/allfaculty-members.component';
import { AddMemberComponent } from './bousst-admin/allfaculty-members/add-member/add-member.component';
import { ViewFacutyMemberComponent } from './bousst-admin/allfaculty-members/view-facuty-member/view-facuty-member.component';
import { UpdateMemberInfoComponent } from './bousst-admin/allfaculty-members/update-member-info/update-member-info.component';
import { CreateAdminComponent } from './bousst-admin/create-admin/create-admin.component';
import { AdminListComponent } from './bousst-admin/admin-list/admin-list.component';
import { AdminAccessComponent } from './bousst-admin/admin-access/admin-access.component';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { OfficialsComponent } from './components/officials/officials.component';
import { UploadGalleryImageComponent } from './bousst-admin/upload-gallery-image/upload-gallery-image.component';
import { GalleryImageComponent } from './bousst-admin/gallery-image/gallery-image.component';
import { AlumniMemberListComponent } from './components/alumni-member-list/alumni-member-list.component';
import { AlumniMemberNewComponent } from './components/alumni-member-new/alumni-member-new.component';
import { AlumniDeptBatchListComponent } from './components/alumni-dept-batch-list/alumni-dept-batch-list.component';
import { AlumniDeptListComponent } from './components/alumni-dept-list/alumni-dept-list.component';

import { NotifierModule } from 'angular-notifier';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    ApplyformComponent,
    MdmrRegFormComponent,
    FsnRegFormComponent,
    PaymentCanceledComponent,
    PaymentFailedComponent,
    SuccessComponent,
    MakePaymentComponent,
    SidebarComponent,
    SidenavComponent,
    ComplaintComponent,
    ComplaintFormComponent,
    AdminLoginComponent,
    ComplainsComponent,
    ViewComplainComponent,
    ReplyComplainComponent,
    ComplainStatusComponent,
    DashbordComponent,
    AdminNavbarComponent,
    NoticeEditComponent,
    NoticeCreatComponent,
    GetStatusComponent,
    NoticeComponent,
    MessagesFromTeachersComponent,
    ViewNoticeComponent,
    AdmissionNoticeComponent,
    RegistrationClassNoticeComponent,
    ExaminationNoticeComponent,
    ResultNoticeComponent,
    GeneralNoticeComponent,
    EmploymentNoticeComponent,
    AdmitCardComponent,
    EditNoticeComponent,
    AboutSstComponent,
    FaqsComponent,
    FacultyMembersComponent,
    GalleryComponent,
    DcsaComponent,
    BscCseComponent,
    BscNursingComponent,
    BscFoodScienceNutritionComponent,
    MdmrComponent,
    MphComponent,
    PgdmuComponent,
    DcsaRegFormComponent,
    BscCseRegFormComponent,
    BscNursingRegFormComponent,
    MphRegFormComponent,
    PgdmuRegFormComponent,
    StudyGuidesComponent,
    RegistrationFormComponent,
    StudyCentersComponent,
    ListOfTutorsComponent,
    ClassRoutineComponent,
    AcademicCalenderComponent,
    AssignmentsComponent,
    EBookComponent,
    VideoAudioContentComponent,
    ViewPdfComponent,
    ResearchAndJournalComponent,
    NewsComponent,
    DocumentsComponent,
    CreateDocumentsComponent,
    DocumentsListComponent,
    DocumentsEditComponent,
    ViewPdfNoticeComponent,
    RoutineListComponent,
    RoutineViewComponent,
    RoutineEditComponent,
    RoutineCreateComponent,
    ContactUsComponent,
    CseVideoContentComponent,
    DcsaVideoContentComponent,
    MdmrVideoContentComponent,
    BsnVideoContentComponent,
    MphVideoContentComponent,
    FsnVideoContentComponent,
    PgdmuVideoContentComponent,
    FacultyMembersDetailsComponent,
    NewIconComponent,
    ContactUsMessageComponent,
    ViewMessageComponent,
    AllfacultyMembersComponent,
    AddMemberComponent,
    ViewFacutyMemberComponent,
    UpdateMemberInfoComponent,
    CreateAdminComponent,
    AdminListComponent,
    AdminAccessComponent,
    OfficialsComponent,
    UploadGalleryImageComponent,
    GalleryImageComponent,
    AlumniMemberListComponent,
    AlumniMemberNewComponent,
    AlumniDeptBatchListComponent,
    AlumniDeptListComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialFileInputModule,
    MaterialModule,
    CommonModule,
   
    //CKEditorModule,
    NotifierModule, 
   // SnotifyModule

  ],
  providers: [
    AuthService,
    AuthGuardService,
    AccessGuardService,
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
    // { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    //   SnotifyService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

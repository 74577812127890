
<div class="container-div">
    <div class="form-container">
    <div class="inner-container">
        <div class="icon-div">
            <h1 class="main-heading">Login As Admin <i class="fa fa-smile-o" aria-hidden="true"></i></h1>
            <!-- <h2>Contact Us...</h2> -->
            <!-- <i class="fa fa-envelope-open" aria-hidden="true"></i> -->
            <img src="../../../assets/loginimg.svg" class="img-fluid">
        </div>
        <div class="form-div">
            <mat-card>
                <mat-card-content>
                    <form [formGroup]="loginForm" style="justify-content: center;">
                        <h2>Login</h2>
                        <mat-form-field class="full-width-input" appearance="fill">
                            <mat-label>Email</mat-label>
                            <input name="email" matInput placeholder="Email" formControlName="email" required>
                        </mat-form-field>
                        <mat-form-field class="full-width-input" appearance="fill">
                            <mat-label>Password</mat-label>
                            <input name="password" matInput [type]="hide ? 'text' : 'password'"
                                placeholder="Password" formControlName="password" required>
                            <mat-icon matSuffix (click)="hide = !hide" class="eye-icon">{{ hide ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                        </mat-form-field>
                        <button [disabled]="!this.loginForm.valid" mat-raised-button color="basic" (click)="login()"
                            style="margin-right: 10px;">Login</button>
                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    </div>

</div>

import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { AuthService } from './../../services/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  hide = false;
  constructor(private router: Router,
    private authService: AuthService,) { }
  loginForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required]),
    password: new FormControl(null, Validators.required),
  })
  ngOnInit(): void {

  }
  login() {
    this.authService.newLogin(this.loginForm.value)
      .subscribe((login) => {

        if (login.isLogin) {
          this.authService.makeAuthenticated(login.token, login.user)
          this.router.navigateByUrl('/dashboard');
        } else {
          alert("Login Not Successful !")
          alert(login.massege)
          alert(login.errors)
        }

      })
  }

}

<app-admin-navbar></app-admin-navbar> 
<main class="pt-5 mx-lg-5">
    <div class="mt-5">
        <div class="container">

            <mat-card>
                <h1>Student's Feedback</h1>
                <mat-card-header>
                    <mat-card-title>
                        <h3>Feedback ID :
                            {{complaintData?.complaintID}}</h3>
                        <h3>Student ID :
                            {{complaintData?.studentID}}</h3>
                        <h3>Student Name : {{complaintData?.studentName}}</h3>
                        <h3>Student Email : {{complaintData?.studentEmail}}</h3>

                    </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <h2>Message : {{complaintData?.topicName}} </h2>
                    <p>
                        {{complaintData?.messageBody}}
                    </p>
                    <div *ngIf="complaintData?.messageFile != ''">
                        <button class="btn btn-success"
                            (click)="downloadPDF(complaintData?.messageFile)">Attachment</button>
                    </div>
                </mat-card-content>

                <br>
                <mat-card-actions>
                    <button class="btn btn-primary" (click)="complaintReply()">Reply</button>
                    <!-- <a class="btn btn-primary" routerLink="/application-form" role="button">Reply</a> -->
                </mat-card-actions>
            </mat-card>
            <div *ngIf="complaintReplyButton">
                <app-reply-complain></app-reply-complain>
            </div>
            <mat-card *ngIf="complaintData?.messageReply.length>0">
                <h1>Reply List</h1>
                <table class="table table-hover">

                    <tbody>
                        <th>SN. </th>
                        <th>Reply Body</th>
                        <th>Attachment</th>
                        <tr *ngFor="let reply of complaintData?.messageReply; index as i">
                            <th scope="col">{{i+1}}</th>
                            <td scope="col">{{reply.replyBody}}</td>
                            <td *ngIf="reply.replyFile != ''" scope="col"><a href="{{reply.replyFile}}">Attachment</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-card>

        </div>
    </div>
</main>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mdmr-video-content',
  templateUrl: './mdmr-video-content.component.html',
  styleUrls: ['./mdmr-video-content.component.scss']
})
export class MdmrVideoContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<app-navbar></app-navbar>
<mat-card>
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">school</mat-icon>
                &nbsp;&nbsp;B.Sc in CSE
            </ng-template>
            <img style="margin: 20px;" src="../../../../../assets/program_details/image/computereng.svg" alt="">
            <p class="firstTabPara">
                The B.Sc. in Computer Science and Engineering (CSE) program is designed to produce skilled graduates in
                the field to satisfy the growing demands of computer engineers at home and abroad. It provides the
                learners an opportunity to obtain a broad knowledge of Computer Science, Computer Engineering with some
                freedom to tailor the program according to the learner’s individual needs.

            </p>
            <h2>The objectives of the program are:</h2>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To produce engineers equipped with the technical
                    knowledge and skills with the ability to apply
                    them correctly, and with the creativity and self-development.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To train them the communication and collaboration
                    skills and the ability to use new technologies
                    to develop themselves to move with the rapidly innovative world.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To produce scientists and engineers equipped with
                    morals and ethics.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> To create opportunity for teaching and research
                    in Computer Science and Engineering.</li>
            </ul>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">import_contacts</mat-icon>
                &nbsp;&nbsp;Course Details
            </ng-template>
            <div class="table-responsive">
                <table id="dcsa-table">
                    <tr>
                        <th>Level</th>
                        <th>Graduation</th>
                    </tr>
                    <tr>
                        <td>Entry Criteria</td>
                        <td>HSC (Science) or 4 years Diploma under Technical Board or DCSA from BOU</td>
                    </tr>
                    <tr>
                        <td>Semester</td>
                        <td>08</td>
                    </tr>
                    <tr>
                        <td>Semester Duration</td>
                        <td>6 months</td>
                    </tr>
                    <tr>
                        <td>Class Duration</td>
                        <td>5 months</td>
                    </tr>
                    <tr>
                        <td>Total Credit Hours</td>
                        <td>148 hours</td>
                    </tr>
                    <tr>
                        <td>Minimum Duration</td>
                        <td>4 years</td>
                    </tr>
                    <tr>
                        <td>Maximum Duration</td>
                        <td>8 years</td>
                    </tr>
                    <tr>
                        <td>Enrollment</td>
                        <td>Once in a Year(January)</td>
                    </tr>
                    <tr>
                        <td>Selection Criteria</td>
                        <td>Admission Test</td>
                    </tr>
                    <tr>
                        <td>Learner’s Guide and Admission Form Fee</td>
                        <td>TK. 600</td>
                    </tr>
                    <tr>
                        <td>Course Registration Fee (Per credit)</td>
                        <td>Tk. 600</td>
                    </tr>
                    <tr>
                        <td>Digital ID card</td>
                        <td>Tk. 200</td>
                    </tr>
                    <tr>
                        <td>Semester Registration Fee (Each semester)</td>
                        <td>Tk. 500</td>
                    </tr>
                    <tr>
                        <td>Examination Fee (Each Course)</td>
                        <td>TK. 300</td>
                    </tr>
                    <tr>
                        <td>Reexamination Fee per course (if applicable)</td>
                        <td>TK. 350</td>
                    </tr>
                    <tr>
                        <td>Academic Calendar Fee</td>
                        <td>TK. 50</td>
                    </tr>
                    <tr>
                        <td>Program Transcript Fee (per semester)</td>
                        <td>TK. 100</td>
                    </tr>
                    <tr>
                        <td>Program Transcript Fee</td>
                        <td>TK. 400</td>
                    </tr>
                    <tr>
                        <td>Provisional Certificate Fee</td>
                        <td>TK. 300</td>
                    </tr>
                    <tr>
                        <td>Original Certificate Fee</td>
                        <td>TK. 350</td>
                    </tr>
                    <tr>
                        <td>Graduate Ceremony Fee</td>
                        <td>TK. 500</td>
                    </tr>
                    <tr>
                        <td>Testimonial Fee</td>
                        <td>TK. 100</td>
                    </tr>
                </table>
                <p class="secondTabPara" style="margin-top: 20px; color: rgb(2, 2, 107);">
                    Total credits of the program are 148 and the credit distributions are given bellow.
                </p>
                <div class="table-responsive">
                    <table id="dcsa-table">
                        <tr>
                            <th>Type</th>
                            <th>Number of Courses</th>
                            <th>Credits</th>
                        </tr>
                        <tr>
                            <td>General Courses</td>
                            <td class="text-center">04</td>
                            <td class="text-center">09</td>
                        </tr>
                        <tr>
                            <td>Basic Science Courses</td>
                            <td class="text-center">06</td>
                            <td class="text-center">17</td>
                        </tr>
                        <tr>
                            <td>Core Courses (theory)</td>
                            <td class="text-center">30</td>
                            <td class="text-center">86</td>
                        </tr>
                        <tr>
                            <td>Practical</td>
                            <td class="text-center">24</td>
                            <td class="text-center">30</td>
                        </tr>
                        <tr>
                            <td>Project Work</td>
                            <td class="text-center">1</td>
                            <td class="text-center">4</td>
                        </tr>
                        <tr>
                            <td>Comprehensive Viva Voce</td>
                            <td class="text-center">1</td>
                            <td class="text-center">2</td>
                        </tr>
                        <tr>
                            <td>Total ( theory + Practical+ Project +
                                Comprehensive Viva Voce)</td>
                            <td class="text-center">04+06+30+24+1+1= 66</td>
                            <td class="text-center">148</td>
                        </tr>
                    </table>
                </div>
                <div style="text-align: center; padding-top: 20px;">
                    <a href="../../../../../assets/program_details/cse_detail_260417.pdf" target="blank">Click here for
                        details</a>
                </div>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">verified</mat-icon>
                &nbsp;&nbsp;Eligibility
            </ng-template>
            <p class="thirdTabPara" style="margin-top: 20px;">
                To be eligible for admission in B.Sc. in Computer Science and Engineering program, a candidate
                must pass SSC and HSC examinations or its equivalent in Science group.
            </p>
            <h2>Minimum qualifications to take part in the admission test are as follows:</h2>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> GPA 2.5 / 2nd division in SSC/equivalent and 2.5/
                    2nd division in HSC/equivalent
                    examinations separately.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Applicants must have passed HSC/equivalent
                    examination from Technical Education Board with
                    Mathematics / higher mathematics, Physics, with minimum GPA 2.5/ 2nd division in HSC
                    examination.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Applicants who passed the Diploma in Computer
                    Science and Application (DCSA) program from
                    Bangladesh Open University.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Applicants who obtained 3 (three) or 4 (four)
                    years Diploma in CSE/ Electrical Engineering/
                    Electronic Engineering/ Telecommunication Engineering from a recognized Polytechnic Institute
                    with a minimum CGPA 2.0 / 2nd division.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Applicants who have passed SSC/equivalent and
                    HSC/ equivalent examination in the current year
                    or years before the notification can apply.</li>
            </ul>
            <h2>Selection Criteria</h2>
            <ul class="firstTabUl">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Written Admission Test: Admission test will be
                    conducted on the basis of the current syllabus of
                    Mathematics, Physics, English and General Knowledge subjects of H.S.C examinations. The
                    questions of admission test will be set in MCQ and/writing ability and conducted out of 100
                    marks. Duration of the written test is one hour and ten minutes and distribution of marks is given
                    below:
                    <div class="table-responsive">
                        <table id="dcsa-table">
                            <tr>
                                <th>Serial</th>
                                <th>Subject</th>
                                <th>Marks</th>
                                <th>Syllabus</th>
                            </tr>
                            <tr>
                                <td class="text-center"> 1 </td>
                                <td class="text-center"> Mathematics </td>
                                <td class="text-center"> 35 </td>
                                <td rowspan="5" class="text-center"> 
                                    <strong>
                                    Current Syllabi
                                    of the HSC
                                    Examination 
                                    </strong> 
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">2</td>
                                <td class="text-center">Physice</td>
                                <td class="text-center">35</td>
                            </tr>
                            <tr>
                                <td class="text-center"> 3 </td>
                                <td class="text-center"> English </td>
                                <td class="text-center"> 20 </td>
                            </tr>
                            <tr>
                                <td class="text-center"> 4 </td>
                                <td class="text-center"> Writing Ability(Bangla and English) </td>
                                <td class="text-center"> 5+5=10 </td>
                            </tr>
                            <tr>
                                <th colspan="2">Total</th>
                                <th> 100 </th>
                            </tr>
                        </table>
                    </div>
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Interview/ Viva-voce: The candidates will be
                    attend in interview/viva-voce based on their
                    obtained marks in written test.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Applicants who passed the Diploma in Computer
                    Science and Application (DCSA) program from
                    Bangladesh Open University.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> 10% seats will be reserved for the candidates who
                    have completed Diploma in Computer Science
                    and Application program from BOU and other quotas shall be maintained as per Govt. rules.</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Admission Committee reserves all rights to make
                    any changes.</li>
            </ul>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">military_tech</mat-icon>
                &nbsp;&nbsp;Evaluation
            </ng-template>
            <h1>Distribution of Marks</h1>
            <h2>Theory Courses</h2>
            <ul class="fifthTabul">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Distribution of marks for each course is as follows:
                    <div class="table-responsive">
                        <table id="dcsa-table">
                            <tr>
                                <th>Category</th>
                                <th>Marks %</th>
                            </tr>
                            <tr>
                                <td class="text-center"> Class Attendance </td>
                                <td class="text-center"> 5% </td>
                            </tr>
                            <tr>
                                <td class="text-center">Assignment/ Case study</td>
                                <td class="text-center">10%</td>
                            </tr>
                            <tr>
                                <td class="text-center"> Quizzes/ Class test </td>
                                <td class="text-center"> 15 % </td>
                            </tr>
                            <tr>
                                <td class="text-center"> Semester Final Examination </td>
                                <td class="text-center"> 70% </td>
                            </tr>
                            <tr>
                                <th>Total</th>
                                <th> 100 </th>
                            </tr>
                        </table>
                    </div>
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Number of assignment/case study of a course shall be at least three (3).</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> At least two quizzes/ class test shall be conducted. Best one shall be considered as final
                    quizzes / class test marks.</li>
            </ul>

            <h2>Laboratory Courses</h2>
            <ul class="fifthTabul">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Distribution of marks for each course is as follows:
                    <div class="table-responsive">
                        <table id="dcsa-table">
                            <tr>
                                <th>Category</th>
                                <th>Marks %</th>
                            </tr>
                            <tr>
                                <td class="text-center">Class attendance</td>
                                <td class="text-center">10%</td>
                            </tr>
                            <tr>
                                <td class="text-center">Lab performance</td>
                                <td class="text-center">10%</td>
                            </tr>
                            <tr>
                                <td class="text-center">Assignment/ case study</td>
                                <td class="text-center">10%</td>
                            </tr>
                            <tr>
                                <td class="text-center">Quizzes/class test</td>
                                <td class="text-center">10%</td>
                            </tr>
                            <tr>
                                <th>Semester final examination</th>
                                <td class="text-center" rowspan="4">60%</td>
                            </tr>
                            <tr>
                                <td class="text-center">Problem solving/ Lab test (40)</td>
                            </tr>
                            <tr>
                                <td class="text-center">Viva-Voce (10)</td>
                            </tr>
                            <tr>
                                <td class="text-center">Note book on experiment /Lab
                                    Report (10)</td>
                            </tr>
                            <tr>
                                <td class="text-center">Total</td>
                                <td class="text-center">100%</td>
                            </tr>
                        </table>
                    </div>
                </li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Number of assignment/case study of a course shall be at least three (3).</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> At least two quizzes/ class test shall be conducted. Best one shall be considered as final
                    quizzes / class test marks.</li>
            </ul>
            <h2>Project Work</h2>
            <ul class="fifthTabul">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Total one hundred marks are allotted for Project Work. Distribution of marks is as follows:
                    <div class="table-responsive">
                        <table id="dcsa-table">
                            <tr>
                                <th>Category</th>
                                <th>Marks %</th>
                            </tr>
                            <tr>
                                <td class="text-center">Project Supervisor</td>
                                <td class="text-center">30 %</td>
                            </tr>
                            <tr>
                                <td class="text-center">Project work</td>
                                <td class="text-center">40 %</td>
                            </tr>
                            <tr>
                                <td class="text-center">Presentation</td>
                                <td class="text-center">20 %</td>
                            </tr>
                            <tr>
                                <td class="text-center">Viva-Voce</td>
                                <td class="text-center">10 %</td>
                            </tr>
                            <tr>
                                <th>Total</th>
                                <th>100 %</th>
                            </tr>
                        </table>
                    </div>
                </li>
            </ul>
            <h2>Requirements for Obtaining ‘B.Sc in CSE’ Degree</h2>
            <ul class="fifthTabul">
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Complete 148 credits successfully;</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Obtain a minimum grade of ‘D’ in each course;</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Secure a minimum ‘Cumulative Grade Point Average (CGPA)’ of 2.25;</li>
                <li><i class="fa fa-pagelines" aria-hidden="true"></i> Complete the program within eight academic years of his/ her first admission year into the
                    program.</li>
            </ul>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">account_circle</mat-icon>
                &nbsp;&nbsp;Academic Co-ordinator
            </ng-template>
            <div style="margin: 20px;">
                <h1>For more information:</h1>
                <ul class="fifthTabul" style="padding-inline-start: 20px;">
                    <li>Dr. K. M Rezanur Rahman</li>
                    <li>Professor (Physics) </li> 
                    <li>School of Science and Technology</li>
                    <li>Bangladesh Open University</li> 
                    <li>Board Bazar, Gazipur-1705.</li>
                    <li>E-mail: drkmrezanur&#64;yahoo.com</li>
                    <li>Phone: 09666730730 Ex.686, 8922871(res), 01726202060 (cell).</li>
                </ul>
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">add_alert</mat-icon>
                &nbsp;&nbsp;Notice
            </ng-template>
            <div class="table-container">
                <table class="notice-table">
                    <ng-container *ngFor="let notice of noticeList; index as i">
                        <ng-container *ngIf="!notice?.isDeleted">
                            <ng-container *ngIf="!notice?.isDisabled">
                                
    
                                    <tr>
                                       
                                        <td class="small-cell">
                                            <div class="dateDiv">
                                                <div class="month">{{notice?.noticeSubmissionDate | date :'MMMM'}}</div>
                                                <div class="date">{{notice?.noticeSubmissionDate | date :'d'}}, {{notice?.noticeSubmissionDate | date :'y'}}</div>
                                                <!-- <div class="year"></div> -->
                                            </div>
                                            <div class="half-circle">
                                            </div>                                    
                                        </td> 
                                       
                                         <td class="notice-cell">
                                             <a href="{{notice?.noticeFile}}" target="blank" matTooltip="Click for download">{{notice?.noticeBody}} </a> <app-new-icon [date]="notice?.noticeSubmissionDate"></app-new-icon>
                                            
                                        </td>
                                    </tr>
                                
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </table>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card>

<app-footer></app-footer>
<app-navbar></app-navbar>
<mat-card>
    <div class="wrapper">
        <h1>Class Routines</h1>
      </div>
    <div class="table-responsive">
        <table class="class-routine-table">
            <tr>
                <th>Year</th> 
                <th>Semester</th>
                <th>Session</th>
                <th>Program Name</th>
                <th>Study Center</th>
                <th colspan="2">View / Download</th>
            </tr>
            <tr *ngFor="let routine of routineList; index as i">
                <td class="text-center">{{routine?.routineYear}}</td>
                <td class="text-center">{{routine?.routineSemister}}</td>
                <td class="text-center">{{routine?.routineSession}}</td>
                <td>{{routine?.programName}}</td>
                <td class="text-center">{{routine?.studyCenter}}</td>
                <!-- <td class="text-center"><button  (click)="viewPDF(routine?.routineFile)" matTooltip="View PDF"><img src="../../../../assets/icon/pdf.png"/></button> </td> -->
                <td class="text-center"><button  (click)="downloadPDF(routine?.routineFile)" matTooltip="View PDF"><img src="../../../../assets/icon/pdf.png"/></button> </td>

            </tr> 

        </table>
    </div>

</mat-card>
<br>
<br>
<br>
<br>
<app-footer></app-footer>
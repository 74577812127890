import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-complain-status',
  templateUrl: './complain-status.component.html',
  styleUrls: ['./complain-status.component.scss']
})
export class ComplainStatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

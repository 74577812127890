import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacultyMemberService } from 'src/app/services/faculty-member.service';

@Component({
  selector: 'app-faculty-members',
  templateUrl: './faculty-members.component.html',
  styleUrls: ['./faculty-members.component.scss']
})
export class FacultyMembersComponent implements OnInit {

  facultyMembers = [];
  deanInformation;
  constructor(private router: Router, private facultyMemberService: FacultyMemberService) { }

  ngOnInit(): void {
    this.facultyMemberService.getAllMember()
      .subscribe((result) => {
        this.facultyMembers = result.sort((val) => { return new Date(val.days_bou) });

        console.log(this.facultyMembers);
      })
    this.facultyMemberService.viewDean()
      .subscribe((res) => {
        if (res.isFound) {
          this.deanInformation = res.result.deanId
          console.log(this.deanInformation)
        }
      })

  }

  goToLink(id) {
    console.log(id)
    this.router.navigate(['/facultyMembers/details'], { queryParams: { id: id } });
  }


}

import { Component, OnInit } from '@angular/core';
import { NoticeService } from '../../../../services/notice.service';

@Component({
  selector: 'app-mph',
  templateUrl: './mph.component.html',
  styleUrls: ['./mph.component.scss']
})
export class MphComponent implements OnInit {

  noticeList
  constructor(public noticeService: NoticeService) { }

  ngOnInit(): void {
    this.noticeService.getNoticeByProgram('Master of Public Health')
      .subscribe(data => {
        if (data.isFound) {
          this.noticeList = data.result;
         console.log(this.noticeList)
        }
      })
  }
}

<app-navbar></app-navbar>
<div class="container" style="margin-top: 20px;">
  <div class="row">
    <div class="col-md">
      <div class="bg-uni text-center">
        <h2>Select Alumni Program</h2>
      </div>
      <div class="friendlist-container text-left">
        <div class="row">
          <div class="col-sm-8">
            <mat-selection-list [multiple]="false">
              <ng-container *ngFor="let deptName of deptNameList">
                <mat-list-option [class.disabled]="true">{{deptName.name}}</mat-list-option>
                <mat-selection-list [multiple]="false" style="margin-left:40px;">
                  <div *ngFor="let dept of deptName.dept_list">
                    <mat-list-option (click)="goToDept(dept.name)">{{ dept.name }}</mat-list-option>
                  </div>
                </mat-selection-list>
              </ng-container>
            </mat-selection-list>
            <!-- <mat-selection-list [multiple]="false">
              <mat-list-option *ngFor="let dept of dept_list" [value]="dept" (click)="goToDept(dept)">
                {{dept}}
              </mat-list-option>
            </mat-selection-list> -->
          </div>
        </div>

        <div class="col-sm-4">
          <img src="../../../assets/alumni-example.jpg" class="img-fluid" style="height: 60%; margin-top: 20px;"
            alt="woman alumni image">
        </div>

      </div>
      <div class="friendlist-container">
        <button mat-raised-button routerLink="/alumni-member-application">Alumni Application</button>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

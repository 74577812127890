import { NoticeService } from 'src/app/services/notice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ComplaintService } from './../../../services/complaint.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-complain',
  templateUrl: './view-complain.component.html',
  styleUrls: ['./view-complain.component.scss']
})
export class ViewComplainComponent implements OnInit {
  complaintData: any
  complaintReplyButton: boolean = false
  complaintId: string
  constructor(public noticeService: NoticeService, private complaintService: ComplaintService, private router: Router, private route: ActivatedRoute) {

    this.complaintId = this.route.snapshot.paramMap.get("id")

  }

  ngOnInit(): void {
    this.complaintService.getComplaintById(
      this.complaintId
    )
      .subscribe(data => {
        if (data.isFound) {
          this.complaintData = data.result;
          console.log(this.complaintData);
          if (this.complaintData.complaintStatus != 'Done') {
            this.complaintService.updateComplaintById(this.complaintId)
              .subscribe(data => {
                if (data.isUpdate) {
                  console.log('Update to seen')
                }
              })
          }
        }
      })

  }
  complaintReply() {
    this.complaintReplyButton = true
    this.complaintService.complaintId = this.complaintId
  }
  downloadPDF(url) {
    if (url.substr(-4) == '.pdf') {
      this.noticeService.pdfURL = url;
      this.router.navigate(['/pdfviewer'])
    }
    else {
      window.open(
        url,
        '_blank'
      );

    }
  }


}
